import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class Message extends Component {
  closePopup() {
    this.props.setpr();
  }

  render(props) {
    if (this.props.popInfo.showPop.sm) {
      setTimeout(() => {
        this.closePopup();
      }, 3000);
    }
    
    if (this.props.popInfo.showPop.vs) {
      setTimeout(() => {
        this.closePopup();
      }, 10000);
    }

    return (
      <React.Fragment>
        {this.props.popInfo.showPop.sm && (
          <div
            className={`alert alert-dismissible fade show position-absolute w-100 ${
              this.props.popInfo.showPop.msgType === "success"
                ? "alert-success"
                : this.props.popInfo.showPop.msgType === "error"
                ? "alert-danger"
                : "alert-warning"
            }`}
            role="alert"
            style={{ zIndex: 9, left: 0 }}
          >
            {this.props.popInfo.showPop.msg}
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
              onClick={() => this.closePopup()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        )}
        {this.props.popInfo.showPop.vs && (
          <div
            className={`alert alert-dismissible fade show position-absolute w-100 ${
              this.props.popInfo.showPop.msgType === "success"
                ? "alert-success"
                : this.props.popInfo.showPop.msgType === "error"
                ? "alert-danger"
                : "alert-warning"
            }`}
            role="alert"
            style={{ zIndex: 9, left: 0 }}
          >
            {this.props.popInfo.showPop.msg}
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
              onClick={() => this.closePopup()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { popInfo: state.updateData };
};

function mapDispatchToProps(dispatch) {
  return {
    setpr: () => {
      dispatch({ type: "setpr" });
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Message));
