import axios from "../../../../config/api";
import { token } from "../../../../Shared/Components/LocalStorages";

let apiList = {
  send: "/messages",
  channel: "/createchannel",
  ownedChannels: "/getOwnedChannels",
  makeRequest: "/makerequest",
  acceptInvite: "/acceptinvite/",
  allUsers: "/allusers",
  notification: "/notifications",
  friends: "/getfriendslist",
  invitetogroup: "/invitetochannel",
  channels: "/getchannels",
  markRead: "/markasread/",
  channel_user: "/getusers/",
  online: "/online/",
  offline: "/offline/",
  away: "/away/",
  get_user: '/user',
  messageMarkRead: '/messagemarkasread/',
  unreadCount: '/unreadCount',
  leaveGroup: '/leave-group/',
  channelAddContacts: '/channel-add-contacts/',
  changeStatus: '/change-chat-status/',
  getSettings: '/chat-settings/',
  saveSettings: '/save-chat-settings/',
};

export function sendMessage(data) {
  let url = `${apiList["send"]}`;
  return axios.post(url, data, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

export function getMessages(id = "") {
  let url = `${apiList["send"]}/${id}`;
  return axios.get(url, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

export function getMessagesByChannelId(id) {
  let url = `${apiList["send"]}/${id}`;
  return axios.get(url, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

export function createChannel(data) {
  let url = `${apiList["channel"]}`;
  return axios.post(url, data, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

export function sendRequest(id) {
  let data = {
    receiver: id
  };

  let url = `${apiList["makeRequest"]}`;
  return axios.post(url, data, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

export function getNotification() {
  let url = `${apiList["notification"]}`;
  return axios.get(url, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

export function getOwnedChannels() {
  let url = `${apiList["ownedChannels"]}`;
  return axios.get(url, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

export function getAllUsers(data) {
  let url = `${apiList["allUsers"]}`;
  return axios.post(url, data, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

export function acceptInvite(id) {
  let url = `${apiList["acceptInvite"]}${id}`;
  return axios.get(url, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

export function getFriendsList() {
  let url = `${apiList["friends"]}`;
  return axios.get(url, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

export function getChanelList() {
  let url = `${apiList["channels"]}`;
  return axios.get(url, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

export function inviteUserToGroup(data) {
  let url = `${apiList["invitetogroup"]}`;
  return axios.post(url, data, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

export function markAsRead(id) {
  let url = `${apiList.markRead}${id}`;
  return axios.get(url, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

export function getChannelUsers(id) {
  let url = `${apiList.channel_user}${id}`;
  return axios.get(url, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

export function markOnline(id) {
  let url = `${apiList.online}${id}`;
  return axios.get(url, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

export function markOffline(id) {
  let url = `${apiList.offline}${id}`;
  return axios.get(url, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

export function markAway(id) {
  let url = `${apiList.away}${id}`;
  return axios.get(url, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

export function getCurrentUser() {
  let url = `${apiList["get_user"]}`;
  return axios.get(url, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

export function markAsReadNotification(id) {
  let url = `${apiList.markRead}${id}`;
  return axios.get(url, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

export function messageMarkAsRead(id) {
  let url = `${apiList.messageMarkRead}${id}`;
  return axios.get(url, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

// Get unread count of message
export function unreadCount() {
  let url = `${apiList.unreadCount}`;
  return axios.get(url, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

// Leave Group
export function leaveGroup(id) {
  let url = `${apiList.leaveGroup}${id}`;
  return axios.post(url, {}, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

// Get contact list to be added into channel/group
export function getChannelAddContacts(id) {
  let url = `${apiList.channelAddContacts}${id}`;
  return axios.get(url, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

// Change Status
export function changeStatus(data) {
  let url = `${apiList.changeStatus}`;
  return axios.post(url, data, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

// Get chat settings
export function getChatSettings(id) {
  let url = `${apiList.getSettings}`;
  return axios.get(url, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

// Save chat settings
export function saveChatSettings(data) {
  let url = `${apiList.saveSettings}`;
  return axios.post(url, data, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}