import React from "react";

const Loader = ({ showLoader, responsive = false }) =>
  showLoader ? (
    <div className={`loaderContener ${responsive ? "w-100" : ""}`}>
      <span className="Ploader">
        <span className="loader-inner"></span>
      </span>
    </div>
  ) : (
    ""
  );

export default Loader;
