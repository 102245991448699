const updateData = (state = {}, action) => {
  state = Object.assign({}, state);
  state["showPop"] = {
    pr: true,
    sm: false,
    vs: false,
  };
  state["planUpdate"] = "no";

  switch (action.type) {
    case "UserInfo":
      let data = JSON.parse(localStorage.getItem("userInfo"));
      state["userInfo"] = {
        image: data.image,
        name: data.name,
        all_types: data.all_types,
        userType: data.userType,
      };
      return state;
    case "paymentInfo":
      state["paymentInfo"] = {
        paymentDetails: action.payload,
      };
      return state;
    case "setpr":
      state["showPop"] = {
        pr: true,
        sm: false,
        vs: false,
      };
      return state;
    case "setsm":
      state["showPop"] = {
        pr: false,
        sm: false,
        vs: false,
        msg: action.msg,
        msgType: action.msgType,
      };
      return state;
    case "setvs":
      state["showPop"] = {
        pr: false,
        sm: false,
        vs: true,
        msg: action.msg,
      };
      return state;
    case "setcurrentUser":
      state["showPop"] = {
        pr: false,
        sm: false,
        vs: false,
        currentUser: action.payload,
      };
      return state;
    case "plan":
      return { ...state, planUpdate: "yes" };
    default:
      return state;
  }
};

export default updateData;
