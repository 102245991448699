import React, { useRef } from 'react';
import classes from "./Chat.module.css";
import defaultImage from "../../../../Shared/Components/ImgUtils";
import InputMessage from "./InputMessage";
import userImage from "../../../../assets/img/user2.png";
import ConfirmPopup from "../../../../Shared/Components/ModalPopupAndCard/ConfirmPopup";
import _get from "lodash/get";

const MessageWindow = (props) => {

  const {
    activeChannel,
    typings,
    handleAddUserToGroup,
    handleGetUsers,
    leaveGroup,
    messagesEndRef,
    enteredText,
    setEnteredText,
    onKeyUPFunc,
    sendMessageHandle,
    activeUser,
    groupedMessages,
    resetActiveChannel
  } = props;

  const styleClass = activeChannel.type !== "dm" ? classes["active-channel"] : null;
  const confirmPopupRef = useRef();

  const showConfirmPopup = () => {
    confirmPopupRef.current.openModal();
  }

  let typingTxt = "";
  if (activeChannel.type === "dm") {
    let typingUser = typings.find(typing => typing.user.id === activeChannel.users[0]?.id && typing.channelId === activeChannel.id)
    if (typingUser) {
      typingTxt = "typing...";
    }
  } else {
    let typingUsers = typings.filter(typing => typing.channelId === activeChannel.id && typing.user.id !== activeUser.id)

    if (typingUsers.length > 0) {
      if (typingUsers.length > 1) {
        typingUsers.map(typing => {
          typingTxt += typing.user.name + ", ";
          return typingTxt;

        })
        typingTxt = typingTxt.slice(0, -2);
        typingTxt += " are typing";
      } else {
        typingTxt = _get(typingUsers, "0.user.name", " ") + " is typing";
      }
    }

  }

  return (
    <div className={classes.content}>
      <div className={`${classes["contact-profile"]} ${styleClass}`}>

        {activeChannel.type === "dm" ? (
          <div>
            {activeChannel?.users[0]?.avatar ? (
              // eslint-disable-next-line jsx-a11y/alt-text
              <img
                src={activeChannel?.users[0]?.avatar}
                alt={activeChannel?.name}
                onError={(e) => {
                  e.persist();
                  defaultImage(e, "user");
                }}
              />
            ) : (
              <p className={classes.image_icons}>
                {activeChannel.name?.charAt(0).toUpperCase()}
              </p>)}
            <p className={`mb-0 ml-2 font-weight-bold ${classes.p_name}`}>
              {`${activeChannel.users[0]?.first_name || ""} ${activeChannel.users[0]?.last_name || ""}`}
              <br />
              <span className='text-transform-none'>{typingTxt ? (<small>{typingTxt}</small>) : (<small>&nbsp;</small>)}</span>
            </p>
          </div>

        ) : (

          <>
            <div className='d-flex align-items-center'>
              <p className={classes.image_icons}>
                {activeChannel.name?.charAt(0).toUpperCase()}
              </p>
              <p className={`mb-0 ml-2 font-weight-bold ${classes.p_name}`}>
                {activeChannel.name}
                <br />
                <span className='text-transform-none'>{typingTxt ? (<small>{typingTxt}</small>) : (<small>&nbsp;</small>)}</span>
              </p>
            </div>
            <div className='ml-auto'>
              {(activeChannel.name &&
                activeUser.id === activeChannel.owner_id) && (
                  <button
                    className={classes.addUser}
                    type="button"
                    onClick={(e) => handleAddUserToGroup(e)}
                  >
                    <i
                      className="fa fa-user-plus fa-fw mr-1"
                      aria-hidden="true"
                    ></i>
                    <span>Add user</span>
                  </button>
                )}
              {activeChannel.name && (
                <button
                  className={`${classes.addUser} ml-2`}
                  type="button"
                  onClick={(e) => handleGetUsers(e)}
                >
                  <i className="fa fa-user fa-fw mr-1" aria-hidden="true"></i>
                  <span>Users</span>
                </button>
              )}
              <button
                className={`${classes.addUser} ${classes.leaveGroup} ml-2 `}
                type="button"
                onClick={(e) => showConfirmPopup()}
                title="Leave Group"
              >
                <i className="fa fa-sign-out fa-fw" aria-hidden="true"></i>
              </button>
            </div>
          </>

        )}
        <button
          className={`${classes.addUser} ml-2`}
          type="button"
          onClick={(e) => resetActiveChannel()}
          title="Close Active Window"
        >
          <i className="bx bx-chevrons-left"></i>
        </button>
      </div>
      <div className={classes.messages}>
        <ul style={{ overflow: "hidden", padding: "0" }}>
          {(Object.keys(groupedMessages)).map((time, key) => {
            let messageUser = groupedMessages[time][0]['user']['id'];
            return (
              <React.Fragment key={'groupedMessage' + key}>
                <li>
                  <span className={classes.time} style={{ padding: "0 70px" }}>
                    {time}
                  </span>
                </li>
                {
                  groupedMessages[time].map((item, index) => {
                    let displayIcon = true;
                    if (index !== 0 && messageUser === item.user.id) {
                      displayIcon = false;
                    } else {
                      messageUser = item.user.id;
                    }
                    let isUserMessage = item.user.id === activeUser.id;
                    return ((item?.is_info || 0) === 1) ? (
                      <li key={index}>
                        <span className={`${classes.time} ${classes.leaveGroupMsg}`} style={{ padding: "0 70px" }}>
                          <i>{item.message}</i>
                        </span>
                      </li>) : (
                      <li
                        className={
                          classes[
                          item.user.id === activeUser.id ? "replies" : "sent"
                          ]
                        }
                        key={index}
                        style={(displayIcon === false && activeChannel.type !== "dm") ? item.user.id === activeUser.id ? { paddingRight: "50px" } : { paddingLeft: "50px" } : {}}
                      >
                        {(displayIcon === true && activeChannel.type !== "dm") &&
                          (item.user.image ? (
                            <img
                              src={item.user.image}
                              alt={item.user.first_name + item.user.last_name}
                              title={item.user.first_name + item.user.last_name}
                              onError={(e) => {
                                e.persist();
                                defaultImage(e, "user");
                              }}
                            />
                          ) : (
                            <img
                              src={userImage}
                              alt={item.user.first_name + item.user.last_name}
                              title={item.user.first_name + item.user.last_name}
                              onError={(e) => {
                                e.persist();
                                defaultImage(e, "user");
                              }}
                            />
                          ))}
                        <p style={{ marginBottom: "0px", borderRadius: "5px", boxShadow: '1px 1px 10px 0 rgb(0 0 0 / 14%)' }} className={`${classes.message_text} ${displayIcon === true ? (isUserMessage === true ? classes.chat_text : classes.chat_reply_text) : ""}`}>{item.message}</p>
                      </li>)
                  })}
              </React.Fragment>
            )
          })}
        </ul>
        <div ref={messagesEndRef}></div>
      </div>
      <InputMessage
        enteredText={enteredText}
        setEnteredText={setEnteredText}
        onKeyUPFunc={onKeyUPFunc}
        sendMessageHandle={sendMessageHandle}
      />
      <ConfirmPopup
        popupClass="leaveGroup"
        ref={confirmPopupRef}
        isRemove={(cc) => leaveGroup(activeChannel.id)}
        message="Are you sure you want to leave group?"
      />
    </div>
  )
};

export default MessageWindow;