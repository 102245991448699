import classes from "../../../assets/scss/common.module.scss";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { logout } from "../../Services/commonApis";
import environment from "../../../Models/env";
import logo from "../../../assets/images/logo.png";
import userImg from "../../../assets/img/user2.png";

import defaultImage from "../ImgUtils";
import { getLocalStorageItem } from "../LocalStorages";
import { componentHide } from "../roles-and-permission/permissions";
import _isEqual from "lodash/isEqual";
import _isEmpty from "lodash/isEmpty";
import _isNull from "lodash/isNull";
import moment from "moment";
import { getLoginUserDetail } from "../../../Modules/admin/Service/Users/usersService";

class Header extends Component {
  constructor(props) {
    super(props);
    this.closeMobileMenu = this.closeMobileMenu.bind(this);
    let name = "";
    let email = "";
    let userType = "";
    let image = "";
    let dId = "";
    let userId = "";
    let all_types = "";
    if (getLocalStorageItem("userInfo")) {
      let userInfo = getLocalStorageItem("userInfo");
      name = userInfo.name;
      email = userInfo.email;
      userType = userInfo.userType;
      image = userInfo.image;
      userId = userInfo.encrypt_id;
      dId = userInfo.id;
      all_types = userInfo.all_types;
    }
    let profileSwitch = getLocalStorageItem("userInfo");
    this.state = {
      name: name,
      email: email,
      all_types: all_types,
      userType: this.props.userInfo.userInfo
        ? this.props.userInfo.userInfo.userType
        : userType,
      SA: false,
      EP: false,
      CL: false,
      VN: false,
      image: image,
      currentPlan: {},
      activePage: this.props.location.pathname,
      activityCount: 0,
      userId,
      count: 0,
      profile_switch: profileSwitch,
      profileSwitchValue: "",
      id: dId,
      profile_name: "Subscriber",
      pro_name: "Subscriber",
      planExpired: false,
      error: "",
      showLoader: false,
      isMobileMenuOpen: false,
    };
  }

  componentDidUpdate(props, prevState) {
    if (prevState.activePage !== this.props.location.pathname) {
      this.setState({ activePage: this.props.location.pathname });
    }

    if (
      !_isEqual(this.state.error, prevState.error) &&
      !_isEmpty(this.state.error)
    ) {
      const errorMsgSection =
        document.getElementById("user-plan-section") || null;
      if (!_isNull(errorMsgSection)) {
        errorMsgSection.scrollIntoView({ behavior: "smooth", block: "start" });
      }
      setTimeout(() => {
        this.setState({ error: "" });
      }, 5000);
    }
  }

  componentDidMount() {
    let Log = componentHide();
    if (Log) {
      let k = Object.keys(Log);
      this.setState({ [k[0]]: Log[k[0]] });
    }
    this.getUserImage();
    window.addEventListener("resize", this.closeMobileMenu);
  }

  closeMobileMenu(e) {
    if (window.innerWidth > 991) {
      this.setState({ isMobileMenuOpen: false });
    }
  }
  getUserImage() {
    getLoginUserDetail(this.state.userId)
      .then((res) => {
        if (res.data.code === 403) {
          this.tokenExpire(res.data.message);
        } else if (res.data.success) {
          let image = res.data.data.records.image
            ? `${res.data.data.records.image}`
            : userImg;
          this.setState({ image: image });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  closeModal() {
    document.getElementById("closebtn").click();
  }

  planExpired() {
    const {
      currentPlan,
      currentPlan: { end_date },
    } = this.state;

    if (currentPlan && moment(end_date).toDate() < new Date()) {
      let endData = moment(currentPlan?.end_date).format("YYYY-MM-DD");
      let currentDate = moment(new Date()).format("YYYY-MM-DD");

      if (moment(endData).isSame(currentDate) !== true) {
        this.setState({ planExpired: true });
      }
    }
  }

  logOut() {
    logout();
    localStorage.setItem("token", "");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("evpId");
    localStorage.removeItem("default_payment");
    localStorage.removeItem("path");
  }

  // activity count
  activityCountshandle(count) {
    this.setState({ activityCount: count });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.closeMobileMenu);
  }

  //render function
  render(props) {
    let userInfo = {};

    if (this.props.userInfo.userInfo) {
      userInfo["image"] = this.state.image;
      userInfo["name"] = this.props.userInfo.userInfo.name;
    } else {
      userInfo["image"] = this.state.image;
      userInfo["name"] = this.state.name;
    }
    if (
      this.props.userInfo.userInfo &&
      this.props.userInfo.userInfo.all_types
    ) {
      userInfo["all_types"] = this.props.userInfo.userInfo.all_types;
    } else {
      userInfo["all_types"] = this.state.all_types;
    }

    const toggleMobileMenu = () =>
      this.setState({ isMobileMenuOpen: !this.state.isMobileMenuOpen });
    let loggedInUser = getLocalStorageItem("userInfo");
    //return
    return (
      <React.Fragment>
        <header
          className={`header ${classes["header"]} ${classes["h_bg_wrapper"]}`}
        >
          <nav
            className={`navbar ${classes["navbar"]} navbar-expand-lg ${
              classes["navbar-expand-lg"]
            } navbar-light ${classes["navbar-light"]} ${
              this.state.isMobileMenuOpen ? classes["navbar_shadow"] : ""
            }`}
          >
            <div
              className={`container ${classes["container"]} navbar-container ${classes["navbar-container"]}`}
            >
              <Link className="navbar-brand" to="/home">
                <img
                  src={logo}
                  className={classes.header_logo}
                  alt="harpsen logo"
                />
              </Link>
              <div
                className={`navbar-toggler ${classes["navbar-toggler"]} ${classes["mobile-nav-buttons"]}`}
              >
                {loggedInUser && (
                  <div className={`dropdown dropleft`}>
                    <a
                      className=""
                      href="/"
                      role="button"
                      id="prs"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <div
                        className="menu-profile"
                        style={{ width: "35px", height: "35px" }}
                      >
                        <img
                          src={
                            userInfo.image
                              ? `${environment.imageBaseUrl}${userInfo.image}`
                              : userImg
                          }
                          className="rounded-circle w-100 h-100"
                          alt="user"
                          onError={(e) => {
                            e.persist();
                            defaultImage(e, "user");
                          }}
                        />
                      </div>
                    </a>
                    <div className="dropdown-menu" aria-labelledby="prs">
                      <h4 className="font-size-base font-weight-bold m-0 pl-4 pr-2 pt-2">
                        Hi! {userInfo.name}
                      </h4>
                      <div className="dropdown-divider"></div>
                      <Link className="dropdown-item" to="/app/dashboard">
                        Dashboard
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="#"
                        onClick={() => {
                          this.logOut();
                        }}
                      >
                        Log out
                      </Link>
                    </div>
                  </div>
                )}

                <button
                  className={`navbar-toggler ${classes["navbar-toggler"]} ${
                    this.state.isMobileMenuOpen ? "" : "collapsed"
                  }`}
                  type="button"
                  onClick={toggleMobileMenu}
                >
                  <input
                    type="checkbox"
                    name=""
                    id=""
                    checked={this.state.isMobileMenuOpen}
                  />
                  <div className={classes["hamburger-lines"]}>
                    <span
                      className={`${classes["line"]} ${classes["line1"]}`}
                    ></span>
                    <span
                      className={`${classes["line"]} ${classes["line2"]}`}
                    ></span>
                    <span
                      className={`${classes["line"]} ${classes["line3"]}`}
                    ></span>
                  </div>
                </button>
              </div>

              <div
                className={`collapse ${classes["collapse"]} navbar-collapse ${
                  classes["navbar-collapse"]
                } ${this.state.isMobileMenuOpen ? "show" : ""}`}
                id="navbarSupportedContent"
              >
                <ul className={`navbar-nav ${loggedInUser && classes["navbar-logged-in"]} ${classes["navbar-nav"]} mx-auto`}>
                  <li className={`nav-item active`}>
                    <Link
                      className={`nav-link ${classes["header_nav_link"]} ${classes["nav-link"]}`}
                      to={{ pathname: "/home", state: { section: "feature" } }}
                    >
                      Features <span className="sr-only">(current)</span>
                    </Link>
                  </li>
                  <li className={`nav-item ${classes["nav-item"]} active`}>
                    <Link
                      className={`nav-link ${classes["header_nav_link"]} ${classes["nav-link"]}`}
                      to={{ pathname: "/home", state: { section: "portal" } }}
                    >
                      Portals
                    </Link>
                  </li>
                  <li className={`nav-item ${classes["nav-item"]} active`}>
                    <Link
                      className={`nav-link ${classes["header_nav_link"]} ${classes["nav-link"]}`}
                      to={{
                        pathname: "/home",
                        state: { section: "explore-harpsen" },
                      }}
                    >
                      Explore Harpsen
                    </Link>
                  </li>
                  <li className={`nav-item ${classes["nav-item"]} active`}>
                    <Link
                      className={`nav-link ${classes["header_nav_link"]} ${classes["nav-link"]}`}
                      to={{ pathname: "/home", state: { section: "aboutUs" } }}
                    >
                      About Us
                    </Link>
                  </li>
                  <li className={`nav-item ${classes["nav-item"]} active`}>
                    <Link
                      className={`nav-link ${classes["header_nav_link"]} ${classes["nav-link"]}`}
                      to={{
                        pathname: "/home",
                        state: { section: "testimonial" },
                      }}
                    >
                      Testimonials
                    </Link>
                  </li>
                  <li className={`nav-item ${classes["nav-item"]} active`}>
                    <Link
                      className={`nav-link ${classes["header_nav_link"]} ${classes["nav-link"]}`}
                      to="/blog"
                    >
                      Blog
                    </Link>
                  </li>
                </ul>
                {loggedInUser && (
                  <div className="dropdown dropleft d-none d-lg-block">
                    <a
                      className=""
                      href="/"
                      role="button"
                      id="prs"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <div
                        className="menu-profile"
                        style={{ width: "35px", height: "35px" }}
                      >
                        <img
                          src={
                            userInfo.image
                              ? `${environment.imageBaseUrl}${userInfo.image}`
                              : userImg
                          }
                          className="rounded-circle w-100 h-100"
                          alt="user"
                          onError={(e) => {
                            e.persist();
                            defaultImage(e, "user");
                          }}
                        />
                      </div>
                    </a>
                    <div className="dropdown-menu" aria-labelledby="prs">
                      <h4 className="font-size-base font-weight-bold m-0 pl-4 pr-2 pt-2">
                        Hi! {userInfo.name}
                      </h4>
                      <div className="dropdown-divider"></div>
                      <Link className="dropdown-item" to="/app/dashboard">
                        Dashboard
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="#"
                        onClick={() => {
                          this.logOut();
                        }}
                      >
                        Log out
                      </Link>
                    </div>
                  </div>
                )}
                {!loggedInUser && (
                  <ul className={`navbar-nav ${classes["navbar-nav"]}`}>
                    <li
                      className={`nav-item ${classes["nav-item"]} ${classes["try-btn"]} active`}
                    >
                      <Link
                        className={`btn ${classes["btn"]} btn-secondary ${classes["btn-secondary"]} nav-link ${classes["nav-link"]}`}
                        to="/login"
                      >
                        Log In
                      </Link>
                    </li>
                    <li
                      className={`nav-item ${classes["nav-item"]} ${classes["try-btn"]}`}
                    >
                      <Link
                        className={`btn ${classes["btn"]} btn-primary ${classes["btn-primary"]} nav-link ${classes["nav-link"]}`}
                        to="/signup"
                      >
                        Sign Up
                      </Link>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </nav>
        </header>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateInfo: () => {
      dispatch({ type: "UserInfo" });
    },
    setsm: (msg) => {
      dispatch({ type: "setsm", msg: msg });
    },
    resetChatTotalCount: () =>
      dispatch({ type: "UPDATE_TOTAL_UNREAD_COUNT", payload: 0 }),
    // updatePlan:()=>{dispatch({ type: "plan" })}
  };
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.updateData,
    paymentInfo: state.updateData,
    planUpdate: state.updateData.planUpdate,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
