import React from 'react';
import classes from "./Chat.module.css";
import defaultImage from "../../../../Shared/Components/ImgUtils";
import userImage from "../../../../assets/img/user2.png";

const AddContactList = ({ contacts, onAddUser, searchQuery, loading = false }) => {
  return (
    contacts.length > 0 ?
      <ul>
        {contacts.map((user) => (
          <li
            className={classes.contact}
            key={user["id"]}
            style={{ cursor: "initial" }}
          >
            <div className={`media ${classes.wrap}`}>
              <div className="align-self-start mr-1">
                <img
                  src={
                    user.image
                      ? user.image
                      : userImage
                  }
                  alt={user?.first_name + " " + user?.last_name}
                  onError={(e) => {
                    e.persist();
                    defaultImage(e, "user");
                  }}
                />
              </div>
              <div className={`media-body ${classes.meta}`}>
                <p className={`mb-0 ${classes.name}`}>
                  {user.first_name} {user.last_name}
                </p>

                <p className={`mb-0 mt-0 ${classes.preview}`}>
                  {user["email"]}
                </p>

                <button
                  type="button"
                  className="btn btn-dark py-1"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onAddUser(user["id"]);
                  }}
                  disabled={user.invite_sent}
                >
                  {user.invite_sent ? "Already requested" : "Send Request"}
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul> : (
        !loading && <div className={classes.chat_no_contact_div}>{searchQuery ? "No users found" : "No users yet!"}</div>
      )
  );
}

export default AddContactList;