import Echo from "laravel-echo";

import environment from "../../../../Models/env";

import { markOffline, markOnline } from "../../Service/chat/service";

import store from "../../../../store/store";

import {
  listenDmChannelEvents,
  listenPrivateChannelEvents,
} from "../../../../store/chat/chat-actions";

export const echoInit = (token, userId) => {


  if (token) {
    if (!window.Echo) {

      window.Pusher = require("pusher-js");

      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: environment.PUSHER_KEY,
        cluster: environment.PUSHER_CLUSTER,
        encrypted: true,
        authEndpoint: environment.apiBaseUrl + '/broadcasting/auth'
      });
    }

    window.Echo.connector.options.auth.headers["Authorization"] =
      "Bearer " + token;

    window.Echo.options.auth = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    // Handling of chat channle
    window.Echo.join("chat")
      .here((users) => {
        users.forEach((user) => {
          if (user.status === 'offline' || user.status === 'away') {
            markOnline(user.id)
          }
        });
      })
      .joining((user) => {
        markOnline(user.id)
          .then((res) => {/*console.log("[ONLINE]", res)*/ })
          .catch((err) => console.log(err));
      })
      .leaving((user) => {
        markOffline(user.id)
          .then((res) => {/*console.log("[OFFLINE]", res)*/ })
          .catch((err) => console.log(err));
      })
      .listen('UserOnline', (event) => {
        store.dispatch({ type: "IS_ONLINE", payload: event.user.id });
      })
      .listen('UserOffline', (event) => {
        store.dispatch({ type: "IS_OFFLINE", payload: event.user.id });
      })
      .listen('UserAway', (event) => {
        store.dispatch({ type: "IS_AWAY", payload: event.user.id });
      });

    // Handling of user private channel (for notifications)
    window.Echo.private(`user.${userId}`)
      .listen(
        "UserNotificationEvent",
        (event) => {
          store.dispatch({ type: "ADD_NOTIFICATION", payload: event });
        }
      );

    // Handling of event acceptrequest private channel (for accept request)
    window.Echo.private(`event.acceptRequest.${userId}`)
      .listen(
        "AcceptRequest",
        (event) => {
          if (event[1] === "FRND") {
            store.dispatch({ type: "ACCEPT_REQUEST_SUCCESS", payload: event[0] });
            listenDmChannelEvents(event[0].id, store.dispatch, store.getState);

          } else {
            store.dispatch({ type: "ADD_CHANNEL_SUCCESS", payload: event[0] });
            listenPrivateChannelEvents(event[0].id, store.dispatch, store.getState)

          }
        }
      );
  }

};

export const echoLeaveChannel = () => {
  if (window.Echo && typeof window.Echo.connector.channels === "object") {
    Object.keys(window.Echo.connector.channels).forEach((channel) => {
      window.Echo.leave(channel);
    });
  }

  return;
};