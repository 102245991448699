import React, { Component } from "react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";

import Login from "./Pages/Login";
import ForgetPassword from "./Pages/ForgetPassword";
import ResetPassword from "./Pages/ResetPassword";

class Routing extends Component {
  checkAdminLogin() {
    let location = this.props.history.location.pathname.split("/");
    if (localStorage.getItem("token") && localStorage.getItem("userInfo")) {
      if(localStorage.getItem("userInfo").isVerified === "true") {
        return <Redirect to="/app/dashboard" />;
      } else {
        return <Login />;
      }
    } else {
      // if (this.props.history.location.pathname.includes('/app/project/templates/')) {
      //   // return <Redirect to="/" />;
      // } else {
      //   return <Redirect to="/app/dashboard" />;
      // }

      if (location[2] === "forgotpassword") {
        return <ForgetPassword />;
      } else if (location[2] === "resetpassword") {
        return <ResetPassword token={this.props.token} />;
      } else if (location[2] === "login") {
        return <Login />;
      } else if (location[2] === "setpassword") {
        return <ResetPassword token={this.props.token} />;
        // } else if (location.includes('app') && location.includes('project') && location.includes('templates') && location.includes('view')) {
        //   // return <Redirect to="/" />;
      } else {
        return <Redirect to="/" />;
      }
    }
  }

  render(props) {
    return (
      <Switch>
        <Route path="/app">{this.checkAdminLogin()}</Route>
      </Switch>
    );
  }
}

export default withRouter(Routing);
