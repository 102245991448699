import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { tokenExpire } from "../../../../Shared/Services/errorService";
import VendorCard from "../../../../Shared/Components/ModalPopupAndCard/VendorCard";
import Pagination from "../../../../Shared/Components/Pagination";
import Loader from "../../../../Shared/Components/Loader";
import moment from "moment";
import {
  removeLocalStorageItem,
  setLocalStorageItem,
  userInfoData,
  getLocalStorageItem,
} from "../../../../Shared/Components/LocalStorages";
import { VendorLoginEPList } from "../../Service/Vendor/vendorService";
import { componentHide } from "../../../../Shared/Components/roles-and-permission/permissions";
import NoRecords from "../../../../Shared/Components/not-found/RecordsNotFound";
import { checkPayment } from "../../Service/Purchase/purchaseService";

class VendorLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      currentKey: "",
      order: 0,
      page: 1,
      recordPerPage: 5,
      filter: this.setFilterField(),
      evpId: "",
      project_ids: "",
      totalRecords: 0,
      list: [],
      SA: false,
      EP: false,
      CL: false,
      VN: false,
      apiMessage: "",
      recordsPerPage: 5,
      planExpired: false,
      userData: userInfoData(),
    };
  }

  tokenExpire(msg) {
    const type = tokenExpire();
    if (type === 1) {
      this.props.history.push("/login");
    } else {
      this.props.history.push("/app/login");
    }
    this.props.setsm(msg);
  }

  componentDidMount() {
    let data = { "records-per-page": this.state.recordsPerPage };
    this.getEPList(data);
    let Log = componentHide();
    if (Log) {
      let k = Object.keys(Log);
      this.setState({ [k[0]]: Log[k[0]] });
    }
    let pathNames = getLocalStorageItem("path");
    if (pathNames) {
      let paths = pathNames.split("/");
      let data = {
        ep_id: paths[paths.length - 3],
        p_ids: paths[paths.length - 1],
      };
      setLocalStorageItem("evpId", data);
      this.props.history.push(getLocalStorageItem("path"));
    }
  }

  sortData(key) {
    if (this.state.currentKey === key) {
      this.setState(
        {
          currentKey: key,
          order: this.state.order === 1 ? 2 : 1,
        },
        () => {
          this.updateData();
        }
      );
    } else {
      this.setState(
        {
          currentKey: key,
          order: 1,
        },
        () => {
          this.updateData();
        }
      );
    }
  }

  setPage(page) {
    this.setState({ page: page }, () => {
      this.updateData();
    });
  }

  updateData() {
    let data = {
      pageno: this.state.page,
      "records-per-page": this.state.recordPerPage,
    };
    if (this.state.currentKey) {
      data["order"] = this.state.order === 1 ? "asc" : "desc";
      data["sortby"] = this.state.currentKey;
    }
    let keyConfig = Object.keys(this.state.filter);
    for (let i = 0; i < keyConfig.length; i++) {
      if (this.state.filter[keyConfig[i]]) {
        data[keyConfig[i]] = this.state.filter[keyConfig[i]];
      }
    }
    this.getEPList(data);
  }

  setFilterField() {
    let fields = {
      first_name: "",
      last_name: "",
      email: "",
      is_active: "",
      phone: "",
    };
    return fields;
  }

  setFilterData(e) {
    let data = this.state.filter;
    data[e.target.name] = e.target.value;
    this.setState({ filter: data });
  }

  closeFilter() {
    document.querySelector(".filterBtnshs").click();
  }

  clearFilter() {
    let data = this.setFilterField();
    this.setState({ filter: data }, () => {
      this.updateData();
    });
    this.closeFilter();
  }

  filterData(e) {
    e.preventDefault();
    this.updateData();
    this.closeFilter();
  }

  getUserCurrentPlan(id, pIds, name, email) {
    const { planExpired } = this.state;
    if (planExpired) {
      this.setState(
        {
          evpId: id,
          project_ids: pIds,
        },
        () => {
          const { evpId, project_ids } = this.state;
          let data = {
            ep_id: evpId,
            p_ids: project_ids,
            name: name,
            email: email,
          };
          setLocalStorageItem("evpId", data);
          this.props.history.push(`/app/dashboard`);
        }
      );
    } else {
      removeLocalStorageItem("evpId");
      this.props.setvs("Subscriber plan has expired!");
    }
  }

  onEVPClick(e, id, pIds, name, email) {
    e.preventDefault();
    checkPayment(id)
      .then((res) => {
        if (res.data.code === 403) {
          this.tokenExpire(res.data.message);
        } else if (
          res.data.success &&
          res.data.data &&
          (res.data.data.is_payment_done ||
            moment(res.data.data.end_date).toDate() >= new Date())
        ) {
          this.setState({ planExpired: true }, () => {
            this.getUserCurrentPlan(id, pIds, name, email);
          });
        } else {
          this.setState({ planExpired: false }, () => {
            this.getUserCurrentPlan(id, pIds, name, email);
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //event planner list
  getEPList(data) {
    this.setState({ showLoader: true });
    VendorLoginEPList(data)
      .then((res) => {
        if (res.data.success && res.data.data) {
          this.setState({
            list: res.data.data.totalRecords ? res.data.data.records : [],
            totalRecords: res.data.data.totalRecords,
            apiMessage: res.data.message,
          });
        }
        this.setState({ showLoader: false, apiMessage: res.data.message });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ showLoader: false });
      });
  }

  // render
  render(props) {
    const { list, showLoader, totalRecords, CL, VN } = this.state;

    return (
      <React.Fragment>
        <div className="main-content d-flex flex-column mt-2">
          <div className="card">
            <div className="card-header d-flex justify-content-md-between align-items-md-center mb-0 flex_mm_column">
              <h3 className="list_title mb-0 mb_w_100">
                <i className="bx bx-user-circle"></i>
                {CL ? "My Vendors" : VN ? "My Vendors" : "Event Planners"}
              </h3>
              <div className="d_flx_mb d-flex">
                <div className="dropdown">
                  <button
                    className="btn btn-sm btn-white filterbtn filterBtnshs"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="bx bx-slider-alt mr-1"></i> Apply Filters
                  </button>
                  <form
                    className="dropdown-menu dropdown-menu-right dropdown-menu-card p-3"
                    onSubmit={(e) => {
                      this.filterData(e);
                    }}
                  >
                    <div className="card-header pt-2 pb-2 m-0">
                      <h4 className="card-header-title font-size-h5">
                        Filters
                      </h4>
                      <button
                        className="btn btn-light-danger closeBtn"
                        type="button"
                        onClick={this.closeFilter}
                      >
                        <i className="bx bx-x"></i>
                        <small>Close</small>
                      </button>
                    </div>
                    <div className="card-body overflow-hidden bd-t">
                      <div className="list-group list-group-flush mb-2">
                        <div className="list-group-item p-0 border-0">
                          <div className="form-row">
                            <label className="col-sm-4 col-form-label word_space">
                              First Name{" "}
                            </label>
                            <div className="col-sm-8">
                              <input
                                type="text"
                                className="form-control"
                                name="first_name"
                                onChange={(e) => {
                                  this.setFilterData(e);
                                }}
                                value={this.state.filter.first_name}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="list-group-item p-0 border-0">
                          <div className="form-row">
                            <label className="col-sm-4 col-form-label word_space">
                              Email
                            </label>
                            <div className="col-sm-8">
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                onChange={(e) => {
                                  this.setFilterData(e);
                                }}
                                value={this.state.filter.email}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-center d-flex">
                        <button className="btn material-button" type="submit">
                          <i className="bx bx-filter"></i> Apply filter
                        </button>
                        <button
                          className="btn material-button bg-mt"
                          type="reset"
                          onClick={(e) => {
                            this.clearFilter(e);
                          }}
                        >
                          <i className="bx bx-x"></i>
                          <small>Clear filters</small>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="card-body bd-t">
              {list.length ? (
                <VendorCard
                  items={list}
                  onNameClick={(e, id, pid, name, email, owner_id) => {
                    this.onEVPClick(e, id, pid, name, email, owner_id);
                  }}
                />
              ) : (
                <NoRecords message={this.state.apiMessage} />
              )}
            </div>
            {totalRecords ? (
              <Pagination
                totalRecord={totalRecords}
                otherData={this.state}
                changePage={(page) => {
                  this.setPage(page);
                }}
              />
            ) : null}
          </div>
        </div>
        <Loader showLoader={showLoader} />
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setsm: (msg) => {
      dispatch({ type: "setsm", msg: msg });
    },
    setvs: (msg) => {
      dispatch({ type: "setvs", msg: msg });
    },
  };
}

export default connect(null, mapDispatchToProps)(withRouter(VendorLogin));
