import axios from "../../../config/api";
import environment from "../../../Models/env";

function getPlans() {
  let url = `${environment.apiBaseUrl}/plans`;
  return axios.get(url);
}
function sendQuery(formData) {
  let url = `${environment.apiBaseUrl}/contactus`;
  return axios.post(url, formData, {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  });
}

function getPages(slug) {
  let url = `${environment.apiBaseUrl}/custom-page/${slug}`;
  return axios.get(url);
}

function getHomePlans() {
  let url = `${environment.apiBaseUrl}/homepage-plans`;
  return axios.get(url);
}

function getFaqs(payload) {
  let url = `${environment.apiBaseUrl}/faqs?search=${payload?.search}&pageno=${payload?.pageno}&records-per-page=${payload?.["records-per-page"]}`;
  return axios.get(url);
}

export { getPlans, sendQuery, getPages, getHomePlans, getFaqs };
