import axios from "../../../../config/api";
import { token } from '../../../../Shared/Components/LocalStorages';

//team member
export function getSubscribersList(data) {
  let url = `/team-member/list`;
  return axios.post(url, data, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

export function createUser(formData) {
  let url = `/team-member`;
  return axios.post(url, formData, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

export function getUserDetail(id) {
  let url = `/team-member/${id}`;
  return axios.get(url, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

export function updateUser(id, data) {
  let url = `/team-member/${id}`;
  return axios.put(url, data, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

export function getTmProfileDetails(id) {
  let url = `/team-member-profile/${id}`;
  return axios.get(url, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

export function updateTmProfileDetails(id, data) {
  let url = `/team-member-profile-update/${id}`;
  return axios.post(url, data, { headers: { Authorization: `Bearer ${token()}` }, });
}

export function teamMemberDelete(id) {
  let url = `/team-member/${id}`;
  return axios.delete(url, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

export function teamMemberAction(data) {
  let url = `/team-member-actions`;
  return axios.post(url, data, { headers: { Authorization: `Bearer ${token()}` }, });
}

export function getUsersList(data) {
  let url = `/team-member/list`;
  return axios.post(url, data, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}
//team member

export function deleteUser(id) {
  let url = `/users/${id}`;
  return axios.delete(url, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

export function getLoginUserDetail(id) {
  let url = `/profile/${id}`;
  return axios.get(url, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

export function updateLoginUserDetails(id, data) {
  let url = `/profile-update/${id}`;
  return axios.post(url, data, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

export function getCountry() {
  let url = `/country`;
  return axios.get(url);
}

export function getStateByCountry(countryId) {
  let url = `/getStateByCountry/${countryId}`;
  return axios.get(url);
}

export function updatePassword(data) {
  let url = `/change-password`;
  return axios.post(url, data, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

export function userAction(data) {
  let url = `/user-actions`;
  return axios.post(url, data, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

export function getUserDetails(id) {
  let url = `/user-view/${id}`;
  return axios.get(url, { headers: { Authorization: `Bearer ${token()}` }, });
}

export function getUserDetailsForView(id) {
  let url = `/team-member/show/${id}`;
  return axios.get(url, { headers: { Authorization: `Bearer ${token()}` }, });
}

//
export function getUserRoleId() {
  let url = `/members`;
  return axios.get(url, { headers: { Authorization: `Bearer ${token()}` }, });
}

//check user payment configuration is set or not
export function checkPaymentConfig() {
  let url = `/user-payment-config`;
  return axios.get(url, { headers: { Authorization: `Bearer ${token()}` }, });
}