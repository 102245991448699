import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom";

import AuthLayout from "../Modules/Authentication/AuthLayout";
import AdminLayout from "../Modules/admin/AdminLayout";
import HomeLayout from "../Modules/Home/HomeLayout";
// import SignUp from "../Modules/Home/Pages/SignUp";
// import ScrollToTop from '../Models/ScrollToTop';

class AppRouting extends Component {

  render(props) {
    return (
      // <ScrollToTop>
      <Switch>
        <Route exact path="/app/login">
          <AuthLayout />
        </Route>
        <Route exact path="/app/forgotpassword">
          <AuthLayout />
        </Route>
        <Route exact path="/app/resetpassword/:token">
          <AuthLayout />
        </Route>
        <Route exact path="/app/setpassword/:token">
          <AuthLayout />
        </Route>
        <Route path="/frontendlogin">
          <HomeLayout />
        </Route>
        <Route path="/app">
          <AdminLayout />
        </Route>
        <Route exact path="/app/event-planners">
          <AdminLayout />
        </Route>
        <Route path="/">
          <HomeLayout />
        </Route>
      </Switch>
      // </ScrollToTop>
    );
  }
}

export default withRouter(AppRouting);
