import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import logo from "../../assets/images/logo.png";
import defaultImage from "./ImgUtils";

class PageNotFound extends Component {
  render(props) {
    let url = "";
    const path = this.props.location.pathname.split("/");
    if (path[1] === "admin") {
      url = "/app/dashboard";
    } else {
      url = "/";
    }
    return (
      <div className="main-content d-flex flex-column">
        <div className="maintenance-area">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="maintenance-content">
                <Link to={url} className="logo">
                  <img
                    src={logo}
                    alt="logo"
                    width="200"
                    onError={(e) => {
                      e.persist();
                      defaultImage(e, "logo");
                    }}
                  />
                </Link>
                <h2>404</h2>
                <p>We can't seem to find the page you're looking for.</p>
                <Link to={url} className="material-button">
                  Back to Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(PageNotFound);
