import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import dateModify from "../../../../Shared/Components/DateModify";
import { userInfoData } from "../../../../Shared/Components/LocalStorages";

class PaymentReminder extends Component {
  render(props) {
    const { userType } = userInfoData();
    return (
      <React.Fragment>
        {(this.props.popInfo.showPop.pr && userType === "EP" &&
	!this.props.is_payment_done) && (
          <div
            className="alert alert-warning alert-dismissible fade show"
            role="alert"
          >
            <strong>Hello {this.props.name}! </strong>
            {this.props.days > 0
              ? `Your trial plan will expire after ${
                  this.props.days
                } days on ${dateModify(this.props.date)}.`
              : `Your trial has expired.`}
            <Link to="/app/select-plan" className="alert-link">
              {" "}
              Click here
            </Link>{" "}
            to upgrade your plan.
		{ /* <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button> */}
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { popInfo: state.updateData };
};

export default connect(mapStateToProps)(PaymentReminder);
