import React from 'react';
import classes from "./Chat.module.css";

const InputMessage = ({ enteredText, setEnteredText, onKeyUPFunc, sendMessageHandle }) => {
    return (
        <div className={classes["message-input"]}>
            <div className={classes.wrap}>
                <input
                    onChange={(e) => setEnteredText(e.target.value)}
                    onKeyUp={(e) => onKeyUPFunc(e)}
                    type="text"
                    value={enteredText}
                    className="form-control"
                    placeholder="Write your message..."
                />
                <button
                    className={classes.submit}
                    type="button"
                    onClick={(e) => sendMessageHandle(e)}
                >
                    <i className="fa fa-paper-plane" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    )
};

export default InputMessage;