import React, { Component } from "react";
import { Link } from "react-router-dom";

class Pagination extends Component {
  pagesList() {
    const totalRecord = this.props.totalRecord;
    const otherData = this.props.otherData;
    const pages = Math.ceil(totalRecord / otherData.recordPerPage);
    let item = [];
    let count = 0;
    let startIndex =
      pages < otherData.page + 3
        ? pages <= 2
          ? 1
          : pages - 2
        : otherData.page;
    for (let i = startIndex; i <= pages; i++) {
      if (count >= 3) {
        break;
      }
      item.push(
        <li
          className={`page-item ${otherData.page === i ? "active" : ""}`}
          key={i}
        >
          <Link
            to="#"
            className="page-link"
            onClick={() => {
              this.changePage(i);
            }}
          >
            {i}
          </Link>
        </li>
      );
      count++;
    }
    return item;
  }

  changePage(i) {
    if (this.props.otherData.page !== i) {
      this.props.changePage(i);
    }
  }

  render(props) {
    const totalRecord = this.props.totalRecord;
    const otherData = this.props.otherData;
    const startIndex = totalRecord
      ? otherData.recordPerPage * (otherData.page - 1) + 1
      : 0;
    const lastIndex = otherData.recordPerPage * otherData.page;
    const pages = Math.ceil(totalRecord / otherData.recordPerPage);
    const startPage =
      pages < otherData.page + 3 ? (pages < 2 ? 1 : pages - 2) : otherData.page;
    const lastPage = startPage + 2 > pages ? pages : startPage + 2;
    return (
      <div className="card-footer d-flex justify-content-between flex-column flex-lg-row border-top">
        <div className="count-text">
          <p className="mb-0 font-weight-bold">
            Showing {startIndex} -{" "}
            {lastIndex <= totalRecord ? lastIndex : totalRecord} of{" "}
            {totalRecord}
          </p>
        </div>
        <nav className="table-pagination">
          <ul className="pagination">
            {startPage - 2 > 0 && (
              <li className="page-item">
                <Link
                  to="#"
                  className="page-link"
                  onClick={() => {
                    this.changePage(startPage - 2);
                  }}
                >
                  <i className="bx bx-chevrons-left"></i>
                </Link>
              </li>
            )}
            {startPage - 1 > 0 && (
              <li className="page-item">
                <Link
                  to="#"
                  className="page-link"
                  onClick={() => {
                    this.changePage(startPage - 1);
                  }}
                >
                  <i className="bx bx-chevron-left"></i>
                </Link>
              </li>
            )}
            {this.pagesList()}
            {lastPage + 1 <= pages && (
              <li className="page-item">
                <Link
                  to="#"
                  className="page-link"
                  onClick={() => {
                    this.changePage(startPage + 1);
                  }}
                >
                  <i className="bx bx-chevron-right"></i>
                </Link>
              </li>
            )}
            {lastPage + 2 <= pages && (
              <li className="page-item">
                <Link
                  to="#"
                  className="page-link"
                  onClick={() => {
                    this.changePage(lastPage + 1);
                  }}
                >
                  <i className="bx bx-chevrons-right"></i>
                </Link>
              </li>
            )}
          </ul>
        </nav>
      </div>
    );
  }
}

export default Pagination;
