import Axios from "axios";
import environment from "../../Models/env";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../Components/LocalStorages";
import toast from "react-hot-toast";

export const getAccessTokenFromRefreshToken = async () => {
  try {
    const clientId = environment.CLIENT_ID;
    const clientSecret = environment.CLIENT_SECRET;
    const refreshToken = localStorage.getItem("GOOGLE_REFRESH_TOKEN");
    if(!refreshToken) return console.log("No refresh token")
    // Define the request body
    const requestBody = {
      client_id: clientId,
      client_secret: clientSecret,
      grant_type: "refresh_token",
      refresh_token: refreshToken,
    };
    const response = await Axios.post(
      "https://oauth2.googleapis.com/token",
      null,
      {
        params: requestBody,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    const expiresIn = Date.now() + (response.data.expires_in * 1000)
    localStorage.setItem("GOOGLE_ACCESS_TOKEN", response.data.access_token);
    if(expiresIn) {
      localStorage.setItem("GOOGLE_ACCESS_TOKEN_EXPIRES_IN", expiresIn)
    }
    if(window?.gapi?.auth?.setToken) {
      window.gapi.auth.setToken({ access_token: response.data.access_token });
    }
    return {
      expires_in: expiresIn,
      access_token: response.data.access_token
    }
  } catch (error) {
    console.error("Error fetching access token:", error);
  }
};

export const exchangeCode = async () => {
  try {
    const payload = {
      code: this.state.googleAuthCode,
      client_id: environment.CLIENT_ID,
      scope: environment.SCOPES,

      client_secret: environment.CLIENT_SECRET,
      redirect_uri: window.location.origin,//environment.frontEndUrl,
      grant_type: "authorization_code",
    };
    const response = await Axios.post(
      "https://oauth2.googleapis.com/token",
      payload
    );
    setLocalStorageItem("GOOGLE_REFRESH_TOKEN", response.data.refresh_token);
    this.getAccessTokenFromRefreshToken();
    return response.data.refresh_token
  } catch (error) {
    console.log("Could not get the token from code");
    return null
  }
};

export const assignCalendarToken = async () => {
  try {
    const GOOGLE_ACCESS_TOKEN = localStorage.getItem("GOOGLE_ACCESS_TOKEN");
    const GOOGLE_REFRESH_TOKEN = localStorage.getItem("GOOGLE_REFRESH_TOKEN");
    const GOOGLE_ACCESS_TOKEN_EXPIRES_IN = localStorage.getItem('GOOGLE_ACCESS_TOKEN_EXPIRES_IN');

    if(GOOGLE_ACCESS_TOKEN) return;
    
    if((!GOOGLE_ACCESS_TOKEN || !GOOGLE_ACCESS_TOKEN_EXPIRES_IN) && GOOGLE_REFRESH_TOKEN) {
     const newToken = await getAccessTokenFromRefreshToken() 
     if(window.gapi?.auth?.setToken) {
        window.gapi.auth.setToken({ access_token: newToken });
     }
    } else if(GOOGLE_ACCESS_TOKEN && GOOGLE_REFRESH_TOKEN) {
        const current = Date.now();
        if(GOOGLE_ACCESS_TOKEN_EXPIRES_IN >= current) {
          // GET CONTENT, GOOD TO GO
        } else {
          const newToken = await getAccessTokenFromRefreshToken() 
          if(window.gapi?.auth?.setToken) {
             window.gapi.auth.setToken({ access_token: newToken });
          }
        }
    } else {
      //toast("Can't work with calendar, Please login into google account to keep your events synced.")
    }
  } catch (error) {
    //toast.error("Can't work with calendar, Please login into google account")
    console.log(error)
  }
}
