import React, { forwardRef, useImperativeHandle } from "react";

const CenterModal = forwardRef(
  (
    {
      children,
      modalClass,
      title,
      onSubmit,
      onWelcome,
      isRemove,
      sendWelcome,
      viewPopup,
      isUpdate,
      btnHide,
      isClient,
      cancelButton,
      isArchive,
      onClose = () => { },
      backDrop = false,
      submitBtnTitle = ""
    },
    ref
  ) => {
    const closeBtn = document.getElementById(`${modalClass}close`);
    const openBtn = document.querySelector(`.${modalClass}`);

    useImperativeHandle(ref, () => ({ openModal, closeModal }));

    const openModal = () => {
      openBtn.click();
    };

    const closeModal = () => {
      closeBtn.click();
      onClose();
    };

    return (
      <React.Fragment>
        <button
          type="button"
          className={`btn btn-outline-secondary d-none ${modalClass}`}
          style={{ opacity: "0", visibility: "hidden" }}
          data-target={`#${modalClass}`}
          data-toggle="modal"
          data-backdrop={backDrop ? 'static' : true}
        >
          {title}
        </button>
        <div
          className="modal fade right shadow"
          id={modalClass}
          // enforceFocus="false"
          tabIndex="-1"
          role="dialog"
          aria-hidden="false"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-header mb-0">
              <h4 className="card-header-title font-size-h5 text-dark font-weight-bold text-capitalize pt-2">
                {title || ""}
              </h4>
              <button
                type="button"
                className="close pt-4"
                id={modalClass + "close"}
                data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  closeModal(e);
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-content">
              <div className="modal-card">
                <div className="card shadow-none">
                  <div className="card-body">{children}</div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {!btnHide && (
                <React.Fragment>
                  {sendWelcome && (
                    <button
                      type="button"
                      className="material-button bg-mt"
                      onClick={() => {
                        onWelcome();
                      }}
                    >
                      <i className="bx bx-paper-plane mr-1"></i>Send Welcome Email
                    </button>
                  )}
                  {isRemove && (
                    <button
                      type="button"
                      className="material-button bg-mt"
                      onClick={() => {
                        isRemove();
                      }}
                    >
                      <i className="bx bx-trash"></i>Remove
                    </button>
                  )}
                  {!!isArchive && (
                    <button
                      type="button"
                      className="material-button"
                      onClick={(e) => {
                        onSubmit(e);
                      }}
                    >
                      <i className="bx bxs-archive"></i> Archive Project
                    </button>
                  )}
                  {!(viewPopup === "view")
                    ? isClient !== "yes" &&
                      !isArchive && (
                        <button
                          type="button"
                          className="material-button"
                          onClick={(e) => {
                            onSubmit(e);
                          }}
                        >
                          <i
                            className={`${
                              !submitBtnTitle ? (isUpdate === "yes"
                                ? "fa fa-refresh"
                                : "bx bx-plus") : ""
                            }`}
                          ></i>{" "}
                          {!submitBtnTitle ? (isUpdate === "yes" ? "Update" : "Add") : submitBtnTitle}
                        </button>
                      )
                    : ""}
                </React.Fragment>
              )}
              {!cancelButton && (
                <button
                  type="button"
                  id="cancelModal"
                  className="material-button bg-mt-light close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => {
                    closeModal(e);
                  }}
                >
                  Cancel
                </button>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
);

export default CenterModal;
