import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import "bootstrap/scss/bootstrap.scss";
import './assets/css/vendors.min.css';
import './assets/css/font-awesome.min.css';
import './assets/css/form-block.css';
import './assets/css/Home.styles.css';

// import "./assets/css/form-block.css";
import "./assets/css/responsive.css";
import "./assets/css/theme.min.css";
import "./assets/css/style.css";
import "./assets/scss/overriding.scss"

import App from "./App";
import "./assets/css/homeStyle.css"; // IMPORTANT : this css file needs to be imported after app component to override the css

import * as serviceWorker from "./serviceWorker";

import store from "./store/store";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
