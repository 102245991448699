import {
  getMessages,
  getNotification,
  getFriendsList,
  getChannelUsers,
  sendRequest,
  getAllUsers,
  getChanelList,
  acceptInvite,
  getCurrentUser,
  markAsReadNotification,
  messageMarkAsRead,
  unreadCount,
  leaveGroup,
  changeStatus
} from "../../Modules/admin/Service/chat/service";
import _cloneDeep from "lodash/cloneDeep";

export const fetchMessages = (id) => {
  return (dispatch) => {
    getMessages(id)
      .then((res) => {
        dispatch({ type: "GET_MESSAGES", payload: res.data.data });
      })
      .catch((err) => console.log(err));
  };
};

export const getNotifications = (id) => (dispatch) => {
  getNotification()
    .then((res) => {
      const notifications = res.data;
      dispatch({ type: "GET_NOTIFICATIONS", payload: notifications });
    })
    .catch((err) => console.log(err));
};

export const getFriendLists = (callback) => (dispatch, getState) => {
  getFriendsList()
    .then((res) => {

      dispatch({ type: "FRIEND_LIST", payload: res.data });
      dmChannelsHandler(res.data, dispatch, getState); // Listen to messages' events on channels
    })
    .catch(/*(err) => console.log(err)*/);
};

export const getChannelUsersList = (id) => (dispatch) => {
  dispatch({ type: "FETCH_CHANNEL_USER_REQUEST" });

  getChannelUsers(id)
    .then((res) => {

      dispatch({ type: "CHANNEL_USERS_LIST", payload: res.data || [] });
    })
    .catch((err) => console.log(err));
};

export const fetchAllUsers = (data) => (dispatch) => {
  dispatch({ type: "FETCH_CHAT_USERS_REQUEST" });

  getAllUsers(data)
    .then((res) => {
      dispatch({ type: "FETCH_CHAT_USERS_SUCCESS" });

      if (res.data.success) {
        dispatch({
          type: "ALL_USERS",
          payload: res.data.data.totalRecords ? res.data.data.records : [],
        });
      } else {
        dispatch({ type: "ALL_USERS", payload: [] });
      }
    })
    .catch((err) => console.log(err));
};

export const getChannelsList = () => (dispatch, getState) => {
  getChanelList()
    .then((res) => {
      dispatch({ type: "CHANNEL_LIST", payload: res.data });
      channelsHandler(res.data, dispatch, getState);
    })
    .catch(/*(error) => { console.log(error); }*/);
}

// Set selected channel
export const dmSelectAction = (channel_id, username, avatar, activeChannel) => {
  return (dispatch, getState) => {
    const channel = { "id": channel_id, "type": "dm", "name": username, "avatar": avatar, "unread_message_count": 0 };
    dispatch({ type: "SET_SELECTED_CHANNEL", payload: channel });

    if (activeChannel.unread_message_count > 0) {
      let totalUnreadCount = getState().chat.unreadCount;
      totalUnreadCount = totalUnreadCount - activeChannel.unread_message_count
      dispatch({ type: "UPDATE_TOTAL_UNREAD_COUNT", payload: totalUnreadCount });
    }
  };
};

// For group channels
export const channelSelect = (channel_id, channel_name, desc, owner_id, owner, owner_avatar, activeChannel) => {

  return (dispatch, getState) => {

    dispatch({ type: "FETCH_CHANNEL_USER_REQUEST" });

    getChannelUsers(channel_id)
      .then((res) => {

        dispatch({ type: "CHANNEL_USERS_LIST", payload: res.data || [] });
        const users = res.data[0].users;
        const channel = { "id": channel_id, "type": "channel", "name": channel_name, "desc": desc, "owner_id": owner_id, "owner": owner, "owner_avatar": owner_avatar, "users": users };
        dispatch({ type: "SET_SELECTED_CHANNEL", payload: channel });

      })
      .catch((error) => {
        console.log(error);
      });

    if (activeChannel.unread_message_count > 0) {
      let totalUnreadCount = getState().chat.unreadCount;
      totalUnreadCount = totalUnreadCount - activeChannel.unread_message_count
      dispatch({ type: "UPDATE_TOTAL_UNREAD_COUNT", payload: totalUnreadCount });
    }
  };
};


export const makeRequest = (id, hideAddUser) => (dispatch) => {
  dispatch({ type: "SEND_REQUEST" });

  sendRequest(id)
    .then((res) => {
      if (res.data.success) {
        const request = res.data.data;
        dispatch({ type: "SEND_REQUEST_SUCCESS", payload: request });
      } else {
        dispatch({ type: "SEND_REQUEST_ERROR", error: res.data.error });
      }

      hideAddUser();
    })
    .catch((err) => {
      dispatch({ type: "SEND_REQUEST_ERROR", error: err.message });
    });
};

// When request is accepted by user update their chat contacts accordingly
export const acceptRequest = (id, notification_id) => (dispatch, getState) => {
  dispatch({ type: "SET_LOADING" });
  acceptInvite(id)
    .then((res) => {
      const response = res.data;
      if (response.success) {
        if (response.data.type !== 'private') {

          dispatch({ type: "ACCEPT_REQUEST_SUCCESS", payload: response.data });
          listenDmChannelEvents(response.data.id, dispatch, getState);

        } else if (response.data.type === 'private') {
          dispatch({ type: "ADD_CHANNEL_SUCCESS", payload: response.data });
          listenPrivateChannelEvents(response.data.id, dispatch, getState)
        }

        // On Accepting request mark notification as read
        dispatch(markAsRead(notification_id));
      }
    })
}

export const getUser = () => (dispatch, getState) => {
  dispatch({ type: "FETCH_USER" });
  getCurrentUser()
    .then((res) => {
      const response = res.data;
      dispatch({ type: "FETCH_USER_SUCCESS", payload: response });
    })
    .catch((err) => console.log(err));

}
export const markAsRead = (id) => (dispatch, getState) => {
  markAsReadNotification(id)
    .then((res) => {

      dispatch({ type: "NOTIF_MARK_AS_READ", payload: res.data });
    })
    .catch((err) => console.log(err));
}


export const dmChannelsHandler = (dmChannels, dispatch, getState) => {

  dmChannels.map(channel => {
    // Join private chat channel
    window.Echo.leave(`chat.dm.${channel.id}`)
    listenDmChannelEvents(channel.id, dispatch, getState);
    return window.Echo;
  });
}

export const channelsHandler = (channels, dispatch, getState) => {
  channels.map(channel => {
    // Join private chat channel
    window.Echo.leave(`chat.channel.${channel.id}`)
    listenPrivateChannelEvents(channel.id, dispatch, getState)
    return window.Echo;
  });
}

export const setTyping = (channelType, channelId, user) => (dispatch, getState) => {
  if (channelType === 'dm') {
    setTimeout(() => {
      window.Echo.private(`chat.dm.${channelId}`).whisper('typing', {
        user,
        typing: true,
        channelId
      })
    }, 300);
  } else {
    setTimeout(() => {
      window.Echo.private(`chat.channel.${channelId}`).whisper('typing', {
        user,
        typing: true,
        channelId
      })
    }, 300);
  }

}

/**
 * Fetch Total unread count
 */
export const fetchUnreadCount = () => (dispatch) => {
  unreadCount()
    .then((res) => {
      dispatch({ type: "UPDATE_TOTAL_UNREAD_COUNT", payload: res.data });
    })
    .catch(/*(err) => dispatch({ type: "SET_CHAT_ERROR", error: err.message })*/);
};

/**
 * Leaves Group
 */
export const leaveFromGroup = (id) => (dispatch) => {
  dispatch({ type: "LEAVE_GROUP_REQUEST" });

  leaveGroup(id)
    .then((res) => {
      dispatch({ type: "LEAVE_GROUP_SUCCESS", payload: id });

      if (res.data.success) {
        leavePrivateChannel(id);
      } else {
        dispatch({ type: "LEAVE_GROUP_ERROR", error: res.data.message });
      }
    })
    .catch((err) => dispatch({ type: "LEAVE_GROUP_ERROR", error: err.message }));
};

/**
 * Change status
 */

export const changeChatStatus = (status) => (dispatch) => {
  dispatch({ type: "CHANGE_STATUS" })

  changeStatus({ status })
    .then((res) => {
      if (res.data.success) {
        dispatch({ type: "CHANGE_STATUS_SUCCESS" });
      } else {
        dispatch({ type: "CHANGE_STATUS_ERROR", error: res.data.error });
      }
    });
};

export const listenDmChannelEvents = (id, dispatch, getState) => {
  window.Echo.private(`chat.dm.${id}`)
    .listen("MessageSent", event => {
      const activeChannel = getState().chat.selectedChannel;
      let totalUnreadCount = getState().chat.unreadCount;

      const typingEvent = {
        user: event.user,
        type: 'typing'
      }

      dispatch({ type: "REMOVE_TYPING_EVENT", payload: typingEvent });
      let message = _cloneDeep(event.message);
      message.user = event.user;
      message.isCurrentChannel = activeChannel.id === id ? 1 : 0

      dispatch({ type: "ADD_MESSAGE", payload: message });

      if (activeChannel.id === event.channel) {
        activeChannel.unread_message_count = 0;
        if (activeChannel.id === id) {
          messageMarkAsRead(activeChannel.id)
            .then((res) => {
              //console.log(res);
            })
            .catch((err) => console.log(err));
        }
      }

      if (event.channel !== activeChannel.id) {
        const friends = getState().chat.friends

        let channeluser = friends.find(friend => friend.id === event.channel);

        channeluser.unread_message_count = ((channeluser.unread_message_count || 0) + 1);
        totalUnreadCount += 1;

        dispatch({ type: "UPDATE_UNREAD_MESSAGE", payload: channeluser });
        dispatch({ type: "UPDATE_TOTAL_UNREAD_COUNT", payload: totalUnreadCount });
      }

      // Set latest message
      updateLatestMessage(event.message, event.channel, event.type, dispatch);


    }).listenForWhisper("typing", event => {
      let timer
      const message = {
        user: event.user,
        type: 'typing',
        channelId: event.channelId
      }

      dispatch({ type: "ADD_TYPING_EVENT", payload: message });

      clearTimeout(timer)

      timer = setTimeout(() => {
        dispatch({ type: "REMOVE_TYPING_EVENT", payload: message });
      }, 3000)

    });
}

export const listenPrivateChannelEvents = (id, dispatch, getState) => {
  window.Echo.private(`chat.channel.${id}`)
    .listen("MessageSent", event => {
      const activeChannel = getState().chat.selectedChannel;

      let message = _cloneDeep(event.message);
      message.user = event.user;
      message.isCurrentChannel = activeChannel.id === id ? 1 : 0

      dispatch({ type: "ADD_MESSAGE", payload: message });
      const typingEvent = {
        user: event.user,
        type: 'typing'
      }
      dispatch({ type: "REMOVE_TYPING_EVENT", payload: typingEvent });

      if (activeChannel.id === event.channel) {
        activeChannel.unread_message_count = 0;
        if (activeChannel.id === id) {
          messageMarkAsRead(activeChannel.id)
            .then((res) => {
              //console.log(res);
            })
            .catch((err) => console.log(err));
        }
      }

      if (event.channel !== activeChannel.id) {
        const groupList = getState().chat.groupList
        let totalUnreadCount = getState().chat.unreadCount;

        let channelobj = groupList.find(channel => channel.id === event.channel);
        channelobj.unread_message_count += 1;
        totalUnreadCount += 1;

        dispatch({ type: "UPDATE_UNREAD_GROUP_MESSAGE", payload: { id: event.channel, unread_message_count: channelobj.unread_message_count } });
        dispatch({ type: "UPDATE_TOTAL_UNREAD_COUNT", payload: totalUnreadCount });
      }

      // Set latest message
      updateLatestMessage(event.message, event.channel, event.type, dispatch);

    })
    .listenForWhisper("typing", event => {
      let typingTimer;
      var doneTypingInterval = 3000;

      const message = {
        user: event.user,
        type: 'typing',
        channelId: event.channelId
      }
      clearTimeout(typingTimer);
      dispatch({ type: "ADD_TYPING_EVENT", payload: message });

      typingTimer = setTimeout(() => {
        dispatch({ type: "REMOVE_TYPING_EVENT", payload: message });
      }, doneTypingInterval)

    });
}

export const leavePrivateChannel = (id) => {
  if (typeof window.Echo.leave === "function") {
    window.Echo.leave(`chat.channel.${id}`);
  }
};

/**
 * Set latest message to channel whenever any new message is recieved
 */
const updateLatestMessage = (message, channel, type, dispatch) => {
  const data = {
    message: message.message,
    messageDate: message.created_at,
    type: type,
    id: channel
  }
  dispatch({ type: "SET_LATEST_MESSAGE", payload: data })
}

/**
 * Resets the selected channel
 */
export const resetSelectedChannel = () => (dispatch) => {
  dispatch({ type: "RESET_SELECTED_CHANNEL" })
}