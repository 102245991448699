import React from "react";

import NoRecords from "../not-found/RecordsNotFound";

class ErrorBoundaries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      return <NoRecords message="Page has been broken!" />;
    }
    return this.props.children;
  }
}

export default ErrorBoundaries;
