import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import {
  validateField,
  setValue,
  validateAllFields,
} from "../../../Shared/Services/validationService";
import { userresetPassword } from "../Service/loginService";
import Errormsg from "../../../Shared/Components/Errormsg";

import logo from "../../../assets/images/logo.png";
import defaultImage from "../../../Shared/Components/ImgUtils";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.setFormFields();
    this.state = {
      formData: this.setForm,
      apiError: "",
      showLoader: "",
      apiMessage: "",
    };
  }

  onChange(e) {
    this.setForm[e.target.name]["value"] = setValue(
      e,
      this.setForm[e.target.name]["type"]
    );
    let validate = validateField(this.setForm[e.target.name]);
    this.setForm[e.target.name]["error"] = validate.validationMsg;
    this.setState({ formData: this.setForm });
  }

  handleBlur(event) {
    this.setForm[event.target.name]["touched"] = true;
    this.setState({ formData: this.setForm });
  }

  submitForm(e) {
    e.preventDefault();
    let isFormValid = validateAllFields(this.state.formData);
    if (isFormValid.validated) {
      let formData = this.state.formData;
      let data = {
        password: formData.password.value,
        confirm_password: formData.cpassword.value,
        reset_token: this.props.token,
        login_type: "admin",
      };
      this.setState({ showLoader: true });
      userresetPassword(data)
        .then((res) => {
          if (res.data.success) {
            this.setState({ apiMessage: res.data.message });
            this.props.setsm(res.data.message);
            this.props.history.push("/app/login");
          } else {
            this.props.setsm(res.data.message);
            this.setState({ apiError: res.data.message, showLoader: false });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({ showLoader: false });
        });
    } else {
      let fields = Object.keys(this.setForm);
      for (let i = 0; i < fields.length; i++) {
        this.setForm[fields[i]].error = isFormValid.errors[fields[i]];
        this.setForm[fields[i]].touched = true;
      }
      this.setState({
        formData: this.setForm,
      });
    }
  }

  setFormFields() {
    this.setForm = {
      password: {
        name: "Password",
        value: "",
        touched: false,
        error: "",
        type: "password",
        validation: [{ required: true }, { maxLength: 50 }, { pattern: true }],
      },
      cpassword: {
        name: "Confirm password",
        value: "",
        touched: false,
        error: "",
        type: "password",
        validation: [{ required: true }, { match: "" }],
      },
    };
  }

  setPassword(e) {
    this.setForm["cpassword"]["validation"][1].match = e.target.value;
    this.setState({
      formData: this.setForm,
    });
  }

  render(props) {
    return (
      <React.Fragment>
        <div className="login-area">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="login-form">
                <div className="logo">
                  <img
                    src={logo}
                    alt="logo"
                    width="200"
                    onError={(e) => {
                      e.persist();
                      defaultImage(e, "logo");
                    }}
                  />
                </div>
                <div className="reset-password-form">
                  <h2>Reset Your Password</h2>
                  {this.state.apiError && (
                    <p className="form-text text-danger text-center ">
                      <small>{this.state.apiError}</small>
                    </p>
                  )}
                  {this.state.apiMessage && (
                    <p className="form-text text-success text-center ">
                      <small>{this.state.apiMessage}</small>
                    </p>
                  )}
                  <form
                    onSubmit={(e) => {
                      this.submitForm(e);
                    }}
                  >
                    <div className="mb-4">
                      <div className="form-group mb-0">
                        <input
                          type="password"
                          className="form-control"
                          name="password"
                          placeholder="Enter a new password"
                          onChange={(e) => {
                            this.onChange(e);
                            this.setPassword(e);
                          }}
                          onBlur={(e) => {
                            this.handleBlur(e);
                          }}
                          onFocus={(e) => {
                            this.onChange(e);
                          }}
                        />
                        <span className="label-title">
                          <i className="bx bx-lock"></i>
                        </span>
                      </div>
                      <Errormsg errormsg={this.state.formData.password} />
                    </div>
                    <div className="mb-4">
                      <div className="form-group mb-0">
                        <input
                          type="password"
                          className="form-control"
                          name="cpassword"
                          placeholder="Confirm your new password"
                          onChange={(e) => {
                            this.onChange(e);
                          }}
                          onBlur={(e) => {
                            this.handleBlur(e);
                          }}
                          onFocus={(e) => {
                            this.onChange(e);
                          }}
                        />
                        <span className="label-title">
                          <i className="bx bx-lock-alt"></i>
                        </span>
                      </div>
                      <Errormsg errormsg={this.state.formData.cpassword} />
                    </div>
                    <button type="submit" className="login-btn">
                      Reset my Password
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.showLoader && (
          <div className="loaderContener">
            <span className="Ploader">
              <span className="loader-inner"></span>
            </span>
          </div>
        )}
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setsm: (msg) => {
      dispatch({ type: "setsm", msg: msg });
    },
  };
}

export default connect(null, mapDispatchToProps)(withRouter(ResetPassword));
