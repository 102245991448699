import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { VendorToSubscriber } from "../../Service/Vendor/vendorService";
import Loader from "../../../../Shared/Components/Loader";
import {
  setLocalStorageItem,
  removeLocalStorageItem,
} from "../../../../Shared/Components/LocalStorages";

class VendorReminder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
    };
  }

  componentDidMount() {
    //this.props.setvs();
  }

  //handleSubscription
  handleSubscription(e) {
    e.preventDefault();
    const { userInfo } = this.props;
    if (userInfo) {
      VendorToSubscriber(userInfo["encrypt_id"])
        .then((res) => {
          if (res.data.success) {
            let userData = {
              ...this.props.userInfo,
              all_types: res.data.all_types,
            };
            removeLocalStorageItem("userInfo");
            setTimeout(() => {
              setLocalStorageItem("userInfo", userData);
              window.location.reload(false);
            }, 0);
          }
        })
        .catch((err) => console.log(err));
    }
  }

  //render
  render(props) {
    const { showLoader } = this.state;

    //return
    return (
      <React.Fragment>
          <div
            className="alert alert-warning alert-dismissible fade show"
            role="alert"
          >
            <strong>Hello {this.props.userInfo.name}! </strong>
            {this.props.expireMessage === 1 ? (
              `Free Trial has expired - ${(
                <Link to="#" className="alert-link">
                  {" "}
                  Subscribe{" "}
                </Link>
              )} Now.`
            ) : (
              <React.Fragment>
                Take control of your business with Harpsen - To Start Your Free Trial
                <Link
                  to="#"
                  className="alert-link"
                  onClick={(e) => {
                    this.handleSubscription(e);
                  }}
                >
                  {" "}
                  Click Here.
                </Link>
              </React.Fragment>
            )}
            {/*<button type="button" className="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>*/}
          </div>
        <Loader showLoader={showLoader} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { popInfo: state.updateData };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setvs: () => {
      dispatch({ type: "setvs", msg: "message" });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VendorReminder));
