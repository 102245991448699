import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import IdleTimer from 'react-idle-timer';

import classes from "./Chat.module.css";

import {
  markAway,
  markOnline
} from "../../Service/chat/service";
import {
  token,
  userInfoData,
  getLocalStorageItem,
} from "../../../../Shared/Components/LocalStorages";

import {
  fetchMessages,
  getFriendLists,
  getChannelUsersList,
  fetchAllUsers,
  getChannelsList,
  channelSelect,
  dmSelectAction,
  makeRequest,
  fetchUnreadCount,
  getUser,
  resetSelectedChannel
} from "../../../../store/chat/chat-actions";

import { echoInit } from "./echoHelpers";

import ChatUI from "./ChatUI";
import GroupModal from "./Group";
import _isEmpty from "lodash/isEmpty";
import _get from "lodash/get";
import ErrorToast from "../../../../Shared/Components/ErrorToast";

class Chat extends Component {

  constructor(props) {
    super(props);
    this.modalRef = React.createRef();

    let epDetails = {};
    if (getLocalStorageItem("evpId")) {
      epDetails = getLocalStorageItem("evpId");
    }
    this.state = {
      groupList: this.props.groupList || [],
      isOpen: false,
      activeChannel: {},
      showAddUsers: false,
      contacts: this.props.friends || [],
      messages: this.props.messages || [],
      activeUser: userInfoData(),
      searchQuery: "",
      searchContact: "",
      epDetails,
      modalType: "group",
      showChatPopup: false,
      message: "",
    };

    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
  }

  componentDidMount() {
   if (this.state.showChatPopup){
    echoInit(token(), this.state.activeUser.id);
    this.props.onGetChannelsList();
    this.props.getFriendLists();
    this.props.fetchUnreadCount();
    this.props.getUser();
   }
  }

  componentDidUpdate(props, state) {
    if (props.messages !== this.props.messages) {
      this.setState({ messages: this.props.messages });
    }

    if (props.friends !== this.props.friends) {
      this.setState({ contacts: this.props.friends });
    }

    if (props.groupList !== this.props.groupList) {
      this.setState({ groupList: this.props.groupList });
    }

    //Reset error 
    if (props.error !== this.props.error && this.props.error) {
      this.props.resetError();
    }

    // If selected channel changes (when user leaves the group) updated active channel accordingly
    if (props.selectedChannel !== this.props.selectedChannel && _isEmpty(this.props.selectedChannel)) {
      this.setState({
        activeChannel: {}
      })
    }
  }

  _onAction(e) {
  }

  // When user goes into active state from idle
  _onActive(e) {
    markOnline(this.state.activeUser.id);
  }

  // on idle state
  _onIdle(e) {
    markAway(this.state.activeUser.id);
  }

  onAddUser = (id) => {
    this.props.makeRequest(id, this.hideAddUser);
  };

  //getAllUSers
  handleAddContact = () => {
    this.setState({ showAddUsers: true }, () => {
      this.fetchAllUsers();
    });
  };

  hideAddUser = () => {
    this.setState({ showAddUsers: false });
  };

  fetchAllUsers() {
    let data = {};
    const { activeUser, epDetails } = this.state;
    if (
      activeUser.userType === "CL" ||
      activeUser.userType === "VN" ||
      activeUser.userType === "VN_TM"
    ) {
      data.user_id = epDetails.ep_id;
    }
    this.props.getAllUsers(data);
  }

  setSearchQuery = (txt, type = 'user') => {
    if (type === 'user') {
      this.setState({ searchQuery: txt })
    } else {
      this.setState({ searchContact: txt })
    }
  };

  // Handles contact selection
  onContactClick = (id, type) => {
    const { groupList, contacts } = this.state;

    // Handle Group channel selection
    if (type === "group") {
      let updatedContacts = contacts.map((u) => {
        u.current = "";
        return u;
      });

      let activeChannel = {};

      let updatedGroup = groupList.map((g) => {
        g.current = "";
        if (g.id === id) {
          g.current = "active";
          activeChannel = { ...g, current: "active", channelType: "channel", unread_message_count: g.unread_message_count };
          g.unread_message_count = 0;
        }
        return g;
      });

      this.setState(
        {
          groupList: updatedGroup,
          activeChannel,
          contacts: updatedContacts,
        },
        () => {
          this.props.getMessages(id);
        }
      );
      this.props.channelSelect(activeChannel.id, activeChannel.name, activeChannel.desc, activeChannel.owner_id, activeChannel.owner, activeChannel.owner_avatar, activeChannel);
    }

    // Handle contact channel selection
    if (type === "user") {
      let updatedGroup = groupList.map((g) => {
        g.current = "";
        return g;
      });

      let activeChannel = {};
      let updatedContacts = contacts.map((u) => {
        u.current = "";
        if (u.id === id) {
          u.current = "active";
          activeChannel = { ...u, current: "active", channelType: "dm", unread_message_count: u.unread_message_count };
          u.unread_message_count = 0;
        }

        return u;
      });

      this.props.dmSelectAction(activeChannel.id, activeChannel.users[0].first_name, activeChannel.users[0].avatar, activeChannel)

      this.setState(
        {
          contacts: updatedContacts,
          activeChannel,
          groupList: updatedGroup,
        },
        () => {
          this.props.getMessages(id);
        }
      );
    }
  };

  openModal = () => {
    this.setState({ modalType: "group" }, () => {
      this.modalRef.current.openModals();
    });
  };

  handleAddUserToGroup = () => {
    this.setState(
      { modalType: "user" },
      () => {
        this.modalRef.current.openModals();
      });
  };

  handleGetUsers = () => {
    this.setState({ modalType: "group-details" }, () => {
      const { activeChannel } = this.state;

      this.props.getChannelUsersList(activeChannel.id);
      this.modalRef.current.openModals();
    });
  };

  toggleChatHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.state.showChatPopup && !_isEmpty(this.props.selectedChannel)) {
      this.props.resetSelectedChannel();
    }
    echoInit(token(), this.state.activeUser.id);
    this.props.onGetChannelsList();
    this.props.getFriendLists();
    this.props.fetchUnreadCount();
    this.props.getUser()
    this.setState({ showChatPopup: !this.state.showChatPopup });
  };

  render(props) {
    const {
      activeChannel,
      messages,
      contacts,
      activeUser,
      showAddUsers,
      searchQuery,
      searchContact,
      groupList,
      modalType,
      showChatPopup,
    } = this.state;

    const { contactList, loading, error, unreadCount } = this.props;

    return (
      <>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          timeout={1000 * 60 * 15}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
        />
        <div className={classes.chat_container}>
          {showChatPopup ? (
            <div
              className={`${classes.chat_modal} ${_isEmpty(activeChannel) ? classes.no_contact : ''}`}
            >
              <ChatUI
                setSearchQuery={this.setSearchQuery}
                onAddUser={this.onAddUser}
                onContactClick={this.onContactClick}
                hideAddUser={this.hideAddUser}
                handleAddContact={this.handleAddContact}
                activeChannel={activeChannel}
                messages={messages}
                contacts={contacts}
                activeUser={activeUser}
                showAddUsers={showAddUsers}
                contactList={contactList}
                searchQuery={searchQuery}
                searchContact={searchContact}
                openModal={this.openModal}
                groupList={groupList}
                handleAddUserToGroup={this.handleAddUserToGroup}
                handleGetUsers={this.handleGetUsers}
                showChatPopup={showChatPopup}
                toggleChatHandler={this.toggleChatHandler}
                showLoader={loading}
                resetActiveChannel={() => this.setState({ activeChannel: {} }, () => {
                  this.props.resetSelectedChannel()
                })}
              />
              <GroupModal
                ref={this.modalRef}
                setsm={(msg) => this.props.setsm(msg)}
                modalType={modalType}
                addedUsers={this.props.addedUsersList || []}
                updateGroup={this.props.onGetChannelsList}
                activeChannel={activeChannel}
              />
            </div>
          ) : (
            ""
          )}
          <>
            {(unreadCount > 0 && !showChatPopup) && (<span className={`badge badge-secondary ${classes.chat_unread_count_badge} 
            ${this.props.location?.pathname?.includes("/app/project/view/")
                ? classes.chat_unread_count_badge_right
                : ""
              }`}>{unreadCount}</span>)}
            <button
              type="button"
              className={`${classes.chat_toggle_btn} ${showChatPopup ? "d-none" : ""
                } ${this.props.location?.pathname?.includes("/app/project/view/")
                  ? classes.btnRight
                  : ""
                }`}
              onClick={this.toggleChatHandler}
            >
              <i className={`bx bx-${showChatPopup ? "x" : "chat"}`}></i>
            </button>
          </>
        </div>
        <ErrorToast error={error} />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setsm: (msg) => dispatch({ type: "setsm", msg: msg }),
    getMessages: (id) => dispatch(fetchMessages(id)),
    getFriendLists: () => dispatch(getFriendLists()),
    getChannelUsersList: (id) => dispatch(getChannelUsersList(id)),
    getAllUsers: (data) => dispatch(fetchAllUsers(data)),
    onGetChannelsList: () => dispatch(getChannelsList()),
    channelSelect: (selectedChannel, channelName, desc, owner_id, owner, owner_avatar, activeChannel) => dispatch(channelSelect(selectedChannel, channelName, desc, owner_id, owner, owner_avatar, activeChannel)),
    dmSelectAction: (id, name, avatar, activeChannel) => dispatch(dmSelectAction(id, name, avatar, activeChannel)),
    makeRequest: (id, hideAddUser) => dispatch(makeRequest(id, hideAddUser)),
    resetError: () => dispatch({ type: "RESET_CHAT_ERROR" }),
    fetchUnreadCount: () => dispatch(fetchUnreadCount()),
    getUser: () => dispatch(getUser()),
    resetSelectedChannel: () => dispatch(resetSelectedChannel()),
  };
}

function mapStateToProps(state, props) {
  return {
    messages: _get(state, "chat.messages", []),
    friends: _get(state, "chat.friends", []),
    addedUsersList: _get(state, "chat.channelUsers", []),
    contactList: _get(state, "chat.allUsers", []),
    groupList: _get(state, "chat.groupList", []),
    message: _get(state, "chat.message", ""),
    loading: _get(state, "chat.loading", false),
    error: _get(state, "chat.error", ""),
    unreadCount: _get(state, "chat.unreadCount", 0),
    selectedChannel: _get(state, "chat.selectedChannel", {}),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Chat));
