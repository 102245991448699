import { userInfoData, permissionData } from "../LocalStorages";

export const userTypes = {
  SA: "Admin",
  SA_TM: "Team Member",
  SA_MA: "Manager",
  EP: "Event Planer",
  EP_TM: "Team Member",
  EP_MA: "Manager",
  VN: "Vendor",
  VN_TM: "Team Member",
  CL: "Client",
};

export const userTypeConstants = {
  SA: "SA",
  SA_TM: "SA_TM",
  SA_MA: "SA_MA",
  EP: "EP",
  EP_TM: "EP_TM",
  EP_MA: "EP_MA",
  VN: "VN",
  VN_TM: "VN_TM",
  CL: "CL",
};

function componentHideHandle(user) {
  if (user) {
    switch (user.userType) {
      case userTypeConstants["SA"]:
      case 1:
      case userTypeConstants["SA_TM"]:
      case 2:
      case userTypeConstants["SA_MA"]:
        return {
          SA: true,
        };
      case userTypeConstants["EP"]:
      case 3:
      case userTypeConstants["EP_TM"]:
      case 4:
      case userTypeConstants["EP_MA"]:
        return {
          EP: true,
        };
      case userTypeConstants["VN"]:
      case 6:
      case userTypeConstants["VN_TM"]:
        return {
          VN: true,
        };
      case userTypeConstants["CL"]:
      case 5:
        return {
          CL: true,
        };
      default:
        return {};
    }
  }
}

export function componentHide() {
  return componentHideHandle(userInfoData());
}

export function showBtn(show) {
  const btnHide = componentHide(userInfoData());
  if (btnHide) {
    let k = Object.keys(btnHide);
    return show
      ? show
      : k[0] === userTypeConstants["VN"] || k[0] === userTypeConstants["CL"]
      ? 0
      : 1;
  } else {
    return show;
  }
}

function getPermission(data, module) {
  return data ? data[module] : {};
}

export function permissions(module) {
  return module ? getPermission(permissionData(), module) : {};
}

export function userAccess(permission, itemId, userId, type) {
  if (userInfoData()) {
    if (
      userInfoData()["userType"] === userTypeConstants["SA_TM"] ||
      userInfoData()["userType"] === userTypeConstants["SA_MA"] ||
      userInfoData()["userType"] === userTypeConstants["EP_TM"] ||
      userInfoData()["userType"] === userTypeConstants["EP_MA"]
    ) {
      if (
        permission &&
        Object.keys(permission).length !== 0 &&
        permission.constructor === Object
      ) {
        if (permission[type] === "own") {
          if (itemId === userId) {
            return true;
          } else {
            return false;
          }
        } else if (permission[type] === "all") {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
}
