import React, { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import classes from "../../../assets/scss/common.module.scss";

function ExplorePriceTabs({ menus }) {
  const [activeTab, setActiveTab] = useState(products?.[0]?.id || "");
  const handleTabChange = (tabId) => {
	  setActiveTab(tabId);
  };

  const products  = [
   {
    id: 1,
    title: 'Monthly',
   },
   {
    id: 2,
    title: 'Annually',
   },
  ]

  useEffect(() => {
    setActiveTab(products?.[0]?.id);
  }, [menus]);

  return (
    <Tab.Container
      as="div"
      className={classes["explore-tabbing"]}
      id="left-tabs-example"
      activeKey={activeTab}
      defaultActiveKey={activeTab}
      transition
    >
      <Nav as="ul" className={`${classes["et_nav-tabs"]}`} role="tablist">
        {products.map((p) => (
          <Nav.Item
            key={p.id}
            as="li"
            role="button"
            onClick={() => handleTabChange(p.id)}
            className={`${classes["nav-item"]} cursor-pointer`}
          >
            <Nav.Link
              as="a"
              role="button"
              eventKey={p.id}
              className={`${classes["et_nav-link"]} ${
                activeTab === p.id && classes["et_nav-link_active"]
              }`}
            >
              {p.title}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
      <Tab.Content as="div" className={classes["tab-content"]}>
	{menus.map((menu) => ( menu.plan_type == "Monthly") ? (
          <Tab.Pane as="div" className={`fade`} key={menu.id} eventKey={1}>
            {menu.content}
          </Tab.Pane>
	):(
          <Tab.Pane as="div" className={`fade`} key={menu.id} eventKey={2}>
            {menu.content}
          </Tab.Pane>)
	)}
      </Tab.Content>
    </Tab.Container>
  );
}

export default ExplorePriceTabs;
