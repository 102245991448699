import React, { Fragment, useState, useEffect, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

// import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { getHomePlans } from "../../Services/HomeService";

import classes from "../../../../assets/scss/common.module.scss";

// assets
import RightChevron from "../../../../assets/images/icons/right-chevron.svg";
import LeftChevron from "../../../../assets/images/icons/left-chevron.svg";

// SLIDES
import Slide1 from "../../../../assets/images/brands/slide1.png";
import Slide2 from "../../../../assets/images/brands/slide2.png";
import Slide3 from "../../../../assets/images/brands/slide3.png";
import Slide4 from "../../../../assets/images/brands/slide4.png";
import Slide5 from "../../../../assets/images/brands/slide5.png";
// BG
import EventBg from "../../../../assets/images/laptop_harp.webp";
import EventBgSmall from "../../../../assets/images/laptop_harp-50-percent.webp";
import EventBgMed from "../../../../assets/images/laptop_harp-med.webp";
// ACTIVITY
import Activity from "../../../../assets/images/activity.webp";
import ActivitySmall from "../../../../assets/images/activity-small.webp";
import Vendors from "../../../../assets/images/vendors.png";
import Tasks from "../../../../assets/images/task.webp";
import TasksSmall from "../../../../assets/images/task-small.webp";
import Conversation from "../../../../assets/images/conversation.png";
import Templates from "../../../../assets/images/templates.png";
import Checklist from "../../../../assets/images/checklist.png";
import VendorPaymentPlan from "../../../../assets/images/vendor-payment-plan.png";
import Files from "../../../../assets/images/files.png";
import Vendor1 from "../../../../assets/images/vendorc1.png";
import TeamMembers from "../../../../assets/images/teammembers.png";
import Payments from "../../../../assets/images/payments.png";
import Workflow from "../../../../assets/images/workflow.png";
import Budget from "../../../../assets/images/budget.png";
import Stripe from "../../../../assets/images/stripe.png";
import Calendar from "../../../../assets/images/calendar.png";
import StoryImage from "../../../../assets/images/story-img.webp";
import Guest1 from "../../../../assets/images/guest-list-1.png";
import VerticalTabs from "../../../../Shared/Components/Tabs/VerticalTabs";
import ExploreTabs from "../../../../Shared/Components/Tabs/ExploreTabs";
import ExplorePriceTabs from "../../../../Shared/Components/Tabs/ExplorePriceTabs";
import Slider from "react-slick";
import { PopupModal } from "react-calendly";
const Modals = React.lazy(() => import("./Modals"));

const tabMenus = [
  {
    title: "Activities + Tasks",
    id: "activities_task",
    content: (
      <React.Fragment>
        <div
          className={`${classes["tab-pane"]} fade show active`}
          id="tabbing1"
          role="tabpanel"
          aria-labelledby="tabbing1-tab"
        >
          <div className={`row ${classes["row"]}`}>
            <div className="col-md-12 text-center col-lg-6">
              <div className={classes["tab-wrapper-one"]}>
                <h3 className={classes["tab-title"]}>Activities</h3>
                <p className={classes["tab-para"]}>
                  Send Emails, Take Notes, Update Checklists, and View Recent
                  Activities
                </p>
                <div className={classes["tab-img"]}>
                  <img
                    alt="activity"
                    src={Activity}
                    srcSet={`${ActivitySmall} 320w,
                 ${ActivitySmall} 640w,
                 ${Activity} 1024w`}
                    sizes="(max-width: 320px) 280px,
               (max-width: 640px) 600px,
               1000px"
                  />
                </div>
                <button
                  className={`${classes["learn-more"]} ${classes["btn"]} ${classes["btn-primary"]} d-flex`}
                  data-toggle="modal"
                  data-target="#activityModal"
                >
                  Learn more
                </button>
              </div>
            </div>
            <div className="col-md-12 text-center col-lg-6">
              <div className={classes["tab-wrapper-one"]}>
                <h3 className={classes["tab-title"]}>Tasks</h3>
                <p className={classes["tab-para"]}>
                  Quickly and easily get updates on all the tasks related to
                  your project in one simple view
                </p>
                <div className={classes["tab-img"]}>
                  <img
                    alt="Tasks"
                    src={Tasks}
                    srcSet={`${TasksSmall} 320w,
                 ${TasksSmall} 640w,
                 ${Tasks} 1024w`}
                    sizes="(max-width: 320px) 280px,
               (max-width: 640px) 600px,
               1000px"
                  />
                </div>
                <button
                  className={`${classes["learn-more"]} ${classes["btn"]} ${classes["btn-primary"]} d-flex`}
                  data-toggle="modal"
                  data-target="#taskModal"
                >
                  Learn more
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    ),
  },
  {
    title: "Conversation",
    id: "conversation",
    content: (
      <React.Fragment>
        <div
          className={`${classes["tab-pane"]} ${classes["fade"]}`}
          id="tabbing2"
          role="tabpanel"
          aria-labelledby="tabbing2-tab"
        >
          <div className="row">
            <div className="col-md-12 text-center">
              <div className={classes["tab-wrapper-one"]}>
                <h3 className={classes["tab-title"]}>Conversation</h3>
                <p className={classes["tab-para"]}>
                  Track your conversations with your clients, vendors and anyone
                  that you place on the project. Simply email directly from the
                  system or reply outside of Harpsen and the system will include
                  and track all responses in the project for you!
                </p>
                <div className={classes["tab-img"]}>
                  <img loading="lazy" src={Conversation} alt="tab-img" />
                </div>
                <button
                  className={`${classes["learn-more"]} ${classes["btn"]} ${classes["btn-primary"]} d-flex`}
                  data-toggle="modal"
                  data-target="#conversationModal"
                >
                  Learn more
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    ),
  },
  {
    title: "Templates + Checklist",
    id: "templates_checklist",
    content: (
      <React.Fragment>
        <div
          className={`${classes["tab-pane"]} ${classes["fade"]}`}
          id="tabbing3"
          role="tabpanel"
          aria-labelledby="tabbing3-tab"
        >
          <div className="row">
            <div className="col-md-12 text-center col-lg-6">
              <div className={classes["tab-wrapper-one"]}>
                <h3 className={classes["tab-title"]}>Templates</h3>
                <p className={classes["tab-para"]}>
                  Proposals, Contracts, Invoices, Menus, Timelines and more.
                  Create your own or use Harpsen’s samples.
                </p>
                <div className={classes["tab-img"]}>
                  <img loading="lazy" src={Templates} alt="tab-img" />
                </div>
                <button
                  className={`${classes["learn-more"]} ${classes["btn"]} ${classes["btn-primary"]} d-flex`}
                  data-toggle="modal"
                  data-target="#templateModal"
                >
                  Learn more
                </button>
              </div>
            </div>
            <div className="col-md-12 text-center col-lg-6">
              <div className={classes["tab-wrapper-one"]}>
                <h3 className={classes["tab-title"]}>Checklist</h3>
                <p className={classes["tab-para"]}>
                  Checklists help to keep everyone on track leading up to the
                  big day. Assign items, specify dates, and more.
                </p>
                <div className={classes["tab-img"]}>
                  <img loading="lazy" src={Checklist} alt="tab-img" />
                </div>
                <button
                  className={`${classes["learn-more"]} ${classes["btn"]} ${classes["btn-primary"]} d-flex`}
                  data-toggle="modal"
                  data-target="#checklistModal"
                >
                  Learn more
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    ),
  },
  {
    title: "Files + Guest List",
    id: "files_guest_list",
    content: (
      <React.Fragment>
        <div
          className={`${classes["tab-pane"]} ${classes["fade"]}`}
          id="tabbing4"
          role="tabpanel"
          aria-labelledby="tabbing4-tab"
        >
          <div className="row">
            <div className="col-md-12 text-center col-lg-6">
              <div className={classes["tab-wrapper-one"]}>
                <h3 className={classes["tab-title"]}>Files</h3>
                <p className={classes["tab-para"]}>
                  It is important as a business owner to keep everything
                  organized. Create, share, and manage all your project files in
                  one easy spot.
                </p>
                <div className={classes["tab-img"]}>
                  <img loading="lazy" src={Files} alt="tab-img" />
                </div>
                <button
                  className={`${classes["learn-more"]} ${classes["btn"]} ${classes["btn-primary"]} d-flex`}
                  data-toggle="modal"
                  data-target="#fileModal"
                >
                  Learn more
                </button>
              </div>
            </div>
            <div className="col-md-12 text-center col-lg-6">
              <div className={classes["tab-wrapper-one"]}>
                <h3 className={classes["tab-title"]}>Guest List</h3>
                <p className={classes["tab-para"]}>
                  Easily import your guest list into Harpsen to keep track of
                  their information, their dinner, and their seat.
                </p>
                <div className={`${classes["tab-img"]} d-flex`}>
                  <img loading="lazy" src={Guest1} alt="tab-img" />
                </div>
                <button
                  className={`${classes["learn-more"]} ${classes["btn"]} ${classes["btn-primary"]} d-flex`}
                  data-toggle="modal"
                  data-target="#guestListModal"
                >
                  Learn more
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    ),
  },
  {
    title: "Vendors + Vendor Comparison",
    id: "vendors_vendor_comparison",
    content: (
      <React.Fragment>
        <div
          className={`${classes["tab-pane"]} ${classes["fade"]}`}
          id="tabbing5"
          role="tabpanel"
          aria-labelledby="tabbing5-tab"
        >
          <div className="row">
            <div className="col-md-12 text-center col-lg-6">
              <div className={classes["tab-wrapper-one"]}>
                <h3 className={classes["tab-title"]}>Vendors</h3>
                <p className={classes["tab-para"]}>
                  Harpsen’s Vendor Tab is used to keep track of all the vendors
                  that are part of your project.
                </p>
                <div className={classes["tab-img"]}>
                  <img loading="lazy" src={Vendors} alt="tab-img" />
                </div>
                <button
                  className={`${classes["learn-more"]} ${classes["btn"]} ${classes["btn-primary"]} d-flex`}
                  data-toggle="modal"
                  data-target="#vendorModal"
                >
                  Learn more
                </button>
              </div>
            </div>
            <div className="col-md-12 text-center col-lg-6">
              <div className={classes["tab-wrapper-one"]}>
                <h3 className={classes["tab-title"]}>Vendors Comparison</h3>
                <p className={classes["tab-para"]}>
                  Compare and track all the vendor quotes in one easy spot.
                  Identify and confirm the vendor.
                </p>
                <div className={`${classes["tab-img"]} d-flex`}>
                  <img src={Vendor1} loading="lazy" alt="tab-img" />
                  {/*<img src={Vendor2} alt="tab-img" />*/}
                </div>
                <button
                  className={`${classes["learn-more"]} ${classes["btn"]} ${classes["btn-primary"]} d-flex`}
                  data-toggle="modal"
                  data-target="#vendorComparisonModal"
                >
                  Learn more
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    ),
  },
  {
    title: "Team Members",
    id: "team_members",
    content: (
      <React.Fragment>
        <div
          className={`${classes["tab-pane"]} ${classes["fade"]}`}
          id="tabbing6"
          role="tabpanel"
          aria-labelledby="tabbing6-tab"
        >
          <div className="row">
            <div className="col-md-12 text-center">
              <div className={classes["tab-wrapper-one"]}>
                <h3 className={classes["tab-title"]}>Team Members</h3>
                <p className={classes["tab-para"]}>
                  Does your company consist of more than one person? With
                  Harpsen, you can add an unlimited amount of team members to
                  your account and give them the permissions that that you feel
                  is necessary.
                </p>
                <div className={classes["tab-img"]}>
                  <img loading="lazy" src={TeamMembers} alt="tab-img" />
                </div>
                <button
                  className={`${classes["learn-more"]} ${classes["btn"]} ${classes["btn-primary"]} d-flex`}
                  data-toggle="modal"
                  data-target="#teamMemberModal"
                >
                  Learn more
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    ),
  },
  {
    title: "Payments",
    id: "payments",
    content: (
      <React.Fragment>
        <div
          className={`${classes["tab-pane"]} ${classes["fade"]}`}
          id="tabbing7"
          role="tabpanel"
          aria-labelledby="tabbing7-tab"
        >
          <div className="row">
            <div className="col-md-12 text-center">
              <div className={classes["tab-wrapper-one"]}>
                <h3 className={classes["tab-title"]}>Payments</h3>
                <p className={classes["tab-para"]}>
                  It’s important, as a business, to get paid on time. This tab
                  is here to help you see when your next payment is coming in
                  and for your client to see when their next payment is due.
                </p>
                <div className={classes["tab-img"]}>
                  <img loading="lazy" src={Payments} alt="tab-img" />
                </div>
                <button
                  className={`${classes["learn-more"]} ${classes["btn"]} ${classes["btn-primary"]} d-flex`}
                  data-toggle="modal"
                  data-target="#paymentModal"
                >
                  Learn more
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    ),
  },
  {
    title: "Workflow",
    id: "Workflow",
    content: (
      <React.Fragment>
        <div
          className={`${classes["tab-pane"]} ${classes["fade"]}`}
          id="tabbing8"
          role="tabpanel"
          aria-labelledby="tabbing8-tab"
        >
          <div className="row">
            <div className="col-md-12 text-center">
              <div className={classes["tab-wrapper-one"]}>
                <h3 className={classes["tab-title"]}>Workflow</h3>
                <p className={classes["tab-para"]}>
                  Are the tedious tasks bogging you down to get the real work
                  done? You can set up a workflow right from the moment someone
                  inquires through your website. Let the system do the work for
                  you so you can focus on growing your business.
                </p>
                <div className={classes["tab-img"]}>
                  <img loading="lazy" src={Workflow} alt="tab-img" />
                </div>
                <button
                  className={`${classes["learn-more"]} ${classes["btn"]} ${classes["btn-primary"]} d-flex`}
                  data-toggle="modal"
                  data-target="#workflowModal"
                >
                  Learn more
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    ),
  },
  {
    title: "Budget + Vendor Payment Plan",
    id: "budget_vendor_payment_plan",
    content: (
      <React.Fragment>
        <div
          className={`${classes["tab-pane"]} ${classes["fade"]}`}
          id="tabbing9"
          role="tabpanel"
          aria-labelledby="tabbing9-tab"
        >
          <div className="row">
            <div className="col-md-12 text-center col-lg-6">
              <div className={classes["tab-wrapper-one"]}>
                <h3 className={classes["tab-title"]}>Budget</h3>
                <p className={classes["tab-para"]}>
                  Are you tracking a budget for your client? Let Harpsen do the
                  numbers for you and keep you within scope.
                </p>
                <div className={classes["tab-img"]}>
                  <img loading="lazy" src={Budget} alt="tab-img" />
                </div>
                <button
                  className={`${classes["learn-more"]} ${classes["btn"]} ${classes["btn-primary"]} d-flex`}
                  data-toggle="modal"
                  data-target="#budgetModal"
                >
                  Learn more
                </button>
              </div>
            </div>
            <div className="col-md-12 text-center col-lg-6">
              <div className={classes["tab-wrapper-one"]}>
                <h3 className={classes["tab-title"]}>Vendors Payment Plan</h3>
                <p className={classes["tab-para"]}>
                  Are you helping your client to keep track of all of their
                  vendor payments? Let Harpsen do that.
                </p>
                <div className={classes["tab-img"]}>
                  <img loading="lazy" src={VendorPaymentPlan} alt="tab-img" />
                </div>
                <button
                  className={`${classes["learn-more"]} ${classes["btn"]} ${classes["btn-primary"]} d-flex`}
                  data-toggle="modal"
                  data-target="#vendorPaymentPlanModal"
                >
                  Learn more
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    ),
  },
];

const PrevArrow = (props) => {
  return (
    <button
      type="button"
      onClick={props.onClick}
      className={`a-left control-c prev ${classes["slick-prev"]} ${classes["slick-arrow"]}`}
    >
      <img loading="lazy" src={LeftChevron} alt="LeftChevron" />
    </button>
  );
};
const NextArrow = (props) => {
  return (
    <button
      type="button"
      onClick={props.onClick}
      className={`a-left control-c next ${classes["slick-next"]} ${classes["slick-arrow"]}`}
    >
      <img loading="lazy" src={RightChevron} alt="RightChevron" />
    </button>
  );
};
function Home() {
  let [planData, setPlanData] = useState([]);
  const [isRequestDemoVisible, setUsRequestDemoVisible] = useState(false);
  const inputField = useRef(null);
  const featuresRef = useRef(null);
  const pricingRef = useRef(null);
  const testimonialRef = useRef(null);
  const aboutusRef = useRef(null);
  const exploreHarpsenBigVideoRef = useRef(null);
  const portalsRef = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const [modalOpen, setModalOpen] = useState(false);

  const toggleRequestDemoVisible = () =>
    setUsRequestDemoVisible((prev) => !prev);

  let getPlanData = () => {
    getHomePlans()
      .then((res) => {
        if (res.data.success) {
          if (res.data.data.totalRecords) {
            setPlanData(res.data.data.records);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getPlanData();
  }, []);

  const planCards = planData.map(
    ({
      id,
      title,
      selling_price,
      currency,
      short_description,
      features,
      plan_type,
    }) => ({
      title: title,
      id: id,
      plan_type: plan_type,
      content: (
        <React.Fragment>
          <div
            //className={`${classes["tab-pane"]} ${classes["fade"]}`}
            className={`${classes["tab-pane"]} ${classes["fade"]} mb-4`}
            id={id}
            role="tabpanel"
            aria-labelledby="YearlyPortal-tab"
          >
            <div
              className={`${classes["pricing-card-wrapper"]} position-relative`}
            >
	      <h1> {title} </h1>
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-6">
                  <h3>
                    {currency}
                    {selling_price} <span>{plan_type}</span>
                  </h3>
                  <div
                    dangerouslySetInnerHTML={{ __html: short_description }}
                  ></div>
                  <Link
                    to="/signup"
                    className={`${classes["btn"]} ${classes["btn-primary"]} px-4`}
                  >
                    START CREATING
                  </Link>
                </div>
                <div
                  className="col-lg-6 col-md-6"
                  dangerouslySetInnerHTML={{ __html: features }}
                ></div>
              </div>
            </div>
          </div>
        </React.Fragment>
      ),
    })
  );

  const trialHandler = () => {
    let pathNames = {
      pathname: "/signup",
      state: { email: inputField.current.value },
    };
    history.push(pathNames);
  };

  // scroll to section
  useEffect(() => {
    if (location.state?.section === "feature") {
      featuresRef.current.scrollIntoView();
    }
    if (location.state?.section === "price") {
      pricingRef.current.scrollIntoView();
    }
    if (location.state?.section === "testimonial") {
      testimonialRef.current.scrollIntoView();
    }
    if (location.state?.section === "aboutUs") {
      aboutusRef.current.scrollIntoView();
    }
    if (location.state?.section === "portal") {
      portalsRef.current.scrollIntoView();
    }
    if (location.state?.section === "explore-harpsen") {
      exploreHarpsenBigVideoRef.current.scrollIntoView();
    }
  }, [location.state]);

  return (
    <Fragment>
      <main className={classes["h_bg_wrapper"]}>
        {/* <!-- Hero section :: START  --> */}
        <section className={classes["hero-wrapper"]}>
          <div className={`container ${classes["container"]}`}>
            <div className="row">
              <div className="col-md-12 text-center">
                <h1 className={classes["hero-main-title"]}>
                  Grow Your Business + Plan Your Event
                </h1>
                <h2 className={classes["hero-sub-title"]}>
                  Incredibly Easy. Unbelievably Affordable.
                </h2>
                <p className={classes["hero-para"]}>
                  Created by Event Professionals
                </p>
                <p
                  className={`${classes["hero-para"]} ${classes["hero-smaller-para"]}`}
                >
                  All you need to grow and manage your business in one place.
                </p>
              </div>
              <div className="col-md-12">
                <div className={classes["hero-btn-wrapper"]}>
                  <Link
                    to="/signup"
                    className={`${classes["btn"]} ${classes["btn-primary"]}`}
                  >
                    start free trial
                  </Link>
                  <button
                    onClick={toggleRequestDemoVisible}
                    className={`${classes["btn"]} ${classes["btn-secondary"]}`}
                  >
                    Schedule a demo
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Hero section :: END  --> */}

        {/* <!-- Event Professionals section :: START  --> */}
        <section className={classes["event-wrapper"]}>
          <div
            className={`container ${classes["container"]} ${classes["slider"]}`}
          >
            <div className={classes["event-slider-wrapper"]}>
              <h3 className={classes["title"]}>
                Event Professionals who choose Harpsen
              </h3>
              <Slider
                slidesToShow={4}
                autoplaySpeed={2000}
                infinite
                slidesToScroll
                autoplay
                arrows={false}
                className={classes["event-slider"]}
                responsive={[
                  {
                    breakpoint: 768,
                    settings: {
                      slidesToShow: 3,
                    },
                  },
                  {
                    breakpoint: 480,
                    settings: {
                      arrows: false,
                      centerMode: true,
                      centerPadding: "40px",
                      slidesToShow: 2,
                    },
                  },
                  {
                    breakpoint: 390,
                    settings: {
                      slidesToShow: 1,
                    },
                  },
                ]}
              >
                <div className="d-flex justify-content-center">
                  <img loading="lazy" src={Slide1} alt="" />
                </div>
                <div className="d-flex justify-content-center">
                  <img loading="lazy" src={Slide2} alt="" />
                </div>
                <div className="d-flex justify-content-center">
                  <img loading="lazy" src={Slide3} alt="" />
                </div>
                <div className="d-flex justify-content-center">
                  <img loading="lazy" src={Slide4} alt="" />
                </div>
                <div className="d-flex justify-content-center">
                  <img loading="lazy" src={Slide5} alt="" />
                </div>
              </Slider>
            </div>
          </div>

          <div className={`${classes["laptop-image"]}`}>
            <div className={`container ${classes["container"]}`}>
              <img
                alt="event-bg"
                src={EventBg}
                srcSet={`${EventBgSmall} 320w,
                 ${EventBgSmall} 640w,
                 ${EventBgMed} 1024w`}
                sizes="(max-width: 320px) 280px,
               (max-width: 640px) 600px,
               1000px"
              />
            </div>
          </div>
        </section>
        {/* <!-- Event Professionals section :: END  --> */}

        {/* <!-- tabbing section :: START  --> */}
        <section className={classes["tab-wrapper"]} ref={featuresRef}>
          <div className={`container ${classes["container"]}`}>
            <VerticalTabs menus={tabMenus} />
          </div>
        </section>
        {/* <!-- tabbing section :: END  --> */}

        {/* <!-- explore section :: START  --> */}
        <section ref={portalsRef} className={classes["heading-wrapper"]}>
          <div className={`container ${classes["container"]}`}>
            <h2 className={classes["title"]}>Explore Our Portals</h2>
          </div>
        </section>

        <section className={classes["explore-wrapper"]}>
          <div className={`container ${classes["container"]}`}>
            <div class="row justify-content-center">
              <div className="col-md-10">
                <ExploreTabs
                  menus={[
                    {
                      title: "client Portal",
                      id: "client_portal",
                      content: (
                        <>
                          <div
                            className={`tab-pane ${classes["fade"]} fade show`}
                            id="clientPortal"
                            role="tabpanel"
                            aria-labelledby="clientPortal-tab"
                          >
                            <div
                              className={`${classes["video-wrapper"]} position-relative`}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  paddingBottom: "calc(51.15625% + 44px)",
                                }}
                              >
                                <iframe
                                  title="Harpsen demo"
                                  src="https://www.youtube.com/embed/JEyMQOpdTV8"
                                  frameborder="0"
                                  webkitallowfullscreen="true"
                                  mozallowfullscreen="true"
                                  allowFullScreen
                                  style={{
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                ></iframe>
                              </div>
                            </div>
                          </div>
                        </>
                      ),
                    },
                    {
                      title: "vendor Portal",
                      id: "vendor_portal",
                      content: (
                        <>
                          <div
                            className={`tab-pane fade show`}
                            id="vendorPortal"
                            role="tabpanel"
                            aria-labelledby="vendorPortal-tab"
                          >
                            <div
                              className={`${classes["video-wrapper"]} position-relative`}
                            >
                              <div
                                style={{
                                  position: "relative",
                                  paddingBottom: "calc(51.15625% + 44px)",
                                }}
                              >
                                <iframe
                                  title="Harpsen Vendor demo"
                                  src="https://www.youtube.com/embed/JEyMQOpdTV8"
                                  frameborder="0"
                                  webkitallowfullscreen="true"
                                  mozallowfullscreen="true"
                                  allowFullScreen
                                  style={{
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                ></iframe>
                              </div>
                            </div>
                          </div>
                        </>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </section>
        {/* <!-- explore section :: END  --> */}

        {/* <!-- grid section :: START  --> */}
        <section className={classes["grid-wrapper"]}>
          <div className={`container ${classes["container"]}`}>
            <div className="row justify-content-center">
              <div className={`col-lg-5 ${classes["col-lg-5"]} text-center`}>
                <div className={classes["grid-card-wrapper"]}>
                  <div
                    className={`${classes["grid-img-wrapper"]} d-flex align-items-center justify-content-center`}
                  >
                    <img loading="lazy" src={Stripe} alt="stripe" />
                  </div>
                  <h4>Get paid on time through Stripe.</h4>
                  <p>
                    We partner with Stripe to ensure any payments that are made
                    by your clients are easy to make, fast to process, and
                    highly secure.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 text-center">
                <div className={classes["grid-card-wrapper"]}>
                  <div
                    className={`${classes["grid-img-wrapper"]} d-flex align-items-center justify-content-center`}
                  >
                    <img loading="lazy" src={Calendar} alt="calendar" />
                  </div>
                  <h4>Easily sync your Google calendar.</h4>
                  <p>
                    Quickly and easily sync your Google calendar with Harpsen to
                    track projects and tasks against other appointments in your
                    calendar.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- grid section :: END  --> */}

        {/* <!-- explore portal 2 section :: START --> */}
        <section
          ref={exploreHarpsenBigVideoRef}
          className={`${classes["heading-wrapper"]}`}
        >
          <div className={`container ${classes["container"]}`}>
            <h2 className={classes["title"]}>Explore Harpsen</h2>
          </div>
          <section
            className={`${classes["explore-wrapper-2"]} ${classes["explore-harpsen"]}`}
          >
            <div className={`${classes["video-wrapper"]} position-relative`}>
              <div className={`container ${classes["container"]}`}>
                <div
                  style={{
                    position: "relative",
                    paddingBottom: "calc(53.9296875% + 44px)",
                    height: "0",
                  }}
                >
                  <iframe
                    title="Harpsen demo"
                    src="https://www.youtube.com/embed/hJxWgQLJYSs"
                    frameborder="0"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      width: "100%",
                      height: "100%",
                    }}
                  ></iframe>
                </div>
              </div>
            </div>
          </section>
        </section>

        {/* <!-- explore portal 2 section :: END --> */}

        {/* <!-- Our Story section :: START --> */}
        <section ref={aboutusRef} className={classes["our-story-wrapper"]}>
          <div className={`container ${classes["container"]}`}>
            <div className="row justify-content-between align-items-center">
              <div className="col-lg-6">
                <h3 className={classes["title"]}>Our Story</h3>
                <p>
                  Danielle and Ryan, a wedding planner and software engineer,
                  husband and wife, mom and dad to Harper and Emerson, and
                  entrepreneurs.
                </p>
                <p>
                  Danielle was in need of a platform that did it all for her
                  wedding planning business. She needed something that checked
                  all the boxes, but existing CRMs were so expensive and they
                  knew they could build a better platform at an affordable cost
                  - one place where she could serve her clients from beginning
                  to end and not ask them to create several logins to different
                  platforms in order to work with her.
                </p>
                <p>
                  One day, as many other days, Danielle and Ryan were chatting,
                  sitting in their kitchen at home trying to figure out better
                  ways to manage all their clients and businesses. Ryan, who is
                  a software engineer, but also manages their catering and bar
                  business, needed something to help streamline his business
                  too.
                </p>
                <p>Danielle asked, Ryan agreed, and Harpsen was born.</p>
                <p>
                  As small business owners and creative thinkers themselves,
                  they got right to work on a solution - putting their event and
                  coding experience together.  They envisioned an affordable,
                  high quality, platform that would work across many different
                  types of small businesses.  This platform needed to be a user
                  friendly space that wasnt too complicated, but had all the
                  tools needed to streamline and run a small business.  And of
                  course, when coming up with a name, they didn’t think twice
                  about including their two daughters, Harper and Emerson.
                </p>
                <p>
                  Many pencil on paper sketches of how the platform would look,
                  happened in that same kitchen, as well as many tireless nights
                  working with their team of developers. For years, 'date night'
                  was staying up late, after putting the kids to bed, to keep
                  working towards their goal.
                </p>
                <p>
                  As a husband and wife and family of four, Harpsen has become
                  much more than just the first conversation in their kitchen.
                  Danielle and Ryan value each other, their daughters, their
                  team, and their consumers. Creating a community that feels
                  like home and that is what they have done in Harpsen.
                </p>
              </div>
              <div className="col-lg-5">
                <div className={classes["story-img-wrapper"]}>
                  <img loading="lazy" src={StoryImage} alt="story-img" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Our Story section :: END --> */}

        {/* <!-- people Says section :: START --> */}
        <section
          ref={testimonialRef}
          id="testimonial"
          className={`${classes["people-says-wrapper"]} d-flex`}
        >
          <div className={classes["people-content"]}>
            <h3 className={classes["title"]}>
              What are people <br className={classes["remove-br"]} /> saying
              about Harpsen?
            </h3>
          </div>
          <div className={`${classes["people-slider"]} pss`}>
            <Slider
              slidesToShow={2}
              slidesToScroll={1}
              infinite
              autoplaySpeed={1000}
              centerMode
              arrows
              className={`${classes["people-say-slider"]}`}
              prevArrow={<PrevArrow />}
              nextArrow={<NextArrow />}
              responsive={[
                {
                  breakpoint: 1370,
                  settings: {
                    slidesToShow: 2.1,
                    centerPadding: "30px",
                  },
                },
                {
                  breakpoint: 1205,
                  settings: {
                    slidesToShow: 1.5,
                    centerPadding: "20px",
                  },
                },
                {
                  breakpoint: 1030,
                  settings: {
                    slidesToShow: 2,
                    centerMode: false,
                  },
                },
                {
                  breakpoint: 780,
                  settings: {
                    slidesToShow: 1,
                    centerMode: false,
                  },
                },
              ]}
            >
              <div>
                <div
                  title="I wasnt expecting to make the switch to Harpsen from my old
                    CRM but I just knew things werent working with them and I
                    needed a change. Harpsen reached out to me just in time…and
                    I havent looked back! The features and ease of use that
                    Harpsen provides makes competitors look ancient. Not to
                    mention the customer service, which truly is unmatched! When
                    I first signed up for the trial, I was given an extended
                    trial to really dive in and get to know the software. Before
                    the extended trial ended, I was already sold and signed up
                    for a year! As if Harpsen wasn’t already great, they are
                    constantly improving and adding new features to the program.
                    I look forward to seeing what Harpsen has in store next!!"
                  className={classes["people-card-wrapper"]}
                >
                  <div className={classes["logo-wrapper"]}>
                    <img loading="lazy" src={Slide1} alt="slide" />
                  </div>
                  <p>
                    "I wasnt expecting to make the switch to Harpsen from my
                    old CRM but I just knew things werent working with them and
                    I needed a change. Harpsen reached out to me just in
                    time…and I havent looked back! The features and ease of use
                    that Harpsen provides makes competitors look ancient. Not to
                    mention the customer service, which truly is unmatched! When
                    I first signed up for the trial, I was given an extended
                    trial to really dive in and get to know the software. Before
                    the extended trial ended, I was already sold and signed up
                    for a year! As if Harpsen wasn’t already great, they are
                    constantly improving and adding new features to the program.
                    I look forward to seeing what Harpsen has in store next!!""
                  </p>
                  <div
                    className={`${classes["people-details"]} d-flex align-items-center`}
                  >
                    <div className={classes["people-content"]}>
                      <h6>Teyanna Bailey</h6>
                      <span>
                        Planner/Designer, Essence of Flair Weddings and Events
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div
                  title="We chose Harpsen first for the features they offered and we
                    are so glad we did! Having templates, workflows, and task
                    lists all in the same place that can easily be assigned to
                    team members and clients is so helpful for accountability
                    and organization. As our company is growing quickly, we
                    needed a CRM that met the needs for all of the packages we
                    offer. A key point for Lauren & Co was client usability, yet
                    another area that Harpsen exceeds expectations. Being able
                    to see where our clients are in the process and what they
                    are working on is helpful for us to provide the best
                    experience for them. The ability to customize the platform
                    to meet our exact needs for all divisions of Lauren & Co in
                    one place was another important aspect when choosing
                    Harpsen.
                    The customer service has been nothing short of
                    outstanding. From the extremely informative demonstration we
                    received to the timely implementation of features we
                    requested, Harpsen has exceeded our expectations. Being able
                    to easily reach the team allows us to quickly resolve
                    questions as we fully switch to Harpsen. Their team is well
                    versed in the event and wedding industry, allowing them to
                    understand what both planners and clients need. We have been
                    extremely impressed with their communication and openness to
                    feedback. We are excited to be part of Harpsen as they
                    continue to grow!"
                  className={classes["people-card-wrapper"]}
                >
                  <div className={classes["logo-wrapper"]}>
                    <img loading="lazy" src={Slide2} alt="slide" />
                  </div>
                  <p>
                    " We chose Harpsen first for the features they offered and
                    we are so glad we did! Having templates, workflows, and task
                    lists all in the same place that can easily be assigned to
                    team members and clients is so helpful for accountability
                    and organization. As our company is growing quickly, we
                    needed a CRM that met the needs for all of the packages we
                    offer. A key point for Lauren & Co was client usability, yet
                    another area that Harpsen exceeds expectations. Being able
                    to see where our clients are in the process and what they
                    are working on is helpful for us to provide the best
                    experience for them. The ability to customize the platform
                    to meet our exact needs for all divisions of Lauren & Co in
                    one place was another important aspect when choosing
                    Harpsen.
                    <br />
                    <br />
                    The customer service has been nothing short of outstanding.
                    From the extremely informative demonstration we received to
                    the timely implementation of features we requested, Harpsen
                    has exceeded our expectations. Being able to easily reach
                    the team allows us to quickly resolve questions as we fully
                    switch to Harpsen. Their team is well versed in the event
                    and wedding industry, allowing them to understand what both
                    planners and clients need. We have been extremely impressed
                    with their communication and openness to feedback. We are
                    excited to be part of Harpsen as they continue to grow!"
                  </p>
                  <div
                    className={`${classes["people-details"]} d-flex align-items-center`}
                  >
                    <div className={classes["people-content"]}>
                      <h6>Nicoletta Nadolski</h6>
                      <span>
                        Operations | Lead Coordinator, Lauren & Co Events
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </section>
        {/* <!-- people Says section :: END --> */}

        {/* <!-- pricing section :: START --> */}
        <section
          id="pricing"
          ref={pricingRef}
          className={classes["pricing-wrapper"]}
        >
          <div className={`container ${classes["container"]}`}>
	  {/*<ExploreTabs menus={planCards} />*/}
	  <ExplorePriceTabs menus={planCards} />
          </div>
        </section>
        {/* <!-- pricing section :: END --> */}
      </main>
      <Modals />
      <PopupModal
        onModalClose={toggleRequestDemoVisible}
        open={isRequestDemoVisible}
        rootElement={document.getElementById("root")}
        color="#197cb5"
        text="Request a Demo"
        textColor="#ffffff"
        url="https://calendly.com/harpsen-1768/30min"
      />
    </Fragment>
  );
}

export default Home;
