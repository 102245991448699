/**
 * STATE VARIABLES FOR ADD CLIENT DRAWER
 */
export const OPEN_ADD_CLIENT_DRAWER =  "OPEN_ADD_CLIENT_DRAWER"
export const CLOSE_ADD_CLIENT_DRAWER =  "CLOSE_ADD_CLIENT_DRAWER"

export const SELECT_EXISTING_TAB =  "SELECT_EXISTING_TAB"
export const SELECT_NEW_TAB =  "SELECT_NEW_TAB"
export const SET_ADD_CLIENT_TAB =  "SET_ADD_CLIENT_TAB"


/**
 CLIENT DETAILS:

 When drawer is opened via clicked on
 some user's profile disc
 **/ 
export const CLIENT_DATA_REQUEST =  "CLIENT_DATA_REQUEST"
export const CLIENT_DATA_SUCCESS =  "CLIENT_DATA_SUCCESS"
export const CLIENT_DATA_FAILURE =  "CLIENT_DATA_FAILURE"
