//workflow
export const workflow_stages = {
  "contract-sent": "after contract sent",
  "contract-signed": "after contract signed",
  "proposal-sent": "after proposal sent",
  "proposal-signed": "after proposal signed",
  "questionnaire-submitted": "after questionnaire submitted",
  completed: "After Project moved to completed",
  "after-activate-workflow": "after workflow activate",
  before_project_date: "before project date",
};

export const workflow_stages_arrays = [
  { id: "contract-sent", name: "after contract sent" },
  { id: "contract-signed", name: "after contract signed" },
  { id: "proposal-sent", name: "after proposal sent" },
  {
    id: "proposal-signed",
    name: "after proposal signed",
  },
  { id: "questionnaire-submitted", name: "after questionnaire submitted" },
  {
    id: "completed",
    name: "after project moved to completed",
  },
  { id: "after-activate-workflow", name: "after workflow activate" },
  { id: "before_project_date", name: "before project date" },
];

export const workflow_category = {
  create_task: { name: "Create Task", icon: "bx bx-check-square" },
  send_questionnaire: {
    name: "Send Questionnaire",
    icon: "bx bx-question-mark",
  },
  send_email: { name: "Send Email", icon: "bx bx-envelope" },
  send_brochure: { name: "Send Brochure", icon: "bx bx-news" },
  send_package: { name: "Send Package", icon: "bx bx-package" },
};

export const workflow_category_arrays = [
  { id: "create_task", name: "Create Task" },
  { id: "send_questionnaire", name: "Send Questionnaire" },
  { id: "send_email", name: "Send Email" },
  { id: "send_brochure", name: "Send Brochure" },
  { id: "send_package", name: "Send Package" },
];

export const approval_status = {
  send_automatically: "Send automatically",
  approval_before_send: "Approval before sending",
};

export const approval_status_array = [
  { id: "send_automatically", name: "Send automatically" },
  { id: "approval_before_send", name: "Approval before sending" },
];

export const time_variants = {
  months: "month(s)",
  weeks: "week(s)",
  days: "day(s)",
  hours: "hour(s)",
  minutes: "minute(s)",
};

export const time_variants_array = [
  { id: "minutes", name: "Minutes" },
  { id: "hours", name: "Hours" },
  { id: "days", name: "Days" },
  { id: "weeks", name: "Weeks" },
];
//workflow

//payment schedule
export const payment_due_date = {
  // "proposal-sent": "after proposal sent",
  // "proposal-signed": "after proposal signed",
  // "questionnaire-submitted": "after questionnaire submitted",
  // completed: "after project moved to completed",
  "custom-date": "Custom Date",
  "smart-date": "Smart Date",
  "custom_date_range": "Custom Date Range",
  "smart_date_range": "Smart Date Range",
};

export const schedule_lists = [
  // {
  //   title: "payment is due when proposal sent",
  //   id: "proposal-sent",
  //   name: "after proposal sent",
  // },
  {
    title: "select a fixed due date on calendar",
    id: "custom-date",
    name: "Custom Date",
  },
  {
    title: "select a offset due date",
    id: "smart-date",
    name: "Smart Date",
  },
  // {
  //   title: "payment is due when proposal signed",
  //   id: "proposal-signed",
  //   name: "after proposal signed",
  // },
  // {
  //   title: "payment is due when questionnaire submitted",
  //   id: "questionnaire-submitted",
  //   name: "after questionnaire submitted",
  // },
  // {
  //   title: "payment is due after project completed",
  //   id: "completed",
  //   name: "after project moved to completed",
  // },
];

export const SMART_DATE_TYPES = [
  {
    title: "select a fixed due date on calendar",
    id: "custom-date",
    name: "Custom Date",
  },
  {
    title: "select a offset due date",
    id: "smart-date",
    name: "Smart Date",
  },
  {
    title: "select a date range",
    id: "custom_date_range",
    name: "Custom Date Range",
  },
  {
    title: "select a smart date range",
    id: "smart_date_range",
    name: "Smart Date Range",
  }
];

export const when_payment_string = {
  after_project_creation_date: "after project creation date",
  "before-start-date": "before project date",
};

export const when_payment = [
  { id: "after_project_creation_date", name: "after project creation date" },
  { id: "before-start-date", name: "before project date" },
];

export const when_dropdown = [
  { id: "proposal-sent", name: "after proposal sent" },
  // { id: "custom-date", name: "custom date" },
  { id: "proposal-signed", name: "after proposal signed" },
  { id: "questionnaire-submitted", name: "after questionnaire submitted" },
  { id: "completed", name: "after project moved to completed" },
];

export const status_list_array = [
  { id: "proposal-sent", name: "proposal sent" },
  // { id: "custom-date", name: "custom date" },
  { id: "proposal-signed", name: "after proposal signed" },
  { id: "questionnaire-submitted", name: "after questionnaire submitted" },
  { id: "completed", name: "after project moved to completed" },
];

export const times_list_array = [
  { id: "days", name: "day(s)" },
  { id: "weeks", name: "week(s)" },
  { id: "months", name: "month(s)" },
];

//payment schedule

export const project_stage = {
  inquiry: "Inquiry",
  follow_up: "Follow-up",
  proposal_sent: "Proposal Sent",
  proposal_signed: "Proposal Signed",
  planning: "Planning",
  completed: "Completed",
  archived: "Archived",
};

export const INITIALS_PLACEHOLDER = `&nbsp;<span style="width:6rem;margin-right:0.5rem;color:#AAA;">USER INITIALS`

export const input_types_with_label = ["radio", "checkbox", "select", "text", "number", "textarea", "date"]
export const input_types_with_options = ["radio", "checkbox", "select"]
export const input_types_with_placeholder = ["text", "number", "textarea"]
export const input_types_with_freetext = ["description"]
export const input_types_with_image = ["image"]

export const signature_fonts = [
  {"label": "Homemade Apple", value: "'Homemade Apple', cursive"},
  {"label": "Pacifico", value: "'Homemade Apple', cursive"}
]

export const EVENT_MANAGER_USER_TYPES = ["EP", "EP_TM", "EP_MA"]
export const pattern = {
  email:
    /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/,
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*]).{8,}$/,
  number: /^[0-9]*$/,
  name: /^[a-zA-Z][a-zA-Z. ]+$/,
  // eslint-disable-next-line
  url: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
  // eslint-disable-next-line
  facebook: /^(http(s)?:\/\/)?(www\.)?facebook.com\/[a-zA-Z0-9(\.\?)?]/,
  // eslint-disable-next-line
  twitter: new RegExp("^(?:http(s)://)?(?:www\\.)?twitter\\.com/(\\w+)$", "i"),
  // eslint-disable-next-line
  linkedin: new RegExp(
    "^(?:http(s)://)?(?:www\\.)?linkedin\\.com/(\\w+)$",
    "i"
  ),
  // eslint-disable-next-line
  instagram: new RegExp(
    "^(?:http(s)://)?(?:www\\.)?instagram\\.com/(\\w+)$",
    "i"
  ),
  // eslint-disable-next-line
  pinterest: new RegExp(
    "^(?:http(s)://)?(?:www\\.)?pinterest\\.com/(\\w+)$",
    "i"
  ),
};

export const ADD_CLIENT_TABS = {
  CHOOSE_EXISTING: "CHOOSE_EXISTING",
  ADD_NEW: "ADD_NEW",
};

export const TEMPLATE_TYPE_WITH_EMAIL_PREVIEW = ["contract", "proposal"];
