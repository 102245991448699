import React, { useEffect, useRef, useState } from "react";
import moment from "moment";

import classes from "./Chat.module.css";

import Loader from "../../../../Shared/Components/Loader";
import defaultImage from "../../../../Shared/Components/ImgUtils";
import _isEmpty from "lodash/isEmpty";
import _cloneDeep from "lodash/cloneDeep";
import _groupBy from "lodash/groupBy";
import _orderBy from "lodash/orderBy";
import _get from "lodash/get";

import AddContactList from "./AddContactList";
import ContactList from "./ContactList";
import SideBarBottom from "./SideBarBottom";
import MessageWindow from "./MessageWindow";
import { connect } from "react-redux";

import {
  leaveFromGroup,
  changeChatStatus,
  setTyping,
} from "../../../../store/chat/chat-actions";
import UserStatus from "./UserStatus";
import {
  sendMessage,
  getChatSettings,
  saveChatSettings
} from "../../Service/chat/service";

const ChatUI = (props) => {
  const {
    showLoader,
    activeChannel,
    messages,
    contacts,
    activeUser,
    showAddUsers,
    contactList,
    searchQuery,
    searchContact,
    groupList,
    user
  } = props;
  let filteredAddContactList = [];
  let filterContacts = [];
  let filterGroupList = [];

  const messagesEndRef = useRef();

  // scroll to bottom
  const scrollToBottom = () => {
    if (!_isEmpty(activeChannel))
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => scrollToBottom(), [messages]);

  const [settings, setSettings] = useState({ show: false, data: {} });
  const [loading, setLoading] = useState(false);
  const [enteredText, setEnteredText] = useState('');

  // Set text
  const setText = (txt) => {
    setEnteredText(txt);
    props.setTyping(activeChannel.channelType, activeChannel.id, activeUser);
  }

  // send message
  const sendMessageHandle = (e) => {
    e.preventDefault();
    onSendMessage();
  };

  // Send message
  const onSendMessage = () => {
    if (enteredText?.trim() !== "" && activeChannel.id) {
      let data = {
        message: enteredText,
        channel_id: activeChannel.id ? activeChannel.id : "",
        channel_type: activeChannel.channelType
          ? activeChannel.channelType
          : "",
      };

      setLoading(true);
      sendMessage(data)
        .then((res) => {
          setLoading(false);
          if (res.data.success) {
            setEnteredText('');
          } else {
            props.setError(res.data.error);
          }
        })
    }
  };

  const onKeyUPFunc = (e) => {
    if (
      (e.key === "Enter" || e.keyCode === 13) &&
      enteredText?.trim() !== "" &&
      activeChannel.id
    ) {
      onSendMessage();
    }
  };

  // Get chat settings 
  const getSettings = () => {
    setLoading(true);
    getChatSettings().then((res) => {
      setLoading(false)
      if (res.data.success) {
        setSettings({
          show: true,
          data: res.data.data
        })
      }
    });
  }

  // Save chat settings 
  const saveSettings = (data) => {
    setLoading(true);
    saveChatSettings(data).then((res) => {
      setLoading(false)
      if (res.data.success) {
        setSettings({
          show: false,
          data: res.data.data
        })
      }
    });
  }

  if (showAddUsers) {
    filteredAddContactList = contactList.filter(
      (item) =>
        item.first_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.last_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.email?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  } else {

    filterContacts = contacts.filter(
      (item) =>
        item.users[0].first_name?.toLowerCase().includes(searchContact.toLowerCase()) ||
        item.users[0].last_name?.toLowerCase().includes(searchContact.toLowerCase()) ||
        item.users[0].email?.toLowerCase().includes(searchContact.toLowerCase()) ||
        item.users[0].name?.toLowerCase().includes(searchContact.toLowerCase())
    );
    filterGroupList = groupList.filter((item) =>
      item.name?.toLowerCase().includes(searchContact.toLowerCase())
    );
  }

  let filteredMessages = [];
  messages.map(m => {
    let createdDate = moment(m.created_at).format("LLLL");
    let message = _cloneDeep(m);
    message['created_at'] = createdDate;
    filteredMessages.push(message);
    return message;
  });
  let groupedMessages = _groupBy(filteredMessages, 'created_at');
  let contactsArr = filterContacts.concat(filterGroupList);

  let nomessageContacts = contactsArr.filter(contact => contact.messageDate === null && contact.message === null);

  // Sort contacts based on recent messages
  let allContacts = _orderBy((contactsArr.filter(contact => contact.messageDate !== null && contact.message !== null) || []), "messageDate", "desc").concat(nomessageContacts);

  let displaySearchBox = showAddUsers ?
    (filteredAddContactList.length > 0 || searchQuery) ? true : false
    : (allContacts.length > 0 || searchContact) ? true : false;

  return (
    <>
      <div className={`card mt-0 ${classes.card}`}>
        <button
          type="button"
          className={`${classes.chat_close_btn} ${props.showChatPopup ? "" : "d-none"
            }`}
          onClick={props.toggleChatHandler}
        >
          <i className="bx bx-x"></i>
        </button>
        <div className="card-body bd-0">
          <div id={classes.frame}>
            <div id={classes.sidepanel} className={`${_isEmpty(activeChannel) ? classes.single_side_panel : ""}`} >
              <div id={classes.profile} className='position-relative'>
                <div className={classes.wrap}>
                  <UserStatus
                    user={user}
                    changeChatStatus={props.changeChatStatus}
                  />
                  <img
                    id={classes["profile-img"]}
                    src={activeUser["image"]}
                    alt={activeUser.name}
                    title={activeUser.name}
                    onError={(e) => {
                      e.persist();
                      defaultImage(e, "user");
                    }}
                  />
                  <p className="mb-0">{activeUser.name}</p>
                </div>
              </div>
              {(displaySearchBox === true) && (<div id={classes.search}>
                <label htmlFor="">
                  <i className="fa fa-search" aria-hidden="true"></i>
                </label>
                <input
                  type="text"
                  placeholder="Search contacts..."
                  value={showAddUsers ? searchQuery : searchContact}
                  onChange={(e) => props.setSearchQuery(e.target.value, showAddUsers ? 'user' : 'contact')}
                />
              </div>)}
              <div id={classes.contacts}>
                {showAddUsers ?
                  <AddContactList
                    contacts={filteredAddContactList}
                    onAddUser={props.onAddUser}
                    searchQuery={searchQuery}
                    loading={showLoader}
                  />
                  : <ContactList
                    contacts={allContacts}
                    onContactClick={props.onContactClick}
                    searchQuery={searchQuery}
                  />}
              </div>
              <SideBarBottom
                showAddUsers={showAddUsers}
                hideAddUser={props.hideAddUser}
                handleAddContact={props.handleAddContact}
                openModal={props.openModal}
                setShowSettings={(val) => {
                  let settingsObj = _cloneDeep(settings);
                  settingsObj.show = val
                  setSettings(settingsObj)
                }}
                getSettings={() => getSettings()}
                settingsData={settings}
                saveSettings={(data) => saveSettings(data)}
              />
            </div>
            {!_isEmpty(activeChannel) && (
              <MessageWindow
                activeChannel={activeChannel}
                typings={props.typings}
                handleAddUserToGroup={props.handleAddUserToGroup}
                handleGetUsers={props.handleGetUsers}
                messagesEndRef={messagesEndRef}
                enteredText={enteredText}
                setEnteredText={(text) => setText(text)}
                onKeyUPFunc={(e) => onKeyUPFunc(e)}
                sendMessageHandle={(e) => sendMessageHandle(e)}
                groupedMessages={groupedMessages}
                channelUsers={props.channelUsers}
                resetActiveChannel={props.resetActiveChannel}
                {...props}
              />
            )}
          </div>
        </div>
      </div>
      <Loader showLoader={showLoader || loading} />
    </ >
  );
}

function mapDispatchToProps(dispatch) {
  return {
    leaveGroup: (id) => dispatch(leaveFromGroup(id)),
    changeChatStatus: (status) => dispatch(changeChatStatus(status)),
    setTyping: (channelId, name, user) => dispatch(setTyping(channelId, name, user)),
    setError: (error) => dispatch({ type: "SET_CHAT_ERROR", error }),
  };
}

function mapStateToProps(state, props) {
  return {
    typings: _get(state, "chat.typings", []),
    channelUsers: _get(state, "chat.selectedChannel.users", []),
    user: _get(state, "chat.user", {}),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatUI);
