import React, { forwardRef, useImperativeHandle } from "react";

const ConfirmPopup = forwardRef(({ popupClass, isRemove, type, message = "", yesButtonText, noButtonText }, ref) => {
  useImperativeHandle(ref, () => ({
    closeModal: removePopup,
    openModal: openPopup,
  }));

  const removePopup = () => {
    document.getElementById(`${popupClass}`).classList.remove("is-visible");
  };

  const openPopup = () => {
    document.getElementById(`${popupClass}`).classList.add("is-visible");
  };

  const messages = {
    "delete-records": "Are you sure you want to delete the selected record(s)?",
    "select-records": "Please select at least one item",
    "restore-records": "Are you sure you want to restore this template?",
    "archive-project": "Are you sure you wish to archive the project?",
    "auto-pay-Off": "By unchecking this, it will remove autopay from all the payments related to this template, you will manually have to come in and pay each one on the due date. Are you sure?",
    "auto-pay-on": "By checking this, it will autopay all the payments related to this template. Are you sure?",
    "manual-payment": "Auto Pay is enabled, Do you still want to make manual payment?",
    "auto-pay-confirm": "As autopay is checked, the rest of the payment RELATED to this template will be automatically deducted from the card you are entering on the due date that is assigned, Are you sure?"
  };

  return (
    <div className="cd-popup" role="alert" id={popupClass}>
      <div className="cd-popup-container">
        <p>{message ? message : messages[type || "delete-records"]}</p>
        <ul className="cd-buttons">
          <li
            onClick={() => {
              isRemove("1");
              removePopup();
            }}
          >
            <span>{yesButtonText || 'Yes'}</span>
          </li>
          <li
            onClick={() => {
              removePopup();
            }}
          >
            <span className="close_popup">{noButtonText || 'No'}</span>
          </li>
        </ul>
        <span
          className="cd-popup-close close_popup img-replace"
          onClick={() => {
            removePopup();
          }}
        >
          Close
        </span>
      </div>
    </div>
  );
});

export default ConfirmPopup;
