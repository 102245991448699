import React, { useState } from 'react';
import { Modal, Button } from "react-bootstrap";
import classes from "./Chat.module.css";
import { FormInput } from "../../../../Shared/Components/Form-Element/FormInput";

const Settings = ({ show = false, onSubmit = () => { }, onHide = () => { }, data }) => {

  const [sendEmail, setSendEmail] = useState(data['SEND_EMAIL_FOR_MESSAGE']);
  const [messageAbility, setMessageAbility] = useState(data['AUTOMATIC_MESSAGE_ABILITY']);
  const [message, setMessage] = useState((data['MESSAGE_TEXT'] || ""));
  const [error, setError] = useState('')

  const save = () => {
    if (messageAbility && !message) {
      setError("Please enter message")
    } else {
      let dataToSend = {
        SEND_EMAIL_FOR_MESSAGE: sendEmail,
        AUTOMATIC_MESSAGE_ABILITY: messageAbility,
        MESSAGE_TEXT: message
      }
      onSubmit(dataToSend);
    }
  }

  return (
    <Modal
      show={show}
      onHide={() => onHide()}
      centered
      className={classes.chat_settings_modal}
      backdrop="static"
    >
      <Modal.Header className='fz-18'>
        Chat Message Settings
      </Modal.Header>

      <Modal.Body>
        <div className="login_checkbox">
          <label className="fz-16">
            <input
              onChange={() => {
                setSendEmail(!sendEmail)
              }}
              type="checkbox"
              name="SEND_EMAIL_FOR_MESSAGE"
              checked={sendEmail}
            />
            <span className="cr">
              <i className="cr-icon fa fa-check"></i>
            </span>{" "}
            Send Email for new messages when you are offline
          </label>
        </div>
        <div className="login_checkbox pt-2">
          <label className="fz-16">
            <input
              onChange={() => {
                setMessageAbility(!messageAbility)
              }}
              type="checkbox"
              name="AUTOMATIC_MESSAGE_ABILITY"
              checked={messageAbility}
              value={messageAbility}
            />
            <span className="cr">
              <i className="cr-icon fa fa-check"></i>
            </span>{" "}
            Auto Reply
          </label>
        </div>
        {messageAbility && (
          <div className="col-lg-12 pt-2">
            <div className="form-group">
              <FormInput
                input
                name="message"
                errClass={`${error ? "error" : ""}`}
                onChange={(e) => {
                  setMessage(e.target.value)
                  if (error) setError("")
                }}
                values={message}
                onBlur={() => { }}
                placeholder="Enter Reply Message to send automatically when you are offline"
              />
              {error && (<div className="invalid-feedback">{error}</div>)}
            </div>
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={() => save()}>Save</Button>
        <Button variant="secondary" onClick={() => onHide()}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Settings