import React, { Component } from 'react';
import Routing from './Routing';
import { withRouter } from 'react-router-dom';
import Message from '../../Shared/Components/Message';

class AuthLayout extends Component {
    constructor(props) {
        super(props);
        this.state = { token: "", };
    }

    componentDidMount() {
        this.setState({ token: this.props.match.params.token, });
    }

    render(props) {
        return (
            <React.Fragment>
                <Message />
                <Routing token={this.state.token} />
            </React.Fragment>
        );
    }
}

export default withRouter(AuthLayout);