import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import updateReducer from "./default-reducer";
import ProjectReducer from "./project/reducer";
import ChatReducer from "./chat/chat-reducer";
import AuthReducer from "./auth/reducer";
import AddClientDrawer from "./client/reducer";
import googleCalendar from "./calendar/reducer";

const rootReducer = combineReducers({
  updateData: updateReducer,
  project: ProjectReducer,
  chat: ChatReducer,
  auth: AuthReducer,
  client: AddClientDrawer,
  calendar: googleCalendar
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const middleware = [thunk];
const initialState = {};

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(
      applyMiddleware(...middleware),
    ),
);

export default store;
