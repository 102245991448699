import React from 'react';
import classes from "./Chat.module.css";
import defaultImage from "../../../../Shared/Components/ImgUtils";
import moment from 'moment';

const ContactList = ({ contacts, onContactClick, searchQuery }) => {
  return (
    (contacts.length > 0) ?
      (<ul>
        {contacts.map((user, idx) => {
          if (user.type === 'dm') {
            return (
              <li
                className={`${classes.contact} ${classes[user["current"]]
                  }`}
                key={"user" + user["id"]}
                onClick={() => {
                  onContactClick(user["id"], "user");
                }}
              >
                <div className={`media ${classes.wrap}`}>
                  <div className="align-self-start mr-1">
                    <span
                      className={`${classes["contact-status"]} 
                      ${classes[user.users[0].status]
                        }`}
                      title={user.users[0].status}
                    ></span>
                    {user.users[0].avatar ? (
                      <img
                        src={
                          user.users[0].avatar
                        }
                        alt={user.name}
                        onError={(e) => {
                          e.persist();
                          defaultImage(e, "user");
                        }}
                      />
                    ) : (
                      <p className={classes.image_icons}>
                        {user?.name?.charAt(0).toUpperCase()}
                      </p>
                    )}
                  </div>
                  <div className={`media-body ${classes.meta}`}>
                    <p className={`mb-0 ${classes.name}`}>
                      {user.users[0].first_name}{" "}
                      {user.users[0].last_name}
                    </p>
                    <p className={`mb-0 mt-0 ${classes.preview} ${classes.msgPreview}`}>
                      {user["message"]}
                    </p>
                    {
                      user.unread_message_count > 0 ?
                        <span className={`chatbadge ${classes.unreadMessage}`}>{user.unread_message_count}</span> : null
                    }
                  </div>
                  {user.messageDate && (
                    <div className={`align-self-end ${classes.fz_11}`}>
                      {moment(user.messageDate).fromNow()}
                    </div>
                  )}
                </div>
              </li>
            )
          } else {
            return (
              <li
                className={`${classes.contact} ${classes[user["current"]]
                  }`}
                key={"group" + idx}
                onClick={() => {
                  onContactClick(user["id"], "group");
                }}
              >
                <div className={`media ${classes.wrap}`}>
                  <div className="align-self-start mr-1">
                    <p className={classes.image_icons}>
                      {user.name?.charAt(0).toUpperCase()}
                    </p>
                  </div>
                  <div className={`media-body ${classes.meta}`}>
                    <p className={`mb-0 ${classes.name}`}>
                      {user["name"]}
                    </p>
                    <p className={`mb-0 mt-0 ${classes.preview} ${classes.msgPreview}`}>
                      {user["message"]}
                    </p>
                    {
                      user.unread_message_count > 0 ?
                        <span className={`chatbadge ${classes.unreadMessage}`}>{user.unread_message_count}</span> : null
                    }
                  </div>
                  {user.messageDate && (
                    <div className={`align-self-end ${classes.fz_11}`}>
                      {moment(user.messageDate).fromNow()}
                    </div>
                  )}
                </div>
              </li>
            );
          }
        }
        )}
      </ul>) : (<div className={classes.chat_no_contact_div}>{searchQuery ? "No Contacts found" : "No contacts yet!"}</div>)
  );
}

export default ContactList;