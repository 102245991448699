import axios from "../../config/api";
import environment from '../../Models/env';
import { echoLeaveChannel } from "../../Modules/admin/Pages/chat/echoHelpers";

function logout() {
    let url =   `${environment.apiBaseUrl}/logout`;
    return axios.post(url, {}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    }).then(res => { echoLeaveChannel(); return res; }).catch(err => { throw err; });
}

function getRoles() {
  let url = `${environment.apiBaseUrl}/roles`;
  return axios.get(url,{headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}});
}

export { logout, getRoles };