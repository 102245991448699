import React, { Component } from "react";
import store from "../../../../store/store";
import classes from "./Chat.module.css";

import { createChannel, getChannelAddContacts } from "../../Service/chat/service";
import {
  changeHandler,
  blurHandler,
  submitFormError,
} from "../../../../Shared/Services/formService";
import CenterModal from "../../../../Shared/Components/ModalPopupAndCard/CenterModal";
import {
  FormInput,
  FormLabel,
} from "../../../../Shared/Components/Form-Element/FormInput";
import { userInfoData } from "../../../../Shared/Components/LocalStorages";
import Loader from "../../../../Shared/Components/Loader";

import environment from "../../../../Models/env";
import userImage from "../../../../assets/img/user2.png";
import defaultImage from "../../../../Shared/Components/ImgUtils";
import {
  inviteUserToGroup,
} from "../../Service/chat/service";
import ErrorToast from "../../../../Shared/Components/ErrorToast";

class CreateGroupModal extends Component {
  constructor(props) {
    super(props);
    this.childRef = React.createRef();

    this.state = {
      formData: this.setForm(),
      showLoader: false,
      id: this.props.id,
      userInfo: userInfoData(),
      modalType: this.props.modalType,
      error: "",
      contactList: [],
    };
  }

  componentDidMount() { }

  componentDidUpdate(props, state) {
    if (props.modalType !== this.props.modalType) {
      this.setState({ modalType: this.props.modalType });
    }
    
  }

  /**
   * Get contact list to be added into group
   */
  getAddContactList() {
    const { activeChannel } = this.props;
    this.setState({ showLoader: true });

    getChannelAddContacts(activeChannel.id)
      .then((res) => {
        if (res.data.success) {
          this.setState({ showLoader: false, contactList: res.data.data });
        } else {
          this.setState({ showLoader: false });
        }
      })
      .catch((error) => {
        this.setState({ showLoader: false, error: error.message });
      });
  }

  setForm() {
    let formFields = {
      channel_name: {
        name: "Group Name",
        value: "",
        touched: false,
        error: "",
        type: "name",
        validation: [{ required: true }],
      },
      description: {
        name: "Description",
        value: "",
        touched: false,
        error: "",
        type: "name",
        validation: [{ required: true }],
      },
    };
    return formFields;
  }

  onChange(e, name) {
    let formData = changeHandler(e, name, this.state.formData);
    this.setState({ formData: formData });
  }

  handleBlur(name) {
    let formData = blurHandler(name, this.state.formData);
    this.setState({ formData: formData });
  }

  onSubmitHandler(e, type) {
    e.preventDefault();
    let isFormValid = submitFormError(this.state.formData);
    if (isFormValid.validated) {
      this.setState({ showLoader: true });
      let form_data = this.state.formData;
      let dataToSend = {
        // is_active: "1",
      };
      let keys = Object.keys(form_data);
      for (let i = 0; i < keys.length; i++) {
        dataToSend[keys[i]] = form_data[keys[i]].value;
      }

      this.createGroup(dataToSend);
    } else {
      this.setState({ formData: isFormValid.form });
    }
  }

  resetFormHandler() {
    let form = this.setForm();
    this.setState({ formData: form });
  }

  openModals() {
    if (this.props.modalType === "user") {
      this.getAddContactList();
    }
    this.childRef.current.openModal();
  }

  closeModals() {
    this.childRef.current.closeModal();
  }

  createGroup(dataToSend) {
    createChannel(dataToSend)
      .then((res) => {
        if (res.data.success) {
          this.props.setsm(res.data.message, "success");
          this.resetFormHandler();
          this.closeModals();
          this.props.updateGroup();
          const channel = res.data;
          store.dispatch({ type: "CREATE_CHANNEL_SUCCESS", payload: channel });
        }
        this.setState({ showLoader: false });
      })
      .catch((error) => {
        store.dispatch({ type: "SET_CHAT_ERROR", error: error.message });
        this.setState({ showLoader: false });
        this.closeModals();
      });
  }

  // Send invitation to group
  addUserToGroupHandler = (receiver) => {
    const { activeChannel } = this.props;
    let data = { channel_id: activeChannel.id, receiver };
    this.setState({ showLoader: true });

    inviteUserToGroup(data)
      .then((res) => {
        this.setState({ showLoader: false });
        this.childRef.current.closeModal();
        this.getAddContactList();
        if (!res.data.success) {
          this.setState({ error: res.data.error });
        }
      })
      .catch((err) => this.setState({ showLoader: false, error: err.message }));
  };


  render() {
    const { modalType, error, contactList } = this.state;
    const { addedUsers } = this.props;

    return (
      <React.Fragment>
        <CenterModal
          modalClass="createModal"
          title={
            modalType === "group"
              ? "Create Group"
              : modalType === "group-details"
                ? "Group Users"
                : "Add User"
          }
          isUpdate=""
          onSubmit={(e) => {
            this.onSubmitHandler(e);
          }}
          btnHide={
            modalType === "group"
              ? ""
              : modalType === "group-details"
                ? "yes"
                : "yes"
          }
          ref={this.childRef}
        >
          {modalType === "group" ? (
            <form>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <FormLabel
                      textLeft
                      className=""
                      name="Group Name"
                      required
                    />
                    <FormInput
                      input
                      errMsg={this.state.formData.channel_name}
                      required
                      errClass={`${this.state.formData.channel_name.error &&
                        this.state.formData.channel_name.touched
                        ? "error"
                        : ""
                        }`}
                      name="channel_name"
                      onChange={(e) => {
                        this.onChange(e, "channel_name");
                      }}
                      onBlur={() => {
                        this.handleBlur("channel_name");
                      }}
                      values={this.state.formData.channel_name.value}
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="form-group">
                    <FormLabel
                      textLeft
                      className=""
                      name="Description"
                      required
                    />
                    <FormInput
                      textarea
                      errMsg={this.state.formData.description}
                      required
                      icon="bx bx-user"
                      errClass={`${this.state.formData.description.error &&
                        this.state.formData.description.touched
                        ? "error"
                        : ""
                        }`}
                      name="description"
                      onChange={(e) => {
                        this.onChange(e, "description");
                      }}
                      onBlur={() => {
                        this.handleBlur("description");
                      }}
                      values={this.state.formData.description.value}
                    />
                  </div>
                </div>
              </div>
            </form>
          ) : (
            ""
          )}
          {modalType === "user" ? (
            <ul className={`list-group ${classes.groupList} ${contactList.length > 0 ? classes.boxBorder : ""}`} type="none">
              {contactList.length > 0 ?
                contactList.map((user) => (
                  <li
                    className={classes.contact}
                    key={user["id"]}
                  >

                    <div className={`media ${classes.wrap}`}>
                      <div className="align-self-start mr-1">
                        {user.users[0].avatar ? (
                          <img
                            src={
                              user.users[0].avatar
                                ? environment.imageBaseUrl + user.users[0].avatar
                                : userImage
                            }
                            alt={
                              user?.users[0].first_name +
                              " " +
                              user?.users[0].last_name
                            }
                            onError={(e) => {
                              e.persist();
                              defaultImage(e, "user");
                            }}
                            style={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "50%",
                            }}
                          />
                        ) : (
                          <p className={classes.image_icons}>
                            {user?.users[0].first_name?.charAt(0).toUpperCase()}
                          </p>
                        )}
                      </div>
                      <div className={`media-body ${classes.meta}`}>
                        <p className={`mb-0 ${classes.name}`}>
                          {user.users[0].first_name} {user.users[0].last_name}
                        </p>
                        <p className={`mb-0 mt-0 ${classes.preview}`}>
                          {user?.users[0].email}
                        </p>
                        <button
                          type="button"
                          className={`btn btn-dark py-1 ${user?.users[0].invite_sent ? 'cursor-not-allowed' : ''}`}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.addUserToGroupHandler(user.users[0].id);
                          }}
                          disabled={user?.users[0].invite_sent}
                        >
                          {user?.users[0].invite_sent ? "Invitation Sent" : "Invite"}
                        </button>
                      </div>
                    </div>
                  </li>
                ))
                :
                <li className="text-center">You have no contacts to add!</li>
              }
            </ul>
          ) : (
            ""
          )}
          {modalType === "group-details" ? (
            <React.Fragment>
              <p className="text-center">
                <strong>Channel Name: </strong>
                {addedUsers[0]?.name}
              </p>
              <p className="text-center">
                <strong>Users </strong>
              </p>
              <ul className={`list-group ${classes.groupList} ${classes.boxBorder}`} type="none">
                {addedUsers[0]?.users.map((user) => (
                  <li
                    className={classes.contact}
                    key={'group-details' + user["id"]}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >

                    <div className={`media ${classes.wrap}`}>
                      <div className="align-self-start mr-1">
                        {user.avatar ? (
                          <img
                            src={
                              user.avatar
                                ? environment.imageBaseUrl + user.avatar
                                : userImage
                            }
                            alt={user?.first_name + " " + user?.last_name}
                            onError={(e) => {
                              e.persist();
                              defaultImage(e, "user");
                            }}
                            style={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "50%",
                            }}
                          />
                        ) : (
                          <p className={classes.image_icons}>
                            {user?.first_name?.charAt(0).toUpperCase()}
                          </p>
                        )}
                      </div>
                      <div className={`media-body ${classes.meta}`}>
                        <p className={`mb-0 ${classes.name}`}>
                          {user.first_name} {user.last_name}
                        </p>
                        <p className={`mb-0 mt-0 ${classes.preview}`}>
                          {user?.email}
                        </p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </React.Fragment>
          ) : (
            ""
          )}
        </CenterModal>
        <Loader showLoader={this.state.showLoader} />
        {error && <ErrorToast error={error} />}
      </React.Fragment>
    );
  }
}

export default CreateGroupModal;
