import axios from "../../../config/api";
// import { response_interceptor } from '../../../Shared/Services/errorService';

// response_interceptor();

function checkLogin(dataToSend) {
  let url = `/login`;
  // let header  =   axios.setHeader('Content-Type', 'application/json');
  return axios.post(url, dataToSend);
}

function forgetPassword(dataToSend) {
  let url = `/forgot-password`;
  return axios.post(url, dataToSend);
}

function resetPassword(dataToSend) {
  let url = `/resetpassword`;
  return axios.post(url, dataToSend);
}
function userresetPassword(dataToSend) {
  let url = `/resetpasswordsave`;
  return axios.post(url, dataToSend);
}

function signup(dataToSend) {
  let url = `/register`;
  return axios.post(url, dataToSend);
}

function validateOTP(dataToSend) {
  let url = `/register/save`;
  return axios.post(url, dataToSend);
}

function checkPromoCode(dataToSend) {
  let url = `/checkPromoCode`;
  return axios.post(url, dataToSend);
}

function setPassword(dataToSend) {
  let url = `/setpassword`;
  return axios.post(url, dataToSend);
}

function setUserPassword(dataToSend) {
  let url = `/setpasswordsave`;
  return axios.post(url, dataToSend);
}

function verifyOTP(dataToSend) {
  let url = `/verifyotp`;
  return axios.post(url, dataToSend, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } });
}

function resendOTP() {
  let url = `/resendotp`;
  return axios.post(url, {}, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } });
}

function switchUser(data) {
  let url = `/switch-account`;
  return axios.post(url, data, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } });
}

export { checkLogin, forgetPassword, resetPassword, signup, validateOTP, setPassword, userresetPassword, setUserPassword, switchUser, verifyOTP, resendOTP, checkPromoCode };
