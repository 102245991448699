import axios from "axios";
import store from "../store/store";
import environment from "../Models/env";
import { logout } from "../store/auth/action";
import { toast } from "react-hot-toast";
import { getAccessTokenFromRefreshToken } from "../Shared/Services/calendar-auth";
const publicRoutesURL = [
  "/app/project/templates/proposal/view",
  "/app/project/templates/contract/view",
  "/app/project/templates/menu/view",
  "/app/project/templates/invoice/view",
  "/app/project/templates/package/view",
  "/app/project/templates/brochure/view",
  "/app/questionnaire",
  "/app/project/templates/timeline/view",
  "/app/project/payment/",
  "/login",
];

const axiosInstance = axios.create({
    baseURL: environment.apiBaseUrl
});

axiosInstance.interceptors.response.use((res) => res,
    (error) => {
        if (error?.response?.status === 401 || error?.response?.status === 403) {
            const pathName = window.location.pathname;
            const isPublicRoute = publicRoutesURL.find(url => pathName.startsWith(url))
            if(!isPublicRoute){
                store.dispatch(logout());
            }
        }
        // toast.error("Something went wrong, Please try again.")
        return Promise.reject(error);
    }
);

export const gCalendarApi = axios.create({
 baseURL: "https://www.googleapis.com",   
 headers: { Authorization: `Bearer ${localStorage.getItem("GOOGLE_ACCESS_TOKEN")}` }
})

gCalendarApi.interceptors.request.use((request => {
    request.headers.Authorization = `Bearer ${localStorage.getItem("GOOGLE_ACCESS_TOKEN")}`;
    return request
}));

gCalendarApi.interceptors.response.use((res) => res,
    async (error) => {
        const originalRequest = error.config;
        if ((error?.response?.status === 401 || error?.response?.status === 403) && !originalRequest._retry) {
            originalRequest._retry = true;
            console.log("GOOGLE ACCESS TOKEN EXPIRED, GETTING NEW TOKEN FROM REFRESH TOKEN")
            const {access_token: newAccessToken, expires_in } = await getAccessTokenFromRefreshToken()
            localStorage.setItem("GOOGLE_ACCESS_TOKEN", newAccessToken);
            if(expires_in) {
              localStorage.setItem("GOOGLE_ACCESS_TOKEN_EXPIRES_IN", expires_in)
            }
            originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
            return axios(originalRequest);
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
