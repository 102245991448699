import React from "react";
// import environment from "../../../Models/env";

import Errormsg from "../Errormsg";
import defaultImage from "../ImgUtils";

export const FormInput = ({
  input,
  readOnly,
  inputGroup,
  name,
  errClass,
  required,
  onChange,
  onBlur,
  values,
  type,
  errMsg,
  icon,
  placeholder,
  onChange2,
  maxLength,
  isReadOnly,
  ref,
  noClass,
  inputAppendGroup = false,
  inputAppendGroupText = "",
  ...props
}) => {
  return (
    <React.Fragment>
      {input ? (
        <React.Fragment>
          <input
            type={type ? type : "text"}
            className={`form-control ${
              required && !noClass && "requiredInput"
            } ${errClass}`}
            name={name}
            onChange={(e) => {
              onChange(e);
              onChange2 && onChange2(e);
            }}
            onBlur={(e) => {
              onBlur(e);
            }}
            onFocus={(e) => {
              onChange(e);
            }}
            /*onWheel={(e) => {
              e.currentTarget.blur();
            }}*/
            value={values}
            autoComplete={
              type === "email" || type === "password" ? "true" : "false"
            }
            readOnly={isReadOnly ? isReadOnly : ""}
            ref={ref ? ref : null}
            {...props}
            placeholder={placeholder && placeholder}
          />
          {required && <Errormsg errormsg={errMsg} />}
        </React.Fragment>
      ) : inputGroup ? (
        <div className="input-group">
          {(!inputAppendGroup) && (<div className="input-group-prepend">
            <span className="input-group-text">
              <i className={icon}></i>
            </span>
          </div>)}
          <input
            type={type ? type : "text"}
            className={`form-control in-append ${
              required && !noClass && "requiredInput"
            } ${errClass}`}
            name={name}
            onChange={(e) => {
              onChange(e);
            }}
            onBlur={(e) => {
              onBlur(e);
            }}
            onFocus={(e) => {
              onChange(e);
            }}
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            value={values}
            autoComplete={
              type === "email" || type === "password" ? "true" : "false"
            }
            readOnly={isReadOnly ? isReadOnly : ""}
            placeholder={placeholder && placeholder}
          />
          {(inputAppendGroup) && (
            <div className="input-group-append">
              <span className="input-group-text"><b>{inputAppendGroupText}</b></span>
            </div>
          )}
          {required && <Errormsg errormsg={errMsg} />}
        </div>
      ) : (
        <React.Fragment>
          <textarea
            className={`form-control ${
              required && !noClass && "requiredInput"
            } ${errClass}`}
            name={name}
            onChange={(e) => {
              onChange(e);
              onChange2 && onChange2(e);
            }}
            onBlur={(e) => {
              onBlur(e);
            }}
            onFocus={(e) => {
              onChange(e);
            }}
            value={values}
            maxLength={maxLength && maxLength}
            readOnly={readOnly ? readOnly : ""}
            placeholder={placeholder && placeholder}
          />
          {required && <Errormsg errormsg={errMsg} />}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export const FormLabel = ({ name, className, required, textLeft }) => {
  return (
    <label
      className={`${className} col-form-label ${textLeft ? "" : "text-right"}`}
    >
      {required && <span className="text-danger mr-1">*</span>}
      {name}
    </label>
  );
};

export const UploadInput2 = ({
  values,
  inputId,
  name,
  errMsg,
  required,
  accept,
  onClick,
}) => (
  <React.Fragment>
    <div className="box">
      <input
        type="file"
        id={inputId}
        className="inputfile inputfile-3"
        accept={accept ? accept : "image/*"}
        name={name}
        readOnly
        disabled
        onChange={(e) => {}}
      />
      <label
        htmlFor={inputId}
        onClick={(e) => {
          onClick(e);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="17"
          viewBox="0 0 20 17"
        >
          <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
        </svg>
        <span>{values || "Choose a file..."}</span>
      </label>
    </div>
    {required && <Errormsg errormsg={errMsg} />}
  </React.Fragment>
);

export const UploadInput = ({
  onChange,
  values,
  inputId,
  name,
  errMsg,
  required,
  accept,
}) => (
  <React.Fragment>
    <div className="box">
      <input
        type="file"
        id={inputId}
        className="inputfile inputfile-3"
        accept={accept ? accept : "image/*"}
        name={name}
        onChange={(e) => {
          onChange(e);
        }}
      />
      <label htmlFor={inputId}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="17"
          viewBox="0 0 20 17"
        >
          <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
        </svg>
        <span>{values || "Choose a file..."}</span>
      </label>
    </div>
    {required && <Errormsg errormsg={errMsg} />}
  </React.Fragment>
);

export const RadioButton = ({
  name,
  errMsg,
  required,
  onChange,
  radio1,
  radio3,
  option1,
  option2,
  option3,
  isReadOnly,
}) => {
  return (
    <React.Fragment>
      <div
        className="bulgy-radios"
        role="radiogroup"
        aria-labelledby="bulgy-radios-label"
      >
        {radio3 ? (
          <React.Fragment>
            <label>
              <input
                type="radio"
                name={name}
                value={option1.value}
                checked={option1.checked}
                readOnly={isReadOnly ? isReadOnly : ""}
                onChange={(e) => {
                  onChange(e);
                }}
              />
              <span className="radio"></span>
              <span className="label">{option1.name}</span>
            </label>
            <label>
              <input
                type="radio"
                name={name}
                value={option2.value}
                checked={option2.checked}
                readOnly={isReadOnly ? isReadOnly : ""}
                onChange={(e) => {
                  onChange(e);
                }}
              />
              <span className="radio"></span>
              <span className="label">{option2.name}</span>
            </label>
            <label>
              <input
                type="radio"
                name={name}
                value={option3.value}
                checked={option3.checked}
                readOnly={isReadOnly ? isReadOnly : ""}
                onChange={(e) => {
                  onChange(e);
                }}
              />
              <span className="radio"></span>
              <span className="label">{option3.name}</span>
            </label>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <label>
              <input
                type="radio"
                name={name}
                value={option1.value}
                checked={option1.checked}
                readOnly={isReadOnly ? isReadOnly : ""}
                onChange={(e) => {
                  onChange(e);
                }}
              />
              <span className="radio"></span>
              <span className="label">{option1.name}</span>
            </label>
            {radio1 !== true && (
              <label>
                <input
                  type="radio"
                  name={name}
                  value={option2.value}
                  checked={option2.checked}
                  readOnly={isReadOnly ? isReadOnly : ""}
                  onChange={(e) => {
                    onChange(e);
                  }}
                />
                <span className="radio"></span>
                <span className="label">{option2.name}</span>
              </label>
            )}
          </React.Fragment>
        )}
      </div>
      {required && <Errormsg errormsg={errMsg} />}
    </React.Fragment>
  );
};

export const SelectBox = ({
  items,
  name,
  errMsg,
  required,
  errClass,
  onChange,
  onChange2,
  onBlur,
  values,
  optionValues,
  optionName,
  defV,
  defVn,
  defHide,
  isReadOnly,
  id
}) => {
  return (
    <React.Fragment>
      <select
        className={`form-control cs_select ${
          required && "requiredInput"
        } ${errClass}`}
        name={name}
        id={id}
        onChange={(e) => {
          onChange(e);
          onChange2 && onChange2(e);
        }}
        onBlur={(e) => {
          onBlur(e);
        }}
        onFocus={(e) => {
          onChange(e);
        }}
        readOnly={isReadOnly ? isReadOnly : ""}
        disabled={isReadOnly ? "disabled" : ""}
        value={values}
      >
        {!defHide && (
          <option defaultValue value={defV ? defV : ""}>
            {defVn ? defVn : "Choose"}
          </option>
        )}
        {items &&
          items.map((item, index) => (
            <option
              value={optionValues ? item[optionValues] : item.id}
              key={index}
            >
              {optionName ? item[optionName] : item.name}
            </option>
          ))}
      </select>
      {required && <Errormsg errormsg={errMsg} />}
    </React.Fragment>
  );
};

export const UserProfile = ({
  imgSrc,
  accept,
  name,
  email,
  onChange,
  imageId,
}) => {
  //open file function
  const openFile = () => {
    document.getElementById({ imageId }).click();
  };

  return (
    <div className="d-flex">
      <div className="profileImges mr-3">
        <div className="circle">
          <img
            className="profile-pic img-fluid"
            id="selectedImage"
            src={imgSrc}
            alt="user"
            onError={(e) => {
              e.persist();
              defaultImage(e, "user");
            }}
          />
        </div>
        <div className="p-image">
          <i
            className="bx bx-camera upload-button uploadBtnIcon"
            onClick={() => {
              openFile();
            }}
          ></i>
          <input
            className="file-upload userProfileUpload"
            id={imageId}
            name={imageId}
            type="file"
            accept={accept ? accept : "image/*"}
            onChange={(e) => {
              onChange(e);
            }}
          />
        </div>
      </div>
      <div className="">
        <h5 className="font-size-h5 mb-0">{name}</h5>
        <p className="mb-0">{email}</p>
      </div>
    </div>
  );
};
