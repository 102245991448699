import Moment from "moment";

localStorage.setItem(
  "date_format",
  JSON.stringify({
    date_format: "MM/DD/YYYY",
    dp_date_format: "MM/dd/yyyy",
  })
);

export const getFormate = (f_name) => {
  let lSDate = JSON.parse(localStorage.getItem("date_format")),
    df = f_name === "picker" ? lSDate.dp_date_format : lSDate.date_format;
  return df;
};

function dateModify(date) {
  let updateDate = Moment(date).format(getFormate());
  return !date ? "" : updateDate;
}

export default dateModify;