const environment = {
    apiPath: process.env.REACT_APP_API_PATH,
    mailBaseUrl: process.env.REACT_APP_MAIL_BASE_URL,
    apiBaseUrl: process.env.REACT_APP_BACKEND_URL,
    imageBaseUrl: process.env.REACT_APP_IMAGE_BASE_URL,
    frontEndUrl: process.env.REACT_APP_FRONTEND_URL,

    GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
    CALENDAR_ID: process.env.REACT_APP_CALENDAR_ID,
    CLIENT_SECRET: process.env.REACT_APP_CLIENT_SECRET,
    CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
    SCOPES: process.env.REACT_APP_SCOPES,
    TASK_SCOPES: process.env.REACT_APP_TASK_SCOPES,
    DISCOVERY_DOCS: JSON.parse(process.env.REACT_APP_DISCOVERY_DOCS),

    PUSHER_KEY: process.env.REACT_APP_PUSHER_KEY,
    PUSHER_CLUSTER: process.env.REACT_APP_PUSHER_CLUSTER
};
export default environment;
