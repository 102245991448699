const initial_state = { stages: {} };

function reducer(state = initial_state, action) {
  switch (action.type) {
    case "fetch_stages_success":
      return {
        ...state,
        stages: action.payload,
      };
    case "fetch_stages_error":
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
}

export default reducer;
