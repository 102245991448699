import React, { Component } from "react";
import _isEqual from "lodash/isEqual";

class ErrorToast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: (props.error || "")
    };
  }

  componentDidMount() {
    this.setError();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_isEqual(prevProps.error, this.props.error) && this.props.error) {
      this.setError();
    }
  }

  setError() {
    this.setState({ error: this.props.error });
    setTimeout(() => {
      this.setState({ error: "" });
    }, 5000);
  }

  render() {
    const { error } = this.state;
    return (
      <div className={`alert alert-dismissible fade position-absolute alert-danger ${error ? "show" : "hide"}`} role="alert" style={{ zIndex: 9, maxWidth: '50%', right: '1%', top: '15%', padding: '15px' }}>
        {error}
      </div>
    )
  }
}

export default ErrorToast;
