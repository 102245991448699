import React from "react";
import classes from "../../../assets/scss/common.module.scss";
import authImage from "../../../assets/images/auth/authentication.png";
import logo from "../../../assets/images/logo.png";

import { Link } from "react-router-dom";

const AuthWrapper = ({ children }) => {
  return (
    <section
      className={`${classes["authentication-wrapper"]} ${classes["h_bg_wrapper"]}`}
    >
      <div className={classes["authentication-img"]}>
        <Link to="/home">
          <img
            src={logo}
            className={classes["authentication-logo"]}
            alt="logo"
          />
        </Link>
        <img
          src={authImage}
          className={classes["authentication"]}
          alt="authentication"
        />
      </div>
      <div
        className={`${classes["authentication-content"]}`}
      >
        {children}
      </div>
    </section>
  );
};

export default AuthWrapper;
