import React from "react"
import { Link } from "react-router-dom";

import environment from "../../../Models/env";
import userImage from "../../../assets/img/user2.png";
import logo from "../../../assets/images/logo.png";
import { userAccess, showBtn } from "../roles-and-permission/permissions";
import { userInfoData } from "../LocalStorages";
import NoRecords from "../not-found/RecordsNotFound";

import "./VendorCard.styles.css";

const VendorCard = ({
  items,
  onEdit,
  onDelete,
  modalOpen,
  onNameClick,
  permission,
  message,
  onGallery,
  isChecked,
  // isVendor,
}) => (
  <div className="row mt-3 pb-3">
    {modalOpen && showBtn() && userAccess(permission, null, null, "add") || (modalOpen && userInfoData()["userType"] === "CL" ) ? (
      <div className="col-lg-3 mb-3">
        <aside className="card user_card">
          <header className="card-header add_new_body">
            <button
              className="add_new_cardbtn modal-button memberModalBtn"
              type="button"
              onClick={(e) => modalOpen(e)}
            >
              <i className="bx bx-plus"></i>
            </button>
          </header>
          <div className="card-body user_bio">
            <p className="text-dark-75 mt-20">Add New Vendor</p>
          </div>
        </aside>
      </div>
    ) : (
      ""
    )}
    {items.length ? (
      items.map((item, idx) => (
        <div className="col-lg-3 mb-3" key={idx}>
          <article className="card vendor_cards">
            <div className="cards__info-hover mb-0 list_title">
	       {onGallery &&
                userAccess(
                  permission,
                  item["created_by"],
                  userInfoData()["encrypt_id"],
                  "add"
                ) && 
                // isVendor === "1" &&
                (userInfoData()["userType"] === "SA" ||
                  userInfoData()["userType"] === "EP" ||
                  userInfoData()["userType"] === "SA_TM" ||
                  userInfoData()["userType"] === "SA_MA" ||
                  userInfoData()["userType"] === "EP_TM" || 
		(userInfoData()["userType"] === "CL" && item["eVV"] === true)) &&
                (!window.location.pathname.includes("/app/vendors")) &&
		(
                  <button
                    type="button"
                    className="btn text-dark-75 font-weight-bolder "
                    onClick={() => {
                      onGallery(item.id);
                    }}
                  >
                    <i className="bx bx-camera"></i>
                  </button>
                )}
              {onEdit &&
                userAccess(
                  permission,
                  item["created_by"],
                  userInfoData()["encrypt_id"],
                  "add"
                ) && 
                // isVendor === "1" &&
                (userInfoData()["userType"] === "SA" ||
                  userInfoData()["userType"] === "EP" ||
                  userInfoData()["userType"] === "SA_TM" ||
                  userInfoData()["userType"] === "SA_MA" ||
                  userInfoData()["userType"] === "EP_TM" ||
                (userInfoData()["userType"] === "CL" && item["eVV"] === true)) &&
                (!window.location.pathname.includes("/app/vendors") || isChecked === true) &&
		(
                  <button
                    type="button"
                    className="btn text-dark-75 font-weight-bolder "
                    onClick={() => {
                      onEdit(item.id);
                    }}
                  >
                    <i className="bx bx-pencil"></i>
                  </button>
                )}
              {onDelete &&
                userAccess(
                  permission,
                  item["created_by"],
                  userInfoData()["encrypt_id"],
                  "delete"
                ) &&
                // isVendor === "1" &&
                (userInfoData()["userType"] === "SA" ||
                  userInfoData()["userType"] === "EP" ||
                  userInfoData()["userType"] === "SA_TM" ||
                  userInfoData()["userType"] === "SA_MA" ||
                  userInfoData()["userType"] === "EP_TM" ||
                (userInfoData()["userType"] === "CL" && item["dVV"] === true)) &&
                (!window.location.pathname.includes("/app/vendors")) &&
                (
                  <button
                    type="button"
                    className="btn text-dark-75 font-weight-bolder"
                    onClick={() => {
                      onDelete(item.id);
                    }}
                  >
                    <i className="bx bx-trash"></i>
                  </button>
                )}
            </div>
            <div className="cards__img">
              <div
                style={{
                  backgroundImage: `url(${item.background_image
                    ? environment.imageBaseUrl + item.background_image
                    : logo
                    })`,
                }}
              ></div>
            </div>
            <button
              type="button"
              className="btn cards_link"
              onClick={(e) => {
                onNameClick &&
                  onNameClick(
                    e,
                    item.id,
                    item.project_ids,
                    `${item.first_name} ${item.last_name}`,
                    item.email,
                    item["owner_id"]
                  );
              }}
            >
              {onNameClick ? (
                item.image ? (
                  <div
                    className="image overflow-hidden"
                    style={{
                      backgroundImage: `url(${environment.imageBaseUrl + item.image
                        })`,
                    }}
                  >
                    <img
                      src={environment.imageBaseUrl + item.image}
                      className="w-100 h-100"
                      alt={item.image}
                    />
                  </div>
                ) : (
                  <div
                    className="image"
                    style={{ backgroundImage: `url(${userImage})` }}
                  ></div>
                )
              ) : item.image ? (
                <div
                  className="image overflow-hidden"
                  style={{
                    backgroundImage:
                      "url(" + environment.imageBaseUrl + item.image + ")",
                  }}
                >
                  <img
                    src={environment.imageBaseUrl + item.image}
                    className="w-100 h-100"
                    alt={item.image}
                  />
                </div>
              ) : (
                <div
                  className="image"
                  style={{ backgroundImage: `url(${userImage})` }}
                ></div>
              )}
            </button>
            <div
              className={`card-info mt-1 ${onNameClick && "justify-content-center"
                }`}
            >
              <span className="card-title mb-1">
                {typeof item.vendor_category_name !== "string"
                  ? item.vendor_category
                  : item.vendor_category_name || ""}
              </span>
              <h3
                className={`card-text textLimit ${onNameClick ? "mb-1" : "mb-1"
                  }`}
              >
                {onNameClick && item.company ? (
                  <button
                    type="button"
                    className="btn p-0 card-text textLimit font-size-h5 font-weight-bold text-left"
                    onClick={(e) => {
                      onNameClick(
                        e,
                        item.id,
                        item.project_ids,
                        `${item.first_name} ${item.last_name}`,
                        item.email,
                        item["owner_id"]
                      );
                    }}
                  >{`${item.company}`}</button>
                ) : null}
                {item.company ? (
                  <span className="card-title mb-1 font-size-sm">
                    {item.company}
                  </span>
                ) : null}
              </h3>
              <div>
                {item.company_email && (
                  <Link
                    to="#"
                    className="d-flex align-items-center text-dark-75 font-weight-bolder h6 textLimit mb-1"
                    title={
                      onNameClick && item.company_email
                        ? item.company_email
                        : ""
                    }
                  >
                    <i className="bx bx-envelope mr-1"></i>
                    {onNameClick && item.company_email
                      ? item.company_email
                      : ""}
                  </Link>
                )}
                {item.company_phone && (
                  <Link
                    to="#"
                    className="d-flex align-items-center text-dark-75 font-weight-bolder h6 textLimit mb-0"
                    title={
                      onNameClick && item.company_phone
                        ? item.company_phone
                        : ""
                    }
                  >
                    <i className="bx bx-phone-call mr-1"></i>
                    {onNameClick && item.company_phone
                      ? item.company_phone
                      : ""}
                  </Link>
                )}
              </div>
              <h3
                className={`card-text textLimit ${onNameClick ? "mb-1" : "mb-1"
                  }`}
              >
                {item.first_name && (
                  <button
                    // style={{cursor: "pointer"}}
                    type="button"
                    className="btn p-0 card-text textLimit font-size-h5 font-weight-bold text-left"
                    onClick={(e) => {
                      onNameClick &&
                        onNameClick(
                          e,
                          item.id,
                          item.project_ids,
                          `${item.first_name} ${item.last_name}`,
                          item.email,
                          item["owner_id"]
                        );
                    }}
                  >
                    {item.first_name} {item.last_name}
                  </button>
                )}
              </h3>
              <div>
                <Link
                  to="#"
                  className="d-flex align-items-center text-dark-75 font-weight-bolder h6 textLimit mb-1"
                  title={
                    onNameClick && item.company_email
                      ? item.company_email
                      : item.email
                  }
                >
                  <i className="bx bx-envelope mr-1"></i>
                  {item.email ? item.email : ""}
                </Link>
                {item.phone && (
                  <Link
                    to="#"
                    className="d-flex align-items-center text-dark-75 font-weight-bolder h6 textLimit mb-0"
                    title={item.phone ? item.phone : ""}
                  >
                    <i className="bx bx-phone-call mr-1"></i>
                    {item.phone ? item.phone : ""}
                  </Link>
                )}
                {item.address1 && (
                  <Link
                    to="#"
                    className="d-flex align-items-center text-dark-75 font-weight-bolder h6 textLimit mb-0"
                    title={item.address1 ? item.address1 : ""}
                  >
                    <i className="bx bx-location-plus mr-1"></i>
                    {item.address2 ? `${item.address2} ` : ""}
                    {item.address1}
                  </Link>
                )}
                {item.facebook && (
                  <a
                    href={"https://" + item.facebook}
	            target="_blank"
                  >
                    <i className="bx bxl-facebook"></i>
                  </a>
                )}
                {item.twitter && (
                  <a
                    href={"https://" + item.twitter}
                    target="_blank"
                  >
                    <i className="bx bxl-twitter"></i>
                  </a>
                )}
                {item.instagram && (
                  <a
                    href={"https://" + item.instagram}
                    target="_blank"
                  >
                    <i className="bx bxl-instagram"></i>
                  </a>
                )}
                {item.pinterest && (
                  <a
                    href={"https://" + item.pinterest}
                    target="_blank"
                  >
                    <i className="bx bxl-pinterest"></i>
                  </a>
                )}
                {item.linkedin && (
                  <a
                    href={"https://" + item.linkedin}
                    target="_blank"
                  >
                    <i className="bx bxl-linkedin"></i>
                  </a>
                )}
                {item.other && (
                  <a
                    href={"https://" + item.other}
                    target="_blank"
                  >
                    <i className="bx bx-globe"></i>
                  </a>
                )}
                {item.vquote_comment && (
                  <Link
                    to="#"
                    className="d-flex align-items-center text-dark-75 font-weight-bolder h6 textLimit mb-0"
                    title={item.vquote_comment ? item.vquote_comment : ""}
                  >
                    <i className="bx bx-task mr-1"></i>
                    {item.vquote_comment ? item.vquote_comment : ""}
                  </Link>
                )}
              </div>
            </div>
          </article>
        </div>
      ))
    ) : (
      <div className="col-lg-9 mb-3">
        <NoRecords message={message} />
      </div>
    )}
  </div>
);

export default VendorCard;
