import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";


import AppRouting from "./Route/AppRoting";

import ErrorBoundaries from "./Shared/Components/error-boundary/ErrorBoundary";
import Loader from "./Shared/Components/Loader";
import { Toaster } from "react-hot-toast";
/*import Echo from 'laravel-echo';
let key = "576fe3b0367a8f594bfc";
window.Pusher = require('pusher-js');

window.Echo = new Echo({
  broadcaster: "pusher",
  key: key,
  cluster:"us3",
  //wsHost: '127.0.0.1',
  //wsPort: 6001,
  //disableStats: true,
  //forceTLS: false,
  encrypted: true
});

window.Echo.join('example')
    .listen('ExampleEvent', (e) => {
        console.log('Example : '+e);
    });*/
class App extends React.Component {
  
  render() {
    return (
      <ErrorBoundaries>
        <Toaster
          position="top-right"
          containerClassName="toaster-container"
         />
        <Suspense fallback={<Loader showLoader />}>
        <Router>
          <AppRouting />
        </Router>
        </Suspense>
      </ErrorBoundaries>
    );
  }
}

export default App;
