import { ADD_CLIENT_TABS } from "../../Shared/globalVariables";
import {
  CLIENT_DATA_FAILURE,
  CLIENT_DATA_REQUEST,
  CLIENT_DATA_SUCCESS,
  CLOSE_ADD_CLIENT_DRAWER,
  OPEN_ADD_CLIENT_DRAWER,
  SELECT_EXISTING_TAB,
  SELECT_NEW_TAB,
  SET_ADD_CLIENT_TAB,
} from "./actionTypes";

const INITIAL_STATE = {
  isOpen: false,
  selectedTab: ADD_CLIENT_TABS.CHOOSE_EXISTING,
  clientInformation: {
    isLoading: false,
    response: null,
    error: null,
  },
};

function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    /**
     * STATE VARIABLES FOR ADD CLIENT DRAWER
     */
    case OPEN_ADD_CLIENT_DRAWER:
      return {
        ...state,
        isOpen: true,
        selectedTab: ADD_CLIENT_TABS.CHOOSE_EXISTING,
      };
    case CLOSE_ADD_CLIENT_DRAWER:
      return {
        ...state,
        isOpen: false,
        clientInformation: {
          isLoading: false,
          response: null,
          error: null,
        }
      };

    case SELECT_EXISTING_TAB:
      return {
        ...state,
        isOpen: true,
        selectedTab: ADD_CLIENT_TABS.CHOOSE_EXISTING,
      };
    case SELECT_NEW_TAB:
      return {
        ...state,
        isOpen: true,
        selectedTab: ADD_CLIENT_TABS.ADD_NEW,
      };
    case SET_ADD_CLIENT_TAB:
      return {
        ...state,
        isOpen: true,
        selectedTab: action.payload,
      };

    /**
     CLIENT DETAILS:

    When drawer is opened via clicked on
    some user's profile disc
    **/
    case CLIENT_DATA_REQUEST:
      return {
        ...state,
        clientInformation: {
          isLoading: true,
        },
      };
    case CLIENT_DATA_SUCCESS:
      return {
        ...state,
        selectedTab: ADD_CLIENT_TABS.ADD_NEW,
        clientInformation: {
          isLoading: false,
          response: action.payload,
        },
      };
    case CLIENT_DATA_FAILURE:
      return {
        ...state,
        selectedTab: ADD_CLIENT_TABS.ADD_NEW,
        clientInformation: {
          isLoading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
}

export default reducer;
