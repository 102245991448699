import React from "react";
import Header from "./Header";
import Footer from "./Footer";

import classes from "../../../assets/scss/common.module.scss"
import { PopupWidget } from "react-calendly";

const NewLayoutWrapper = ({ children, otherPage }) => {
  return (
    <React.Fragment>
      <div className={otherPage ? classes["other-pages"] : ""}>
        {children}
        <PopupWidget
          rootElement={document.getElementById("root")}
          color="#197cb5"
          text="Request a Demo"
          textColor="#ffffff"
          url="https://calendly.com/harpsen-1768/30min"
      />
      </div>
    </React.Fragment>
  );
};

export default NewLayoutWrapper;
