import React from "react";

const Errormsg = (props) => {
  return (
    props.errormsg.error &&
    props.errormsg.touched && (
      <div className="invalid-feedback">{props.errormsg.error}</div>
    )
  );
};

export default Errormsg;
