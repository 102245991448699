import React, { Component, lazy } from "react";
import { withRouter, Route, Redirect, Switch } from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "../../assets/css/homeStyle.css";

import Message from "../../Shared/Components/Message";
import { PopupWidget } from "react-calendly";

// import FrontEndLogin from '../Home/Pages/Frontend-login/FrontendLogin';

// Routing components
import Home from "./Pages/home/HomePage";

import PageNotFound from "../../Shared/Components/PageNotFound";
import ScrollToTop from "../../Models/ScrollToTop";
import { getLocalStorageItem } from "../../Shared/Components/LocalStorages";
import NewLayoutWrapper from "../../Shared/Components/Layout/NewLayoutWrapper";
import AuthWrapper from "../../Shared/Components/PageContainers/AuthWrapper";

const Payment = lazy(() => import("./Pages/payment/Payment")) 
const Success = lazy(() => import("./Pages/payment/Success")) 

const ForgetPassword = lazy(() => import("./Pages/auth/ForgetPassword")) 
const ResetPassword = lazy(() => import("./Pages/auth/ResetPassword")) 

const Pages = lazy(() => import("./Pages/common/Pages")) 
const BlogList = lazy(() => import("./Pages/blog/List")) 
const BlogView = lazy(() => import("./Pages/blog/View")) 
const Login = lazy(() => import("./Pages/auth/login/Login")) 
const SignUp = lazy(() => import("./Pages/auth/register/SignUp")) 
const NewFaqs = lazy(() => import("./Pages/faqs/NewFaqs")) 

const Header = lazy(() => import("./Pages/Header"));
const Footer = lazy(() => import("./Pages/Footer"));

const LayoutWrapper = ({ children }) => {
  return (
    <React.Fragment>
      <Message />
      <Header />
      {children}
      <Footer />
      <PopupWidget
        rootElement={document.getElementById("root")}
        color="#b3c6b3"
        text="Request a Demo"
        textColor="#ffffff"
        url="https://calendly.com/harpsen-1768/30min"
      />
    </React.Fragment>
  );
};

class HomeLayout extends Component {
  constructor(props) {
    super(props);
  }
  checkForLogin(comp) {
    if (
      getLocalStorageItem("token", "prs") &&
      getLocalStorageItem("userInfo", "prs")
    ) {
      return <Redirect to="/app/dashboard" />;
    } else {
      return comp;
    }
  }

  componentDidMount() {
   this.updateSEOTags()
  }

  componentDidUpdate() {
    this.updateSEOTags()
  }

  updateSEOTags = () => {
    const canonicalLink = document.getElementById("canonical-link");
    canonicalLink.setAttribute("href", window.location.href);
    const toBeIndexedPage = [
      {
        path: "/home",
        follow: true,
        index: true,
      },
      {
        path: "/blog",
        follow: true,
        index: true,
      },
    ];


    const robotsMeta = document.getElementById("robots-meta");
    const indexedPage = toBeIndexedPage.find(x => window.location.href.includes(x.path)) 
    let metaContent = `${indexedPage?.index ? "index" : "noindex"}, ${indexedPage?.follow ? "follow" : "nofollow"}, max-image-preview:large, max-snippet:-1, max-video-preview:-1`
    robotsMeta.setAttribute("content", metaContent);
  };

  refreshPage(){
    if (window.location.pathname === '/home') {
       window.location.reload();
    }
  }

  refreshPPPage(){
    if (window.location.pathname === '/home/privacy-policy.html') {
       window.location.reload();
    }
  }

  refreshTSPage(){
    if (window.location.pathname === '/home/terms-of-service.html') {
       window.location.reload();
    }
  }

  render(props) {
    return (
      <React.Fragment>
        <ScrollToTop>
          <Switch>
            <Route exact path="/">
	    <Redirect to="/home" />
          </Route>

            <Route exact path="/home">
            {this.refreshPage()}
          </Route>

            <Route exact path="/home/privacy-policy.html">
	    {this.refreshPPPage()}
          </Route>

            <Route exact path="/home/terms-of-service.html">
            {this.refreshTSPage()}
          </Route>

	    { /*<Route exact path="/">
              <Redirect to="/home" />
            </Route>*/}

	    {/* <Route path="/home">
	    {console.log("HOME")}
              <NewLayoutWrapper>
                <Home />
              </NewLayoutWrapper>
            </Route>
*/}
            <Route path="/login">{this.checkForLogin(<Login />)}</Route>

            <Route path="/signup">{this.checkForLogin(<SignUp />)}</Route>

            <Route path="/forgotpassword">
              <AuthWrapper>
                <ForgetPassword />
              </AuthWrapper>
            </Route>
            <Route path="/resetpassword/:token">
              <AuthWrapper>
                <ResetPassword />
              </AuthWrapper>
            </Route>
            <Route path="/setpassword/:token">
              {this.checkForLogin(
                <AuthWrapper>
                  <ResetPassword />
                </AuthWrapper>
              )}
            </Route>
            <Route path="/payment/:id">
              <LayoutWrapper>
                <Payment />
              </LayoutWrapper>
            </Route>
            <Route path="/success">
              <LayoutWrapper>
                <Success />
              </LayoutWrapper>
            </Route>

            <Route exact path="/blog">
              <NewLayoutWrapper otherPage>
                <BlogList />
              </NewLayoutWrapper>
            </Route>            
            
            <Route exact path="/blog/category/:category">
              <NewLayoutWrapper otherPage>
                <BlogList />
              </NewLayoutWrapper>
            </Route>
            
            <Route path="/blog/:slug">
              <NewLayoutWrapper otherPage>
                <BlogView />
              </NewLayoutWrapper>
            </Route>


            <Route exact path="/faqs">
              <NewLayoutWrapper otherPage>
                <NewFaqs />
              </NewLayoutWrapper>
            </Route>

            <Route exact path="/:slug">
              <NewLayoutWrapper otherPage>
                <Pages />
              </NewLayoutWrapper>
            </Route>

            <Route path="/pagenotfound">
	    {/*  <LayoutWrapper>*/}
                <PageNotFound />
	    {/* </LayoutWrapper>*/}
            </Route>

            <Route path="*">
              <Redirect to="/pagenotfound" />
            </Route>
          </Switch>
        </ScrollToTop>
      </React.Fragment>
    );
  }
}
export default withRouter(HomeLayout);
