import axios from "../../../../config/api";
import { token } from "../../../../Shared/Components/LocalStorages";

export function getVendorList(data, myVendors) {
  let url = `/vendor/list/${myVendors}`;
  return axios.post(url, data, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

export function addVendor(data) {
  let url = `/vendor`;
  return axios.post(url, data, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

export function getVendorDetails(id) {
  let url = `/vendor/${id}`;
  return axios.get(url, { headers: { Authorization: `Bearer ${token()}` } });
}

export function updateVendorBackground(data, id) {
  let url = `/vendor_back/${id}`;
  return axios.post(url, data, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

export function updateVendorDetails(data, id) {
  let url = `/vendors/${id}`;
  return axios.post(url, data, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

export function getVendorCategory() {
  let url = `/active-vendorcategory`;
  return axios.get(url, { headers: { Authorization: `Bearer ${token()}` } });
}

export function userAction(data) {
  let url = `/vendor-actions`;
  return axios.post(url, data, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

export function VendorLoginEPList(data) {
  let url = `/eventplanner/list`;
  return axios.post(url, data, {
    headers: { Authorization: `Bearer ${token()}` },
  });
}

export function VendorToSubscriber(id) {
  let url = `/subscribe/${id}`;
  return axios.get(url, { headers: { Authorization: `Bearer ${token()}` } });
}
