import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from "../../Shared/Components/LocalStorages";
import { logout } from "../../Shared/Services/commonApis";

const userTypeConstants = {
    SA: "SA",
    SA_TM: "SA_TM",
    SA_MA: "SA_MA",
    EP: "EP",
    EP_TM: "EP_TM",
    EP_MA: "EP_MA",
    VN: "VN",
    VN_TM: "VN_TM",
    CL: "CL",
};

const auth = (state = {}, action) => {
    switch (action.type) {
        case "LOGOUT":
            logout();

            let userInfo = getLocalStorageItem("userInfo");
            setLocalStorageItem("token", "", "nstr");
            removeLocalStorageItem("userInfo");
            removeLocalStorageItem("evpId");
            removeLocalStorageItem("path");

            if (
                userInfo.userType === userTypeConstants.SA ||
                userInfo.userType === userTypeConstants.SA_TM ||
                userInfo.userType === userTypeConstants.SA_MA
            ) {
                window.location.href = "/app/login";
            } else {
                window.location.href = "/login";
            }

            break;

        default:
            return state;
    }
}

export default auth;