import React from "react";
import classes from "./Chat.module.css";
import Dropdown from 'react-bootstrap/Dropdown';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href="/#"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

const UserStatus = ({ user, changeChatStatus }) => {
  return (
    <Dropdown
      className="status-dropdown"
      onSelect={(status) => {
        changeChatStatus(status);
      }}
    >
      <Dropdown.Toggle
        id="dropdown-custom-components"
        as={CustomToggle}
      >
        <span
          className={`${classes["contact-status"]} ${classes[user.status]} cursor-pointer`}
        ></span>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item eventKey="online" active={user.status === 'online'}>Online</Dropdown.Item>
        <Dropdown.Item eventKey="offline" active={user.status === 'offline'}>Offline</Dropdown.Item>
        <Dropdown.Item eventKey="away" active={user.status === 'away'}>Away</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default UserStatus;