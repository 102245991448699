import _cloneDeep from "lodash/cloneDeep";
import { combineReducers } from "redux";

const messages = (state = [], action) => {
  switch (action.type) {
    case "GET_MESSAGES":
      return action.payload

    case "ADD_MESSAGE": {
      const prevMessages = _cloneDeep(state);
      let newMessage = _cloneDeep(action.payload);
      delete newMessage.isCurrentChannel;
      const messages = action.payload.isCurrentChannel ? prevMessages.concat(newMessage) : prevMessages;

      return messages;
    }
    default:
      return state;
  }
}

const selectedChannel = (state = {}, action) => {
  switch (action.type) {
    case "SET_SELECTED_CHANNEL": {
      return action.payload;
    }

    case "RESET_SELECTED_CHANNEL":
    case "LEAVE_GROUP_SUCCESS":
      return {};

    default:
      return state;

  }
}

const allUsers = (state = [], action) => {
  switch (action.type) {
    case "ALL_USERS": {
      return action.payload;
    }
    default:
      return state;

  }
}
const notifications = (state = [], action) => {
  switch (action.type) {
    case "GET_NOTIFICATIONS": {
      return action.payload.notifications;
    }
    case "ADD_NOTIFICATION": {
      const notification = {
        data: action.payload,
        id: action.payload.id,
        read_at: null
      };

      return [notification, ...state];

    }
    case "NOTIF_MARK_AS_READ": {
      return state.map(notification => {
        if (notification.data.id === action.payload[0].data.id) {

          notification.read_at = action.payload[0].read_at;
          return notification;
        } else {
          return notification;
        }
      });
    }

    default:
      return state;

  }
}

const friends = (state = [], action) => {
  switch (action.type) {
    case 'ACCEPT_REQUEST_SUCCESS': {
      return state.concat(action.payload);
    }

    case "FRIEND_LIST": {
      return action.payload;
    }
    case "IS_ONLINE": {

      return state.map(friend => {

        if (friend.users[0].id === parseInt(action.payload)) {

          friend.users[0].status = "online";
          return friend;
        } else {
          return friend;
        }
      });
    }


    case "IS_OFFLINE": {

      return state.map(friend => {
        if (friend.users[0].id === parseInt(action.payload)) {

          friend.users[0].status = "offline";
          return friend;
        } else {
          return friend;
        }
      });
    }

    case "IS_AWAY": {

      return state.map(friend => {
        if (friend.users[0].id === parseInt(action.payload)) {

          friend.users[0].status = "away";
          return friend;
        } else {
          return friend;
        }
      });
    }

    case "UPDATE_UNREAD_MESSAGE": {
      return state.map(friend => {
        if (friend.users[0].id === action.payload.users[0].id) {
          friend.unread_message_count = action.payload.unread_message_count;

          return friend;
        } else {
          return friend;
        }
      });
    }

    case "SET_LATEST_MESSAGE": {
      if (action.payload.type === 'dm') {
        return state.map(friend => {
          if (friend.id === action.payload.id) {
            friend.message = action.payload.message;
            friend.messageDate = action.payload.messageDate;
            return friend;
          } else {
            return friend;
          }
        });
      }
      return state;
    }
    default:
      return state;

  }

}

const channelUsers = (state = [], action) => {
  switch (action.type) {
    case "CHANNEL_USERS_LIST": {
      return action.payload;
    }
    default:
      return state;
  }
}

const groupList = (state = [], action) => {
  switch (action.type) {

    case "CHANNEL_LIST": {
      return action.payload;
    }
    case "CREATE_CHANNEL_SUCCESS":
    case "ADD_CHANNEL_SUCCESS": {
      return state.concat(action.payload);
    }
    case "LEAVE_GROUP_SUCCESS": {
      return state.filter(
        channel => channel.id !== action.payload
      );
    }
    case "UPDATE_UNREAD_GROUP_MESSAGE": {
      return state.map(group => {

        if (group.id === action.payload.id) {
          group.unread_message_count = action.payload.unread_message_count;
          return group;
        } else {
          return group;
        }
      });
    }
    case "SET_LATEST_MESSAGE": {
      if (action.payload.type !== 'dm') {
        return state.map(group => {
          if (group.id === action.payload.id) {
            group.message = action.payload.message;
            group.messageDate = action.payload.messageDate;
            return group;
          } else {
            return group;
          }
        });
      }
      return state;
    }
    default:
      return state;
  }
}

const enteredText = (state = "", action) => {
  switch (action.type) {
    case "ENTER_MESSAGE": {
      return action.payload;
    }
    default:
      return state;
  }
}

const usersInRoom = (state = [], action) => {
  switch (action.type) {

    case "SET_USERS_IN_ROOM": {
      return action.payload;
    }
    case "ADD_USER_TO_ROOM": {
      return state.concat(action.payload);
    }
    case "USER_LEAVES_ROOM": {
      return state.filter(
        user => user.id !== action.payload.id
      );
    }
    default:
      return state;
  }
}

const requests = (state = [], action) => {
  switch (action.type) {
    case "SEND_REQUEST_SUCCESS": {
      return state.concat(action.payload);
    }
    default:
      return state;
  }
}

const unreadNotifs = (state = 0, action) => {
  switch (action.type) {
    case "GET_NOTIFICATIONS": {
      return action.payload.unread_count
    }
    case "ADD_NOTIFICATION": {
      return state + 1;
    }
    case "NOTIF_MARK_AS_READ": {
      return state - 1;
    }
    default:
      return state;
  }
}

const typings = (state = [], action) => {
  switch (action.type) {
    case "ADD_TYPING_EVENT": {
      let isFound = state.find(typing => typing.user.id === action.payload.user.id);
      return isFound ? state : state.concat(action.payload);
    }
    case "REMOVE_TYPING_EVENT": {
      return state.filter(
        typing => typing.user.id !== action.payload.user.id && typing.channelId !== action.channelId
      );
    }
    default:
      return state;
  }
}

const loading = (state = false, action) => {
  switch (action.type) {
    case "FETCH_USER":
    case "SET_LOADING":
    case "SEND_REQUEST":
    case "FETCH_CHAT_USERS_REQUEST":
    case "FETCH_CHANNEL_USER_REQUEST":
    case "CHANGE_STATUS":
    case "LEAVE_GROUP_REQUEST": {
      return true;
    }
    case "FETCH_USER_SUCCESS":
    case "RESET_LOADING":
    case "SEND_REQUEST_SUCCESS":
    case "SEND_REQUEST_ERROR":
    case "FETCH_CHAT_USERS_SUCCESS":
    case "LEAVE_GROUP_SUCCESS":
    case "CHANNEL_USERS_LIST":
    case "CHANGE_STATUS_SUCCESS":
    case "CHANGE_STATUS_ERROR":
    case "LEAVE_GROUP_ERROR":
    case "ACCEPT_REQUEST_SUCCESS":
    case "ADD_CHANNEL_SUCCESS":
      return false;
    default:
      return state;
  }
}

const error = (state = "", action) => {
  switch (action.type) {
    case "RESET_CHAT_ERROR": {
      return "";
    }

    case "SET_CHAT_ERROR":
    case "LEAVE_GROUP_ERROR": {
      return action.error;

    }
    default:
      return state;
  }
}

const unreadCount = (state = 0, action) => {
  switch (action.type) {
    case "UPDATE_TOTAL_UNREAD_COUNT": {
      return action.payload;
    }

    default:
      return state;
  }
}

const user = (state = {}, action) => {
  switch (action.type) {
    case "FETCH_USER_SUCCESS": {
      return action.payload;
    }
    case "IS_ONLINE": {
      const userObj = _cloneDeep(state);
      if (userObj.id === parseInt(action.payload)) {
        userObj.status = 'online'
      }
      return userObj;
    }
    case "IS_OFFLINE": {
      const userObj = _cloneDeep(state);
      if (userObj.id === parseInt(action.payload)) {
        userObj.status = 'offline'
      }
      return userObj;
    }
    case "IS_AWAY": {
      const userObj = _cloneDeep(state);
      if (userObj.id === parseInt(action.payload)) {
        userObj.status = 'away'
      }
      return userObj;
    }

    default:
      return state;
  }
}


export default combineReducers({
  user,
  messages,
  selectedChannel,
  allUsers,
  notifications,
  friends,
  channelUsers,
  groupList,
  enteredText,
  usersInRoom,
  requests,
  unreadNotifs,
  typings,
  loading,
  error,
  unreadCount,
});
