import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";

// import Routing from "./Routing";
import { checkPayment } from "./Service/Purchase/purchaseService";
import PaymentReminder from "./Components/LayoutComponent/PaymentReminder";
import VendorReminder from "./Components/LayoutComponent/VendorReminder";
// import Message from "../../Shared/Components/Message";
import {
  getLocalStorageItem,
  userInfoData,
  setLocalStorageItem,
  removeLocalStorageItem,
} from "../../Shared/Components/LocalStorages";
import VendorLogin from "./Pages/Vendor/VendorLogin";
import {
  userTypeConstants,
  componentHide,
} from "../../Shared/Components/roles-and-permission/permissions";

import Chat from "./Pages/chat/Chat";
import Message from "../../Shared/Components/Message";
import moment from "moment";
const Routing =  React.lazy(() => import("./Routing"));

const Header =  React.lazy(() => import("../../Shared/Components/Header"));
const AdminFooter =  React.lazy(() => import("../../Shared/Components/AdminFooter"));

class AdminLayout extends Component {
  constructor(props) {
    super(props);
    this.isMount = false;
    this.state = {
      showReminder: false,
      showVendorReminder: false,
      url: this.props.location.pathname,
      is_payment: true,
      is_payment_done: false,
      has_plan: false,
      days_left: 0,
      name: "",
      date: "",
      SA: false,
      EP: false,
      CL: false,
      VN: false,
      userData: userInfoData(),
    };
  }

  componentDidMount() {
    this.isMount = true; 
  /*  let arr = this.state.userData.all_types;
    if(arr.indexOf('EP') === -1 && this.state.userData.userType === userTypeConstants.VN)  {
	this.setState({showVendorReminder: true});
    }*/
    if (this.isMount) {
      let Log = componentHide();
      if (Log) {
        let k = Object.keys(Log);
        this.setState({ [k[0]]: Log[k[0]] });
      }

      if (!localStorage.getItem("token")) {
        this.redirect();
      } else {
        let arr = this.state.userData.all_types;
	if (arr) {
          if(arr.indexOf('EP') === -1 && this.state.userData.userType === userTypeConstants.VN)  {
            this.setState({showVendorReminder: true});
         }
	}
      }

      const userInfo = userInfoData();
      if (userInfo.isVerified === false) {
        this.redirect();
      }

    }
    const { userData } = this.state;
    if (
      userData["userType"] === "EP" ||
      userData["userType"] === "EP_TM" ||
      userData["userType"] === "EP_MA"
    ) {
      this.checkPaymentStatus();
    }
  }

  componentDidUpdate() {
    const { url } = this.state;
    const {
      location: { pathname },
    } = this.props;

    if (url !== pathname) {
      this.setState({ url: pathname }, () => {
        // if (userData['userType'] === 'EP') {
        this.checkPaymentStatus();
        // }
        const backdropEl = document.querySelector('.modal-backdrop');
        if (backdropEl) {
          backdropEl.parentNode.removeChild(backdropEl)
        }

      });
    }


  }

  componentWillUnmount() {
    this.isMount = false;
  }

  checkPaymentForExpire(userData) {
    if (
      userData["userType"] === "CL" ||
      userData["userType"] === "VN" ||
      userData["userType"] === "VN_TM"
    ) {
      let epData = getLocalStorageItem("evpId");
      this.getPaymentStatus(epData["ep_id"], userData);
    } else {
      if (
        userData["userType"] === "EP_TM" ||
        userData["userType"] === "EM_MA"
      ) {
        this.getPaymentStatus(userData["encrypted_parent_id"], userData);
      } else {
        this.getPaymentStatus(userData["encrypt_id"], userData);
      }
    }
  }

  getPaymentStatus(id, data) {
    checkPayment(id)
      .then((res) => {
        let data = res.data.data;
        let d = new Date();
        d.setDate(d.getDate() - 1);
        if (data && !moment(data.end_date).toDate()) {
          data.end_date = d;
        }
        if (
          res.data.success &&
          // (data?.is_payment_done || new Date(data?.end_date) >= new Date())
          (!data?.is_payment_done && moment(data?.end_date).toDate() >= new Date() && (this.state.userData.userType === userTypeConstants.EP ||
            this.state.userData.userType === userTypeConstants.EP_TM))
        ) {
          this.setState({
            is_payment: true,
            has_plan: false,
            days_left: data?.days_left,
            date: data?.end_date,
            is_payment_done: data?.is_payment_done,
          }, () => {
            this.checkForReminder();
            this.checkForReminderForCLvN();
            this.checkForReminderForEP_TM();
          });
        } else {
          this.setState(
            {
              is_payment: false,
              has_payment: true,
              days_left: data?.days_left,
              date: data?.end_date,
              is_payment_done: data?.is_payment_done,
            },
            () => {
              let endData = moment(data?.end_date).format("YYYY-MM-DD");
              let currentDate = moment(new Date()).format("YYYY-MM-DD");

              if (
                moment(endData).isSame(currentDate) !== true &&
                moment(data?.end_date).toDate() < new Date() &&
                (this.state.userData.userType === userTypeConstants.EP ||
                  this.state.userData.userType === userTypeConstants.EP_TM)) {
                this.setState({ days_left: 0 });
                this.redirectToExpire();
              } else {
                this.checkForReminder();
                this.checkForReminderForCLvN();
                this.checkForReminderForEP_TM();
              }
            }
          );
        }

        // console.log(
        //   "payment done",
        //   new Date(data?.end_date),
        //   new Date(),
        //   new Date(data?.end_date) < new Date() &&
        //     data.userType === userTypeConstants.EP,
        //   data.userType
        // );
        // if (
        //   new Date(data?.end_date) < new Date() &&
        //   data.userType === userTypeConstants.EP
        // ) {
        //   console.log("payment done");
        //   this.setState({
        //     is_payment: false,
        //     date: data?.end_date || d,
        //     days_left: 0,
        //   });
        //   this.redirectToExpire();
        // }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  redirect() {
    let pathName = this.props.history.location.pathname;
    // if (
    //   pathName.includes("/app/project/templates/") ||
    //   pathName.includes("/app/questionnaire/") ||
    //   pathName.includes("/app/project/view/") ||
    //   pathName.includes("/login")
    // ) {
    if(!(
        pathName.includes("/app/project/templates/proposal/view") ||
        pathName.includes("/app/project/templates/contract/view") ||
        pathName.includes("/app/project/templates/menu/view") ||
        pathName.includes("/app/project/templates/invoice/view") ||
        pathName.includes("/app/project/templates/brochure/view") ||
        pathName.includes("/app/project/templates/package/view") ||
        pathName.includes("/app/questionnaire") ||
        pathName.includes("/app/project/templates/timeline/view") 
//        pathName.includes("/app/project/templates/questionnaires/view")
      )) {
      if (!(
        pathName.includes("/app/subscribersList") ||
        pathName.includes("/app/packages") ||
        pathName.includes("/app/cms") ||
        pathName.includes("/app/faqs") ||
        pathName.includes("/app/email-templates")
      )) {
        this.props.history.push("/login");
        if (pathName !== "/login" && pathName !== "/app/login") {
          setLocalStorageItem("path", pathName);
        }
      } else {
        this.props.history.push("/app/login");
      }
    }
  }

  checkPaymentStatus() {
    if (this.isMount) {
      if (!localStorage.getItem("userInfo")) {
        this.redirect();
      } else {
        const { userData } = this.state;
        this.checkPaymentForExpire(userData);
      }
    }
  }

  redirectToExpire() {
    const { userData } = this.state;
    if (
      userData["userType"] === "CL" ||
      userData["userType"] === "VN" ||
      userData["userType"] === "VN_TM"
    ) {
      const path = this.props.location.pathname.split("/");
      const canOpen = [
        "profile",
        "change-password",
        "helpandsupport",
        "event-planners",
      ];
      if (!canOpen.includes(path[2])) {
        removeLocalStorageItem("evpId");
        this.props.history.push("/app/event-planners");
      } else {
        this.checkForReminder();
        this.checkForReminderForCLvN();
        this.checkForReminderForEP_TM();
      }
    } else {
      const path = this.props.location.pathname.split("/");
      const canOpen = [
        "select-plan",
        "payment",
        "payment-summary",
        "profile",
        "current-plan",
        "plan-history",
        "transaction-history",
        "change-password",
        "helpandsupport",
        "goals",
      ];
      if (!canOpen.includes(path[2])) {
        removeLocalStorageItem("evpId");
        this.props.history.push("/app/trial-expired");
      } else {
        this.checkForReminder();
        this.checkForReminderForCLvN();
        this.checkForReminderForEP_TM();
      }
    }
  }

  checkForReminderForEP_TM() {
    const { userData } = this.state;
    if (userData["userType"] === "EP_TM" || userData["userType"] === "EP_MA") {
      if (!localStorage.getItem("userInfo")) {
        this.redirect();
      } else {
        const path = this.props.location.pathname.split("/");
        const doNotShowPath = [
          "trial-expired",
          "profile",
          "change-password",
          "sign",
        ];
        if (!this.state.is_payment_done) {
          if (!this.state.is_payment && !doNotShowPath.includes(path[2])) {
            this.props.history.push("/app/trial-expired");
          }
        }
      }
    }
  }

  checkForReminderForCLvN() {
    const { userData } = this.state;
    if (
      userData["userType"] === "CL" ||
      userData["userType"] === "VN" ||
      userData["userType"] === "VN_TM"
    ) {
      if (!localStorage.getItem("userInfo") && !localStorage.getItem("evpId")) {
        this.redirect();
      } else {
        const path = this.props.location.pathname.split("/");
        const doNotShowPath = ["event-planners", "trial-expired"];
        if (this.state.is_payment && !doNotShowPath.includes(path[2])) {
          removeLocalStorageItem("evpId");
          this.props.history.push("/app/event-planners");
        }
      }
    }
  }

  checkForReminder() {
    if (!localStorage.getItem("userInfo")) {
      this.redirect();
    } else {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      // const path = this.props.location.pathname.split("/");
      // const doNotShowPath = [
      //   "select-plan",
      //   "payment",
      //   "payment-summary",
      //   "trial-expired",
      // ];
      if (
        (userInfo.userType === userTypeConstants.EP ||
          userInfo.userType === userTypeConstants.EP_TM) &&
        this.state.is_payment
        //!doNotShowPath.includes(path[2])
      ) {
        this.setState({ showReminder: true, name: userInfo.name });
      } else {
        this.setState({ showReminder: false });
      }
    }
  }

  checkVendorLogin() {
    let pathName = this.props.history.location.pathname;
    let location = pathName.split("/");
    let userInfo = getLocalStorageItem("userInfo");
    if (
      userInfo &&
      (userInfo.userType === userTypeConstants.VN ||
        userInfo.userType === userTypeConstants.VN_TM ||
        userInfo.userType === userTypeConstants.CL)
    ) {
      if (getLocalStorageItem("evpId")) {
        let arrCheck = getLocalStorageItem("evpId");
	if (arrCheck['ep_id'] == "view"){
            //console.log("Modifying evpID to temp");
            let data = {
              ep_id: "temp",//paths[paths.length - 6] == 'view' ? paths[paths.length - 5] : paths[paths.length - 3],
              p_ids: "temp",//paths[paths.length - 6] == 'view' ? paths[paths.length - 3] : paths[paths.length - 1],
            };
            setLocalStorageItem("evpId", data);
            return <Redirect to="/app/event-planners" />;
	} else {
            return <Routing />;
	}
      } else {
        if (location[2] === "event-planners") {
          return <VendorLogin />;
        } else {
          if (
            pathName.includes("/app/project/templates/") ||
            pathName.includes("/app/questionnaire/")
          ) {
            //console.log("Do i ever get here");
            let paths = this.props.history.location.datapath ? this.props.history.location.datapath.split("/") : pathName.split("/");
            if(paths[paths.length - 6] == 'view') {
              setLocalStorageItem('path', pathName);
            }

            let data = {
              ep_id: paths[paths.length - 6] == 'view' ? paths[paths.length - 5] : paths[paths.length - 3],
              p_ids: paths[paths.length - 6] == 'view' ? paths[paths.length - 3] : paths[paths.length - 1],
            };
            setLocalStorageItem("evpId", data);
            this.props.history.push(getLocalStorageItem("path"));
            removeLocalStorageItem('path');
          } else {
            return <Redirect to="/app/event-planners" />;
          }
        }
        // }
      }
    } else {
      return <Routing />;
    }
  }

  render(props) {
    return (
      <React.Fragment>
        <Header
          days={this.state.days_left}
          name={this.state.name}
          date={this.state.date}
        />
        {this.state.showReminder && this.state.userData.userType === userTypeConstants.EP && (
          <PaymentReminder
            days={this.state.days_left}
            name={this.state.name}
            date={this.state.date}
          />
        )}
        {this.state.showVendorReminder && (
          <VendorReminder
            expireMessage="0"
            userInfo={userInfoData()}
            name={this.state.name}
          />
        )}
        <Message />
        {
          this.checkVendorLogin()
          //<Routing history={this.props.history} />
        }
        {this.state.userData ?
          <Chat />
        : null}
        <AdminFooter />
      </React.Fragment>
    );
  }
}

export default withRouter(AdminLayout);
