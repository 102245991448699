import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
// import { toast } from "react-toastify";

import { validateField, setValue, validateAllFields, } from "../../../Shared/Services/validationService";
import { forgetPassword } from "../Service/loginService";
import Errormsg from "../../../Shared/Components/Errormsg";

import logo from "../../../assets/images/logo.png";
import defaultImage from '../../../Shared/Components/ImgUtils';

class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.setForm();
    this.state = {
      formData: this.setFormFields,
      apiError: '',
      showLoader: '',
      successMsg: '',
    };
  }

  onChange(e) {
    this.setFormFields[e.target.name]["value"] = setValue(
      e,
      this.setFormFields[e.target.name]["type"]
    );
    let validate = validateField(this.setFormFields[e.target.name]);
    this.setFormFields[e.target.name]["error"] = validate.validationMsg;
    this.setState({
      formData: this.setFormFields,
    });
  }

  handleBlur(event) {
    this.setFormFields[event.target.name]["touched"] = true;
    this.setState({
      formData: this.setFormFields,
    });
  }

  setForm() {
    this.setFormFields = {
      email: {
        name: "Email",
        value: "",
        touched: false,
        error: "",
        type: "email",
        validation: [{ required: true }, { maxLength: 50 }, { pattern: true }],
      },
    };
  }

  submitForm(e) {
    e.preventDefault();
    let isFormValid = validateAllFields(this.state.formData);
    if (isFormValid.validated) {
      let data = {
        email: this.state.formData.email.value,
        login_type: "admin"
      };
      this.setState({ showLoader: true });
      forgetPassword(data).then((res) => {
        if (res.data.success) {
          // toast.success(res.data.message);
          this.props.history.push("/app/login");
          this.setState({ apiError: '', successMsg: res.data.message });
        } else {
          this.setState({ apiError: res.data.message, successMsg: '' });
        }
        this.setState({ showLoader: false })
      }).catch((error) => {
        console.log(error);
        this.setState({ showLoader: false });
      });
    } else {
      let fields = Object.keys(this.setFormFields);
      for (let i = 0; i < fields.length; i++) {
        this.setFormFields[fields[i]].error = isFormValid.errors[fields[i]];
        this.setFormFields[fields[i]].touched = true;
      }
      this.setState({
        formData: this.setFormFields,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="login-area">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="login-form">
                <div className="logo">
                  <img src={logo} alt="logo" width='200' onError={(e) => { e.persist(); defaultImage(e, 'logo') }} />
                </div>
                <div className="forgot-password-form">
                  <h2 className='font-size-h5 font-weight-bold'>Recover your password</h2>
                  <p className="mb-0">
                    Please provide the email address that you used when you
                    signed up for your Harpsen account.
                      </p>
                  {
                    this.state.apiError &&
                    <p className="form-text text-danger text-center ">
                      <small>{this.state.apiError}</small>
                    </p>
                  }
                  {
                    this.state.successMsg &&
                    <p className="form-text text-success text-center ">
                      <small>{this.state.successMsg}</small>
                    </p>
                  }
                  <form
                    autoComplete="off"
                    onSubmit={(event) => {
                      this.submitForm(event);
                    }}
                  >
                    <div className="mb-4">
                      <div className="form-group mb-0">
                        <input
                          type="text"
                          className="form-control"
                          name="email"
                          onBlur={(e) => {
                            this.handleBlur(e);
                          }}
                          placeholder="Email address"
                          onChange={(event) => {
                            this.onChange(event);
                          }}
                          onFocus={(event) => {
                            this.onChange(event);
                          }}
                        />
                        <span className="label-title">
                          <i className="bx bx-envelope"></i>
                        </span>
                      </div>
                      <Errormsg errormsg={this.state.formData.email} />
                    </div>

                    <div className="d-flex align-items-center justify-content-between">
                      <button type="submit" className="login-btn">
                        Send Password
                          </button>
                      <p className="m-0 font-size-sm text-dark">
                        Back to
                            <Link
                          to="/app/login"
                          className="btn p-0 font-weight-bold "
                        >
                          <u>&nbsp;Login&nbsp;</u>
                        </Link>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          this.state.showLoader &&
          <div className="loaderContener">
            <span className="Ploader">
              <span className="loader-inner"></span>
            </span>
          </div>
        }
      </React.Fragment>
    );
  }
}

export default withRouter(ForgetPassword);
