import axios from "../../../../config/api";

export function getAllPlans() {
  let url = `/plans`;
  return axios.get(url, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } });
}

export function getPlanData(id) {
  let url = `/plans/${id}`;
  return axios.get(url, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } });
}

export function getProjectPaymentData(id) {
  let url = `/project-payment/${id}`;
  return axios.get(url, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } });
}

export function getPlanDetails(id) {
  let url = `/plan-details/${id}`;
  return axios.get(url, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } });
}

export function payment(data) {
  let url = `/make-payment`;
  return axios.post(url, data, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } });
}

export function projectPayment(data) {
  let url = `/make-user-payment`;
  return axios.post(url, data, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } });
}

export function paymentSummary() {
  let url = ``;
  return axios.get(url, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } });
}

export function checkPayment(id) {
  let url = `/payment-notifiation/${id}`;
  return axios.get(url, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } });
}

export function signupPayment(data) {
  let url = `/make-signup-payment`;
  return axios.post(url, data);
}

export function getClientSecret(id) {
  let url = `/stripe-client-secret/${id}`;
  return axios.get(url, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } });
}

export function getStripeConfigForProjectPayment(id) {
  let url = `/stripe-project-payment-config/${id}`;
  return axios.get(url, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } });
}

export function saveprojectTransaction(data) {
  let url = `/save-project-payment-transaction`;
  return axios.post(url, data, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } });
}

// Get Payment config of system 
export function getPaymentConfig() {
  let url = `/get-payment-config`;
  return axios.get(url, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } });
}

export function saveUserSubscription(data) {
  let url = `/save-user-subscription`;
  return axios.post(url, data, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } });
}

export function getUserSubscriptionClientSecret(id) {
  let url = `/get-user-subscription-client-secret/${id}`;
  return axios.get(url, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } });
}