import {
  SIGNED_IN_SUCCESS,
  SIGNED_IN_FAILURE,
} from "./actionTypes";

const INITIAL_STATE = {
  isSignedInInGoogleCalendar: false,
};

function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SIGNED_IN_SUCCESS:
      return {
        ...state,
        isSignedInInGoogleCalendar: true,
      };
    case SIGNED_IN_FAILURE:
      return {
        ...state,
        isSignedInInGoogleCalendar: false,
      };
    
    default:
      return state;
  }
}

export default reducer;
