export function getLocalStorageItem(itemname, prs) {
  return localStorage.getItem(itemname)
    ? prs
      ? localStorage.getItem(itemname)
      : JSON.parse(localStorage.getItem(itemname))
    : "";
}

export function removeLocalStorageItem(itemname) {
  localStorage.removeItem(itemname);
}

export function setLocalStorageItem(itemname, data, nstr) {
  return nstr
    ? localStorage.setItem(itemname, data)
    : localStorage.setItem(itemname, JSON.stringify(data));
}

export function userInfoData() {
  return getLocalStorageItem("userInfo");
}

export function token() {
  return getLocalStorageItem("token", "y");
}

export function permissionData() {
  if (
    getLocalStorageItem("access", "no") &&
    getLocalStorageItem("access", "no") !== undefined
  ) {
    return getLocalStorageItem("access")["permission"];
  }
}
