import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
// import { toast } from "react-toastify";

import Errormsg from "../../../Shared/Components/Errormsg";
import { checkLogin, resendOTP, verifyOTP } from "../Service/loginService";
import { markOnline } from "../../admin/Service/chat/service";
import {
  validateField,
  setValue,
  validateAllFields,
} from "../../../Shared/Services/validationService";
import logo from "../../../assets/images/logo.png";
import defaultImage from "../../../Shared/Components/ImgUtils";
import { userInfoData } from "../../../Shared/Components/LocalStorages";

class Login extends Component {
  constructor(props) {
    super(props);
    this.setForm();

    this.state = {
      formData: this.setFormFields,
      apiError: "",
      showLoader: false,
      apiSuccess: "",
    };
  }

  onChange(e) {
    this.setFormFields[e.target.name]["value"] = setValue(
      e,
      this.setFormFields[e.target.name]["type"]
    );
    let validate = validateField(this.setFormFields[e.target.name]);
    this.setFormFields[e.target.name]["error"] = validate.validationMsg;
    this.setState({
      formData: this.setFormFields,
    });
  }

  afterLogin(id) {
    this.props.updateInfo();

    markOnline(id)
      .then((res) => console.log("MARK ONLINE WHEN LOGIN", res))
      .catch((err) => console.log(err));
  }

  submitForm(e) {
    e.preventDefault();
    let isFormValid = validateAllFields(this.state.formData);
    if (isFormValid.validated) {
      let data = {
        login_type: "admin",
        user_type: 1,
        email: this.state.formData.email.value?.trim().toLocaleLowerCase(),
        password: this.state.formData.password.value,
      };
      this.setState({ showLoader: true });
      checkLogin(data)
        .then((res) => {
          if (res.data.success) {
            if (this.state.formData.remeberMe.value) {
              localStorage.setItem("rememberMe", JSON.stringify(data));
            } else {
              localStorage.setItem("rememberMe", "");
            }
            localStorage.setItem("token", res.data.data.token);
            let userData = {
              id: res.data.data.id,
              name: res.data.data.first_name,
              email: res.data.data.email,
              login_type: res.data.data.login_type,
              userType: res.data.data.user_type,
              image: res.data.data.image,
              encrypt_id: res.data.data.encrypt_id,
              parent_id: res.data.data.parent_id,
              encrypted_parent_id: res.data.data.encrypted_parent_id,
              isVerified: res.data.data.is_verified,
            };
            let permissionData = {
              permission: res.data.data.permissions,
            };
            localStorage.setItem("userInfo", JSON.stringify(userData));
            localStorage.setItem("access", JSON.stringify(permissionData));
            this.props.setsm(res.data.message);
            // this.props.setcurrentUser(userData);

            if (userData.isVerified) {
              this.afterLogin(res.data.data.id);
            } else {
              this.setForm();
              this.setState({
                formData: this.setFormFields,
                showLoader: false,
                apiError: "",
                apiSuccess: "An OTP has been sent to your email for verification. Please enter it below.",
              })
            }

            // this.props.history.push("/app/dashboard");
          } else {
            this.setState({ apiError: res.data.message, showLoader: false });
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            const errors = error.response.data.errors || {};
            let changed = false;
            Object.keys(errors).forEach((key) => {
              console.log(errors, key);
              this.setFormFields[key]["error"] = errors[key][0];
              changed = true;
            });

            if (changed) {
              this.setState({
                formData: this.setFormFields,
                showLoader: false,
              });
            }
          } else {
            this.setState({ showLoader: false });
          }
        });
    } else {
      let fields = Object.keys(this.setFormFields);
      for (let i = 0; i < fields.length; i++) {
        this.setFormFields[fields[i]].error = isFormValid.errors[fields[i]];
        this.setFormFields[fields[i]].touched = true;
      }
      this.setState({
        formData: this.setFormFields,
      });
    }
  }

  submitVerifyForm(e) {
    e.preventDefault();

    let isFormValid = validateAllFields(this.state.formData);
    if (isFormValid.validated) {
      let data = {
        otp: this.state.formData.otp.value,
      };

      this.setState({ showLoader: true });

      verifyOTP(data).then((res) => {
        if (res.data.success) {
          const userInfo = userInfoData();
          userInfo.isVerified = true;
          localStorage.setItem("userInfo", JSON.stringify(userInfo));

          this.afterLogin(res.data.data.id);

          this.props.history.push("/app/dashboard");
        } else {
          this.setState({ apiError: res.data.message, showLoader: false });
        }
      }).catch((error) => {
        console.log(error);
        this.setState({ showLoader: false });
      });

    } else {
      let fields = Object.keys(this.setFormFields);
      for (let i = 0; i < fields.length; i++) {
        this.setFormFields[fields[i]].error = isFormValid.errors[fields[i]];
        this.setFormFields[fields[i]].touched = true;
      }

      this.setState({
        formData: this.setFormFields,
      });
    }
  }

  handleBlur(event) {
    this.setFormFields[event.target.name]["touched"] = true;
    this.setState({
      formData: this.setFormFields,
    });
  }

  setForm() {
    const userInfo = userInfoData();

    let credData = {
      email: "",
      password: "",
      otp: "",
    };

    if (userInfo && userInfo.isVerified === false) {

      this.setFormFields = {
        otp: {
          name: "OTP",
          value: `${credData.otp}`,
          touched: false,
          error: "",
          type: "text",
          validation: [{ required: true }],
        },
      };
    } else {
      if (localStorage.getItem("rememberMe")) {
        let data = JSON.parse(localStorage.getItem("rememberMe"));
        if (data.login_type === "admin") {
          credData.email = data.email;
          credData.password = data.password;
        }
      }

      this.setFormFields = {
        email: {
          name: "Email",
          value: `${credData.email}`,
          touched: false,
          error: "",
          type: "email",
          validation: [{ required: true }, { maxLength: 50 }, { pattern: true }],
        },
        password: {
          name: "Password",
          value: `${credData.password}`,
          touched: false,
          error: "",
          type: "password",
          validation: [{ required: true }, { maxLength: 50 }],
        },
        remeberMe: {
          name: "remeberMe",
          value: false,
          touched: false,
          error: "",
          type: "checkbox",
          validation: [],
        },
      };
    }
  }

  render() {
    const userInfo = userInfoData();

    return (
      <React.Fragment>
        <div className="login-area">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="login-form">
                <div className="logo">
                  <img
                    src={logo}
                    alt="logo"
                    width="200"
                    onError={(e) => {
                      e.persist();
                      defaultImage(e, "logo");
                    }}
                  />
                </div>
                {/* <h2>Welcome</h2> */}
                {this.state.apiError && (
                  <p className="form-text text-danger text-center ">
                    <small>{this.state.apiError}</small>
                  </p>
                )}
                {this.state.apiSuccess && (
                  <p className="form-text text-success text-center ">
                    <small>{this.state.apiSuccess}</small>
                  </p>
                )}

                {(userInfo && userInfo.isVerified === false) && (
                  <form onSubmit={(e) => this.submitForm(e)}>
                    <div className="mb-4">
                      <div className="form-group mb-0">
                        <input
                          type="text"
                          className="form-control"
                          name="otp"
                          onBlur={(e) => {
                            this.handleBlur(e);
                          }}
                          placeholder="OTP"
                          onFocus={(event) => {
                            this.onChange(event);
                          }}
                          onChange={(event) => {
                            this.onChange(event);
                          }}
                          value={this.state.formData.otp.value}
                        />
                        <span className="label-title">
                          <i className="bx bx-key"></i>
                        </span>
                      </div>
                      <Errormsg errormsg={this.state.formData.otp} />
                    </div>

                    <button onClick={(e) => this.submitVerifyForm(e)} type="submit" className="login-btn">
                      Verify
                    </button>
                    <p className="mb-0">
                      Didn't receive OTP ? <span style={{ color: "#d08a56", cursor: "pointer" }} onClick={() => {
                        this.setState({ showLoader: true });
                        resendOTP().then((res) => {
                          if (res.data.success) {
                            this.setState({ apiSuccess: res.data.message, apiError: "", showLoader: false });
                          } else {
                            this.setState({ apiError: res.data.message, apiSuccess: "", showLoader: false });
                          }
                        })
                      }}>Resend</span>
                    </p>
                  </form>
                )}

                {(!userInfo) && (
                  <form
                    autoComplete="off"
                    onSubmit={(event) => {
                      this.submitForm(event);
                    }}
                  >
                    <div className="mb-4">
                      <div className="form-group mb-0">
                        <input
                          type="text"
                          className="form-control"
                          name="email"
                          onBlur={(e) => {
                            this.handleBlur(e);
                          }}
                          placeholder="Email"
                          onFocus={(event) => {
                            this.onChange(event);
                          }}
                          onChange={(event) => {
                            this.onChange(event);
                          }}
                          value={this.state.formData.email.value}
                        />
                        <span className="label-title">
                          <i className="bx bx-user"></i>
                        </span>
                      </div>
                      <Errormsg errormsg={this.state.formData.email} />
                    </div>
                    {/* <div>
                    <span>
                      {this.state.formData.email.touched &&
                      this.state.formData.email.error
                        ? this.setFormFields.email.error
                        : ""}
                    </span>
                  </div> */}
                    <div className="mb-4">
                      <div className="form-group mb-0">
                        <input
                          type="password"
                          className="form-control"
                          name="password"
                          onBlur={(e) => {
                            this.handleBlur(e);
                          }}
                          placeholder="Password"
                          onChange={(event) => {
                            this.onChange(event);
                          }}
                          onFocus={(event) => {
                            this.onChange(event);
                          }}
                          value={this.state.formData.password.value}
                        />
                        <span className="label-title">
                          <i className="bx bx-lock"></i>
                        </span>
                      </div>
                      <Errormsg errormsg={this.state.formData.password} />
                    </div>
                    <div className="form-group">
                      <div className="remember-forgot">
                        <label className="checkbox-box">
                          Remember me
                          <input
                            type="checkbox"
                            name="remeberMe"
                            onBlur={(e) => {
                              this.handleBlur(e);
                            }}
                            onChange={(event) => {
                              this.onChange(event);
                            }}
                          />
                          <span className="checkmark"></span>
                        </label>
                        <Link
                          to="/app/forgotpassword"
                          className="forgot-password"
                        >
                          Forgot password?
                        </Link>
                      </div>
                    </div>
                    <button type="submit" className="login-btn">
                      Login
                    </button>
                    <p className="mb-0 d-none">
                      Back to Event Planner <Link to="/login">Login</Link>
                    </p>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
        {this.state.showLoader && (
          <div className="loaderContener">
            <span className="Ploader">
              <span className="loader-inner"></span>
            </span>
          </div>
        )}
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setsm: (msg) => {
      dispatch({ type: "setsm", msg: msg });
    },
    updateInfo: () => {
      dispatch({ type: "UserInfo" });
    },
    // setcurrentUser: (payload) => dispatch({ type: 'setcurrentUser', payload: payload }),
  };
}

export default connect(null, mapDispatchToProps)(withRouter(Login));
