import React from "react";

import "./RecordsNotFound.css";

const NoRecords = ({ onAdd, message }) => (
  <React.Fragment>
    <div className="particle_container">
      <article className="particle_content">
        <i className="bx bx-search bx-md bx-tada-hover"></i>
        <p className="font-size-base font-weight-bold">
          {message ? message : "No records found."}
        </p>
        {onAdd && (
          <React.Fragment>
            <p>Do you want to add something?</p>
            <p>
              <button
                className="material-button"
                type="button"
                onClick={(e) => onAdd(e)}
              >
                <i className="bx bx-plus mr-1"></i>Add
              </button>
            </p>
          </React.Fragment>
        )}
      </article>
    </div>
  </React.Fragment>
);

export default NoRecords;
