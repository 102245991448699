import React from 'react';
import classes from "./Chat.module.css";
import Settings from './Settings';

const SideBarBottom = ({ showAddUsers, hideAddUser, handleAddContact, openModal, setShowSettings, getSettings, settingsData, saveSettings }) => {

  return (
    <div id={classes["bottom-bar"]}>
      {showAddUsers ? (
        <button
          id={classes.addcontact}
          type="button"
          onClick={(e) => hideAddUser(e)}
        >
          <i className="fa fa-times fa-fw" aria-hidden="true"></i>
          <span>Cancel</span>
        </button>
      ) : (
        <button
          id={classes.addcontact}
          type="button"
          onClick={(e) => handleAddContact(e)}
        >
          <i className="fa fa-user-plus fa-fw" aria-hidden="true"></i>
          <span>Add contact</span>
        </button>
      )}
      <button
        id={classes.settings}
        type="button"
        onClick={(e) => openModal(e)}
      >
        <i className="fa fa-plus fa-fw" aria-hidden="true"></i>
        <span>Create Group</span>
      </button>
      <button
        id={classes.settings}
        type="button"
        onClick={(e) => getSettings()}
      >
        <i className="fa fa-cog fa-fw" aria-hidden="true"></i>
        <span>Settings</span>
      </button>
      {settingsData.show && <Settings
        show={settingsData.show}
        onHide={() => {
          setShowSettings(false);
        }}
        data={settingsData.data}
        onSubmit={saveSettings}
      />}
    </div>
  );
};

export default SideBarBottom;